import React from "react";
import data from "./ListData.json";

const List = (props) => {
  const filteredData = data.filter((el) => {
    if (props.input === "") {
      return el;
    } else {
      return el.text.toLowerCase().includes(props.input);
    }
  });
  return (
    <div className="search-list-wrapper event-scroll">
      <ul>
        {filteredData.map((item) => (
          <a href={item.path} key={item.id}>
            <li>{item.text}</li>
          </a>
        ))}
      </ul>
    </div>
  );
};

export default List;
