import { CircularProgress } from "@material-ui/core";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pagination from "@material-ui/lab/Pagination";

export const sha512 = async (str) => {
  return crypto.subtle
    .digest("SHA-512", new TextEncoder("utf-8").encode(str))
    .then((buf) => {
      return Array.prototype.map
        .call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2))
        .join("");
    });
};

export const capitalize = (string) => {
  return string ? string.replace(/\b\w/g, (l) => l.toUpperCase()) : string;
};
// export const capitalize = (string) => {
//   return string ? string.capitalize() : string;
// };

export const uppercase = (string) => {
  return string ? string.toUpperCase() : string;
};

export const createTableData = (...parameters) => {
  return parameters;
};

export const formatNumber = (number) => {
  return number ? number.toLocaleString() : number;
};

export const exportTableDataToPdf = (tableHead, tableData, tableName) => {
  const pdf = () => {
    const doc = new jsPDF();
    //   autoTable(doc, { html: "#ApplicantTable" });

    doc.autoTable({
      theme: "grid",
      styles: { halign: "center" },

      bodyStyles: {
        halign: "center",
        // valign: "center",
        fontSize: 8,
        //bla
      },
      margin: { top: 5 },
      head: [tableHead],
      body: tableData,
    });
    doc.save(tableName);
  };

  return pdf();
};

export const TableLoadingSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        wsidth: "100%",
        margin: "1rem 0rem",
      }}
    >
      <CircularProgress style={{ color: "#46C35F" }} size={40} />
    </div>
  );
};

export const paginationCurrentPage = (total, rowsPerPage = 10) => {
  const reminder = total % rowsPerPage;

  const pages = total - reminder;
  const pagesDiv = pages / rowsPerPage;

  return reminder > total ? pagesDiv + 2 : pagesDiv + 1;
};

export const PaginationRange = ({
  paginationPage,
  rowsPerPage,
  total,
  filteredTotal = [],
}) => {
  return (
    <p>
      {`Showing ${
        paginationPage === 1
          ? paginationPage
          : paginationPage * rowsPerPage - rowsPerPage
      } to ${
        paginationPage * rowsPerPage > total
          ? total
          : paginationPage * rowsPerPage
      } 0f ${filteredTotal.length === 0 ? total : filteredTotal.length}:`}
    </p>
  );
};

export const TablePagination = ({
  paginationPage,
  total,
  handleChangePage,
  styles,
  rowsPerPage,
}) => {
  return (
    <div className={styles.table_pagination}>
      <PaginationRange
        paginationPage={paginationPage}
        rowsPerPage={rowsPerPage}
        total={total}
      />
      <Pagination
        onChange={handleChangePage}
        page={paginationPage}
        count={paginationCurrentPage(total)}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
};

export const GetDropList = () => {
  const year = new Date().getFullYear();

  return Array.from(new Array(50), (v, i) => (
    <option key={i} value={year - i}>
      {year - i}
    </option>
  ));
};
