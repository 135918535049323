import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import PageContainer from "../../components/PageContainer";
import Tab from "../../components/Website/Tab";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Website/Sidebar";
import PageTitle from "../../components/Website/PageTitle";
import { useMediaQuery } from "react-responsive";
import { AdeGeneralInfo } from "../../components/Website/Programmes/GeneralInfo";
import { AdeRequirements } from "../../components/Website/Programmes/Requirements";
import { AdeFees } from "../../components/Website/Programmes/Fees";
import PaymentMethods from "../../components/Website/Programmes/PaymentMethods";
import { useDispatch } from "react-redux";
import serviceApi from "../../redux/api/services";
import { useEffect } from "react";

const Ade = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [active, setActive] = useState("General Info");
  const [fetching, setFetching] = useState(false);
  const [ade, setAde] = useState({});
  const dispatch = useDispatch();

  const getAdeContent = async (state, key) => {
    setFetching(true);
    try {
      const res = await serviceApi?.getAdeContent();

      setAde(res?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getAdeContent();
  }, []);

  return (
    <div>
      <Grid>
        <PageContainer>
          <Helmet>
            <title>Programs | NTI</title>
          </Helmet>
          <PageTitle title="Programs" />
          <Tab />
          <div className="container">
            <section style={{ margin: isPhone ? "0 2rem 5rem" : "0" }}>
              <h1 style={{ fontWeight: "800", fontSize: "30px" }}>
                Advanced Diploma in Education
              </h1>
              <Grid container>
                <ul className="prog-details">
                  <li
                    onClick={() => setActive("General Info")}
                    style={{
                      background:
                        active === "General Info" ? "#00C853" : "#F6F6F6",
                      color: active === "General Info" ? "white" : "black",
                    }}
                  >
                    General Info
                  </li>
                  <li
                    onClick={() => setActive("Requirements")}
                    style={{
                      background:
                        active === "Requirements" ? "#00C853" : "#F6F6F6",
                      color: active === "Requirements" ? "white" : "black",
                    }}
                  >
                    Requirements
                  </li>
                  <li
                    onClick={() => setActive("Fees")}
                    style={{
                      background: active === "Fees" ? "#00C853" : "#F6F6F6",
                      color: active === "Fees" ? "white" : "black",
                    }}
                  >
                    Fees
                  </li>
                  <li
                    onClick={() => setActive("Payment Methods")}
                    style={{
                      background:
                        active === "Payment Methods" ? "#00C853" : "#F6F6F6",
                      color: active === "Payment Methods" ? "white" : "black",
                    }}
                  >
                    Payment Methods
                  </li>
                </ul>

                <Grid container spacing={3} style={{ marginBottom: "2rem" }}>
                  <Grid item md={9}>
                    {active === "General Info" && (
                      <AdeGeneralInfo content={ade} />
                    )}
                    {active === "Requirements" && (
                      <AdeRequirements content={ade} />
                    )}
                    {active === "Fees" && <AdeFees content={ade} />}
                    {active === "Payment Methods" && <PaymentMethods />}
                  </Grid>

                  <Grid item md={3}>
                    <Sidebar
                      semester={ade?.programme_duration}
                      yrs={ade?.programme_year}
                      applicationFee={ade?.application_fee}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </section>
          </div>
        </PageContainer>
      </Grid>
    </div>
  );
};

export default Ade;
