import {
  Box,
  Button,
  Container,
  // option,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import mainStyles from "../../styles/mainStyles";
import { useMediaQuery } from "react-responsive";
import AlertComponent from "../../components/Applicant/AlertComponent";
import { bindActionCreators } from "redux";
import {
  createApplicant,
  logoutApplicant,
  getApplicantData,
} from "../../redux/actions/applicantAction";

import { getPortalInfo } from "../../redux/api/services";
// was commented out for cypress testing
import servicesApi from "../../redux/api/services";
import { connect } from "react-redux";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PageContainer from "../../components/PageContainer";
import HoldImg from "../../assets/images/modal-hold-on.svg";
import HoldImg2 from "../../assets/images/modal-hold-on-red.svg";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const NewApplicant = ({
  createApplicant,
  applicant,
  logoutApplicant,
  getApplicantData,
  ...props
}) => {
  const history = useHistory();
  const year = new Date().getUTCFullYear();
  const defAlertDetails = {
    image: HoldImg,
    headerText: "Hold on a bit!",
    text: "Are you sure you entered all your information correctly?",
    btn1Text: "Check again",
    btn2Text: "Okay, Submit form",
  };

  const [alertConfig2, setAlertConfig2] = useState({
    image: HoldImg2,
    headerText: "Registration Portal closed. Please stay tuned.",
    headerColor: "#f44336",
    btn1Text: "Back to home",
    btnSec: true,
  });

  //   useEffect(() => {
  //     if (applicant.applicantData) logoutApplicant();

  //   }, [applicant.applicantData, logoutApplicant]);

  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const [alertConfig, setAlertConfig] = useState(defAlertDetails);
  const defaultValue = {
    firstname: "",
    surname: "",
    othername: "",
    email: "",
    phone: "",
    gender: "",
    dob: "", //2000-12-12
    marital_status: "", //married
    disability: "", //none
    other_disability: "none", //none
    password: "",
    password_confirmation: "",
  };
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const [applicantFormValue, setApplicantFormValue] = useState(defaultValue);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const closeModal2 = () => {
    history.push("/");
  };

  // To remove special characters from fields [Only supports alphabets and dash]
  const ALPHA_DASH_REGEX = /^[a-zA-Z-]+$/;
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
  const phoneNumberRegex = /^(0|\+234)[789]\d{9}$/;
  const email =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z0-9-]*$/;
  const slicedEmail = applicantFormValue?.email?.slice(
    applicantFormValue?.email.indexOf("@")
  );
  const isValidEmail = applicantFormValue.email.match(email);

  const handleFormValue = ({ name, value }) => {
    delete formErrors[name];
    console.log(name);
    const emailHost = value.slice(value.indexOf("@"));
    const isNtiEmail =
      emailHost?.includes("nti") ||
      emailHost?.includes("mynti") ||
      emailHost?.includes("my.nti");
    if (
      name === "phone" &&
      value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/)
    ) {
      setApplicantFormValue({ ...applicantFormValue, [name]: value });
    }

    if (name === "firstname" && value !== "" && !ALPHA_DASH_REGEX.test(value)) {
      return value;
    }

    if (name === "email" && value !== "" && !email.test(value)) {
      setFormErrors({
        email:
          "Email is not valid, please ensure you have entered a valid email",
      });
    }

    if (name === "email" && value !== "" && isNtiEmail) {
      setFormErrors({ email: "Email cannot be an nti email" });
    }

    if (name === "surname" && value !== "" && !ALPHA_DASH_REGEX.test(value)) {
      return value;
    }

    if (name === "othername" && value !== "" && !ALPHA_DASH_REGEX.test(value)) {
      return value;
    }

    if (name !== "phone" && "firstname" && "surname" && "othername") {
      setApplicantFormValue({ ...applicantFormValue, [name]: value });
    }
  };

  const handleButtonConfirmation = async () => {
    handleCreateApplicant();
  };

  const handleCreateApplicant = async () => {
    setFormErrors({});
    setSubmitting(true);
    try {
      const response = await createApplicant(applicantFormValue);

      await getApplicantData();
      console.log("successful");
      history.push("/applicant/personal-information", { application_state: 1 });
    } catch (error) {
      setFormErrors(error?.data?.errors ? error?.data?.errors : {});
    } finally {
      setSubmitting(false);
      handleCloseModal();
      setAlertConfig(defAlertDetails);
    }
  };
  // was commented out for cypress testing
  // Check if application portal is closed or not.
  useEffect(() => {
    const handlePortalCheck = async () => {
      try {
        const response = await servicesApi.getPortalInfo();
        if (response.data.message === "closed") {
          setOpenModal2(true);
        }
      } catch (error) {
        setFormErrors(error?.data?.errors ? error?.data?.errors : {});
        console.log(error);
      }
    };
    handlePortalCheck();
  }, []);
  return (
    <PageContainer>
      <Helmet>
        <title>New Application | NTI</title>
      </Helmet>
      <Container
        style={isPhone ? mainStyles.phoneContainer : mainStyles.container}
      >
        <Box>
          <Typography
            style={
              isPhone
                ? { ...mainStyles.pageTitle, ...{ fontSize: "22px" } }
                : mainStyles.pageTitle
            }
          >
            New and Prospective students
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: isPhone ? "14px" : "16px" }}>
            Create your MyNTI Applicant Account to get started with the new
            student application.
          </Typography>
        </Box>

        <Grid
          style={
            isPhone
              ? { ...mainStyles.subContainer, ...mainStyles.subPhoneContainer }
              : mainStyles.subContainer
          }
        >
          <form>
            <Grid
              item
              xs={12}
              style={{
                display: isPhone ? "block" : "flex",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="First name *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Enter First Name *"
                  helperText={formErrors.firstname && formErrors.firstname}
                  error={formErrors.hasOwnProperty("firstname")}
                  value={applicantFormValue.firstname}
                  name="firstname"
                  onChange={(e) => handleFormValue(e.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Surname *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Enter Surname *"
                  helperText={formErrors.surname && formErrors.surname}
                  error={formErrors.hasOwnProperty("surname")}
                  value={applicantFormValue.surname}
                  name="surname"
                  onChange={(e) => handleFormValue(e.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Other names"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Enter Other names"
                  // helperText={formErrors.othername && formErrors.othername}
                  // error={formErrors.hasOwnProperty("othername")}
                  value={applicantFormValue.othername}
                  name="othername"
                  onChange={(e) => handleFormValue(e.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Email address *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Enter Email address *"
                  value={applicantFormValue.email}
                  helperText={formErrors.email && formErrors.email}
                  error={formErrors.hasOwnProperty("email")}
                  name="email"
                  onChange={(e) => handleFormValue(e.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Mobile Number *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Enter Mobile Number *"
                  type="text"
                  helperText={formErrors.phone && formErrors.phone}
                  error={formErrors.hasOwnProperty("phone")}
                  name="phone"
                  value={applicantFormValue.phone}
                  onChange={(e) => handleFormValue(e.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 25 }}
                />
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <InputLabel shrink>Gender *</InputLabel>
                  <Select
                    native
                    notched
                    label="Select Gender *"
                    variant="outlined"
                    name="gender"
                    error={formErrors.hasOwnProperty("gender")}
                    value={applicantFormValue.gender}
                    onChange={(e) => handleFormValue(e.target)}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Select>
                  {formErrors.gender && (
                    <FormHelperText error>{formErrors.gender}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Date of birth *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Date of birth *"
                  helperText={formErrors.dob && formErrors.dob}
                  error={formErrors.hasOwnProperty("dob")}
                  name="dob"
                  type="date"
                  inputProps={{
                    max: `${year - 15}-12-31`,
                  }}
                  value={applicantFormValue.dob}
                  onChange={(e) => handleFormValue(e.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <InputLabel shrink>Marital Status *</InputLabel>
                  <Select
                    native
                    notched
                    label="Marital Status *"
                    variant="outlined"
                    name="marital_status"
                    error={formErrors.hasOwnProperty("marital_status")}
                    value={applicantFormValue.marital_status}
                    onChange={(e) => handleFormValue(e.target)}
                  >
                    <option value="">Select Marital Status</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                  </Select>
                  {formErrors.marital_status && (
                    <FormHelperText error>
                      {formErrors.marital_status}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <InputLabel shrink>Physically challenged? *</InputLabel>
                  <Select
                    native
                    notched
                    label="Physically challenged? *"
                    variant="outlined"
                    name="disability"
                    error={formErrors.hasOwnProperty("disability")}
                    value={applicantFormValue.disability}
                    onChange={(e) => handleFormValue(e.target)}
                  >
                    <option value="">Select an option</option>
                    <option value="none">None</option>
                    <option value="visual">Visual</option>
                    <option value="hearing">Hearing</option>
                    <option value="mental health">Mental Health</option>
                    <option value="disability affecting mobility">
                      Disability Affecting Mobility
                    </option>
                    <option value="wheel chair user">Wheel Chair User</option>
                    <option value="others">Others (Please State)</option>
                  </Select>
                  {formErrors.disability && (
                    <FormHelperText error>
                      {formErrors.disability}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Other disability *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Other disability *"
                  name="other_disability"
                  helperText={
                    formErrors.other_disability && formErrors.other_disability
                  }
                  error={formErrors.hasOwnProperty("other_disability")}
                  value={applicantFormValue.other_disability}
                  onChange={(e) => handleFormValue(e.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Choose password *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Choose password *"
                  name="password"
                  helperText={formErrors.password && formErrors.password}
                  error={formErrors.hasOwnProperty("password")}
                  type={showPassword ? "text" : "password"}
                  value={applicantFormValue.password}
                  onChange={(e) => handleFormValue(e.target)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <TextField
                  label="Confirm password *"
                  style={mainStyles.input}
                  variant="outlined"
                  placeholder="Confirm password *"
                  name="password_confirmation"
                  helperText={
                    formErrors.password_confirmation &&
                    formErrors.password_confirmation
                  }
                  error={formErrors.hasOwnProperty("password_confirmation")}
                  type={showConfirmPassword ? "text" : "password"}
                  value={applicantFormValue.password_confirmation}
                  onChange={(e) => handleFormValue(e.target)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {!showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Box mt={4}>
                  <Button
                    disabled={
                      submitting || !isValidEmail || slicedEmail.includes("nti")
                    }
                    onClick={() => handleOpenModal()}
                    style={{ ...mainStyles.btn, ...{ width: "100%" } }}
                    variant="contained"
                    color="primary"
                  >
                    {submitting ? (
                      <CircularProgress color="#FFF" size={20} />
                    ) : (
                      "Apply"
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <AlertComponent
          processing={submitting}
          config={alertConfig}
          disabled={submitting}
          openDialog={openModal}
          handleClose={handleCloseModal}
          handleAlertConfirm={handleButtonConfirmation}
        />

        <AlertComponent
          config={alertConfig2}
          openDialog={openModal2}
          handleClose={closeModal2}
        />
      </Container>
    </PageContainer>
  );
};
const mapStateToProps = ({ applicant }) => ({ applicant });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createApplicant, logoutApplicant, getApplicantData },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewApplicant);
