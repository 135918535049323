import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Logo from "../../assets/images/logo.svg";
import PersonLaptop from "../../assets/images/dashboard-person-laptop-.svg";
import Documents from "../../assets/images/documents.svg";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemText,
  Grid,
  ListItemIcon,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Close, ExitToApp, AccountCircle, Search } from "@material-ui/icons";
import { useLocation, useHistory, Link } from "react-router-dom";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../styles/colors";
import { useMediaQuery } from "react-responsive";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import DashboardFooter from "./DashboardFooter";
import { logoutApplicant } from "../../redux/actions/applicantAction";
import { logoutStudent } from "../../redux/actions/studentAction";
import { logoutAdmin } from "../../redux/actions/adminAction";
import Envelope from "../../assets/images/Envelope.svg";
import File from "../../assets/images/File.svg";
import styles from "../../styles/styles";
import DashboardIcon from "../../assets/images/dashboard-main-icon.svg";
import DashboardApplicantIcon from "../../assets/images/dashboard-applicant-icon.svg";
import DashboardCoursesIcon from "../../assets/images/dashboard-courses-icon.svg";
import DashboardBulkIcon from "../../assets/images/dashboard-bulk-icon.svg";
import DashboardStudentIcon from "../../assets/images/dashboard-student-icon.svg";
import DashboardAuditIcon from "../../assets/images/admin/admin-audit-dash-icon.svg";
import DashboardFeesIcon from "../../assets/images/admin/admin-fees-dash-icon.svg";
import DashboardScholarshipIcon from "../../assets/images/admin/admin-scholarship-dash-icon.svg";
import DashboardReportsIcon from "../../assets/images/admin/admin-reports-dash-icon.svg";
import DashboardStaffIcon from "../../assets/images/admin/admin-staff-dash-icon.svg";
import DashboardExamIcon from "../../assets/images/admin/admin-exam-dash-icon.svg";
import DashboardNotifyIcon from "../../assets/images/admin/admin-notify-dash-icon.svg";
import DashboardSettingsIcon from "../../assets/images/admin/admin-settings-dash-icon.svg";
import DashboardLogoutIcon from "../../assets/images/admin/admin-logout-dash-icon.svg";
import DashboardCreateStudentIcon from "../../assets/images/admin/Create-Student.svg";
import DashboardVerifyRRRIcon from "../../assets/images/admin/verify-rrr.svg";
import DashboardSearchIcon from "../../assets/images/admin/admin-dash-search.svg";
import DashboardProfileIcon from "../../assets/images/admin/admin-profile-icon.svg";
import Dashlet from "../Dashlet";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // width: "1171px",
    // margin: "0 auto",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: PRIMARY_COLOR,
    color: WHITE_COLOR,
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      width: "5px",
      background: "#46c35f ",
      borderRadius: "2px ",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const DashboardComponent = ({
  children,
  loader = false,
  type,
  pageTitle,
  applicant,
  student,
  admin,
  logoutApplicant,
  logoutStudent,
  logoutAdmin,
}) => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const smallerPhone = useMediaQuery({ query: "(max-width: 500px)" });
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [activeSemester, setActiveSemester] = useState([]);

  const [loggingOut, setLoggingOut] = useState(false);

  const [dashboardType, setDashboardType] = useState(""); // controls the dashboard - value can be admin | applicant | student
  const [applicantData, setApplicantData] = useState(
    applicant?.applicantData?.applicant || {}
  );
  const [studentData, setStudentData] = useState(
    student?.studentData?.student || {}
  );
  // console.log(studentData);
  const [name, setDashboardName] = useState("");
  const [open, setOpen] = useState(isPhone ? false : true);

  const applicantLinks = [
    { title: "Dashboard", link: "dashboard" },
    { title: "Payment History", link: "payment-history" },
    { title: "Documents", link: "documents" },
    { title: "Logout", link: "logout" },
  ];

  const studentLinks = [
    { title: "Dashboard", link: "dashboard" },
    { title: "My Profile", link: "my-profile" },
    { title: "My Courses", link: "my-courses" },
    { title: "My Documents", link: "my-documents" },
    { title: "My Results", link: "my-results" },
    { title: "My Wallet", link: "my-wallet" },
    { title: "Exam", link: "exam" },
    { title: "My Fees", link: "my-fees" },
    { title: "Support", link: "support" },
    { title: "Logout", link: "logout" },
  ];

  const superAdmin = [
    { title: "Dashboard", link: "dashboard", icon: DashboardIcon },
    { title: "Applicants", link: "applicants", icon: DashboardApplicantIcon },
    { title: "Students", link: "students", icon: DashboardStudentIcon },
    { title: "Courses", link: "courses", icon: DashboardCoursesIcon },
    {
      title: "Bulk Feature",
      link: "bulk-admission",
      icon: DashboardBulkIcon,
    },
    {
      title: "Audit Trails",
      link: "audit-trails",
      icon: DashboardAuditIcon,
    },
    {
      title: "Fees",
      link: "fees",
      icon: DashboardFeesIcon,
    },
    {
      title: "Scholarship",
      link: "scholarship",
      icon: DashboardScholarshipIcon,
    },
    {
      title: "General Search",
      link: "general-search",
      icon: DashboardSearchIcon,
    },
    {
      title: "Reports",
      link: "reports",
      icon: DashboardReportsIcon,
    },
    {
      title: "Verify RRR",
      link: "verify-rrr",
      icon: DashboardVerifyRRRIcon,
    },
    {
      title: "Create Student",
      link: "create-student",
      icon: DashboardCreateStudentIcon,
    },
    {
      title: "Staff",
      link: "staff",
      icon: DashboardStaffIcon,
    },
    {
      title: "Exams",
      link: "exams",
      icon: DashboardExamIcon,
    },
    {
      title: "Profile",
      link: "profile",
      icon: DashboardProfileIcon,
    },

    {
      title: "Settings",
      link: "settings",
      icon: DashboardSettingsIcon,
    },
    { title: "Logout", link: "logout", icon: DashboardLogoutIcon },
  ];
  const adminDash = [
    { title: "Dashboard", link: "dashboard", icon: DashboardIcon },
    { title: "Applicants", link: "applicants", icon: DashboardApplicantIcon },
    { title: "Students", link: "students", icon: DashboardStudentIcon },
    { title: "Courses", link: "courses", icon: DashboardCoursesIcon },
    {
      title: "Bulk Feature",
      link: "bulk-admission",
      icon: DashboardBulkIcon,
    },

    {
      title: "Scholarship",
      link: "scholarship",
      icon: DashboardScholarshipIcon,
    },

    {
      title: "Verify RRR",
      link: "verify-rrr",
      icon: DashboardVerifyRRRIcon,
    },
    {
      title: "Create Student",
      link: "create-student",
      icon: DashboardCreateStudentIcon,
    },

    {
      title: "Exams",
      link: "exams",
      icon: DashboardExamIcon,
    },
    {
      title: "Profile",
      link: "profile",
      icon: DashboardProfileIcon,
    },
    { title: "Logout", link: "logout", icon: DashboardLogoutIcon },
  ];
  const support = [
    { title: "Dashboard", link: "dashboard", icon: DashboardIcon },
    { title: "Applicants", link: "applicants", icon: DashboardApplicantIcon },
    { title: "Students", link: "students", icon: DashboardStudentIcon },
    { title: "Courses", link: "courses", icon: DashboardCoursesIcon },
    {
      title: "Bulk Feature",
      link: "bulk-admission",
      icon: DashboardBulkIcon,
    },

    {
      title: "Fees",
      link: "fees",
      icon: DashboardFeesIcon,
    },
    {
      title: "Scholarship",
      link: "scholarship",
      icon: DashboardScholarshipIcon,
    },
    {
      title: "General Search",
      link: "general-search",
      icon: DashboardSearchIcon,
    },

    {
      title: "Verify RRR",
      link: "verify-rrr",
      icon: DashboardVerifyRRRIcon,
    },
    {
      title: "Create Student",
      link: "create-student",
      icon: DashboardCreateStudentIcon,
    },
    {
      title: "Exams",
      link: "exams",
      icon: DashboardExamIcon,
    },
    {
      title: "Profile",
      link: "profile",
      icon: DashboardProfileIcon,
    },
    { title: "Logout", link: "logout", icon: DashboardLogoutIcon },
  ];
  const aro = [
    { title: "Dashboard", link: "dashboard", icon: DashboardIcon },
    { title: "Applicants", link: "applicants", icon: DashboardApplicantIcon },
    { title: "Students", link: "students", icon: DashboardStudentIcon },
    { title: "Courses", link: "courses", icon: DashboardCoursesIcon },
    {
      title: "Bulk Feature",
      link: "bulk-admission",
      icon: DashboardBulkIcon,
    },
    {
      title: "Audit Trails",
      link: "audit-trails",
      icon: DashboardAuditIcon,
    },

    {
      title: "Scholarship",
      link: "scholarship",
      icon: DashboardScholarshipIcon,
    },
    {
      title: "Reports",
      link: "reports",
      icon: DashboardReportsIcon,
    },
    {
      title: "Verify RRR",
      link: "verify-rrr",
      icon: DashboardVerifyRRRIcon,
    },
    {
      title: "Create Student",
      link: "create-student",
      icon: DashboardCreateStudentIcon,
    },
    {
      title: "Staff",
      link: "staff",
      icon: DashboardStaffIcon,
    },
    {
      title: "Exams",
      link: "exams",
      icon: DashboardExamIcon,
    },
    {
      title: "Profile",
      link: "profile",
      icon: DashboardProfileIcon,
    },
    {
      title: "Settings",
      link: "settings",
      icon: DashboardSettingsIcon,
    },
    { title: "Logout", link: "logout", icon: DashboardLogoutIcon },
  ];
  const staff = [
    {
      title: "Exams",
      link: "exams",
      icon: DashboardExamIcon,
    },
    { title: "Logout", link: "logout" },
  ];

  useEffect(() => {
    setOpen(false);
  }, [isPhone]);

  const dashlets = [
    {
      icon: applicantData?.profile_picture ? (
        `${applicantData.profile_picture}`
      ) : (
        <AccountCircle style={{ fontSize: 45 }} />
      ),
      iconStyle: { borderRadius: "50%", objectFit: "cover" },
      label: "",
      data: `${applicantData.surname} ${applicantData.firstname} ${applicantData.othername}`,
    },
    { icon: File, label: "Application No", data: `${applicantData.app_no}` },
    { icon: Envelope, label: "Recovery Email", data: `${applicantData.email}` },
  ];

  const studentDashlets = [
    {
      icon: studentData?.profile_picture ? (
        `${studentData.profile_picture}`
      ) : (
        <AccountCircle style={{ fontSize: 45 }} />
      ),
      iconStyle: { borderRadius: "50%", objectFit: "cover" },
      label: "",
      data: `${studentData?.surname} ${studentData?.firstname} ${
        studentData?.othername ? studentData?.othername : ""
      }`,
    },
    { icon: File, label: "Registration No", data: `${studentData?.reg_no}` },
    {
      icon: Envelope,
      label: "Recovery Email",
      data: `${studentData?.recovery_email}`,
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (applicant?.applicantData?.applicant) {
      setApplicantData(applicant?.applicantData?.applicant);
    }
  }, [applicant]);

  useEffect(() => {
    if (student?.studentData?.student) {
      setStudentData(student?.studentData?.student);
    }
  }, [student]);

  useEffect(() => {
    setDashboardType(type);
    if (applicant.authenticated) {
      setDashboardType("applicant");
    } else if (admin.authenticated) {
      setDashboardType("admin");
    } else if (student.authenticated) {
      setDashboardType("student");
    }
  }, [
    type,
    applicant.authenticated,
    admin.authenticated,
    student.authenticated,
  ]);

  const goto = (url) => {
    history.push(`/${dashboardType}/${url}`);
  };

  const getActive = (link) => {
    const lnk = location.pathname.endsWith("/")
      ? location.pathname.slice(0, -1)
      : location.pathname;
    return lnk === `/${dashboardType}/${link}` ||
      location.pathname.includes(link)
      ? true
      : false;
  };

  const getLinks = () => {
    switch (dashboardType) {
      case "admin":
        return admin?.adminData?.staff?.role === "super_admin"
          ? superAdmin
          : admin?.adminData?.staff?.role === "aro"
          ? aro
          : admin?.adminData?.staff?.role === "admin"
          ? adminDash
          : admin?.adminData?.staff?.role === "support"
          ? support
          : staff;
      case "applicant":
        return applicantLinks;
      case "student":
        return studentLinks;
      default:
        return [];
    }
  };

  const getSideBarImage = () => {
    const link =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    switch (link) {
      case "dashboard":
        return PersonLaptop;
      case "documents":
        return Documents;
      default:
        return PersonLaptop;
    }
  };

  const handleLogout = async () => {
    try {
      if (applicant.authenticated) {
        await logoutApplicant();
      } else if (admin.authenticated) {
        setLoggingOut(true);
        await logoutAdmin();
      } else if (student.authenticated) {
        await logoutStudent();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoggingOut(false);
    }
  };
  // if (!studentData) {
  //   history.push("/student/login");
  // }

  useEffect(() => {
    setActiveSemester(JSON.parse(localStorage.getItem("activeSemester")));
  }, []);

  useEffect(() => {
    if (applicant.authenticated) {
      setDashboardName(
        `${applicantData.surname} ${applicantData.firstname} ${applicantData.othername}`
      );
    } else if (admin.authenticated) {
      setDashboardName(
        `${admin?.adminData?.staff.surname} ${admin?.adminData?.staff.firstname} ${admin?.adminData?.staff.othername}`
      );
    } else if (student.authenticated) {
      setDashboardName(
        `${studentData.surname} ${studentData.firstname} ${
          studentData.othername ? studentData.othername : ""
        }`
      );
    }
  }, [
    applicant,
    student,
    admin,
    applicantData.firstname,
    applicantData.othername,
    applicantData.surname,
    studentData.firstname,
    studentData.othername,
    studentData.surname,
  ]);

  return (
    <div className={classes.root} styl={{ width: "1171px", margin: "0 auto" }}>
      <Helmet>
        <title>
          {pageTitle ? `${pageTitle} - ${dashboardType}` : ""} Dashboard | NTI
        </title>
      </Helmet>
      <CssBaseline />
      {isPhone && (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              {open ? <Close /> : <MenuIcon />}
            </IconButton>

            <img src={Logo} alt="nti" height={40} />

            <IconButton color="inherit" aria-label="refresh" edge="end">
              {dashboardType === "admin" ? (
                admin?.adminData?.staff?.picture ? (
                  <Link
                    className=""
                    to={"/admin/profile"}
                    style={{ textDecoration: "none", listStyleType: "none" }}
                  >
                    <img
                      alt=""
                      src={admin?.adminData?.staff?.picture}
                      width="40px"
                      height="40px"
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                  </Link>
                ) : (
                  <AccountCircle style={{ fontSize: 40 }} />
                )
              ) : dashboardType === "student" ? (
                studentData?.profile_picture ? (
                  <img
                    alt=""
                    src={studentData?.profile_picture}
                    width="40px"
                    height="40px"
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                ) : (
                  <AccountCircle style={{ fontSize: 40 }} />
                )
              ) : applicantData?.profile_picture ? (
                <img
                  alt=""
                  src={applicantData?.profile_picture}
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                />
              ) : (
                <AccountCircle style={{ fontSize: 40 }} />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
      )}

      <Drawer
        className={classes.drawer}
        // className="side-drawer"
        variant="persistent"
        anchor="left"
        open={isPhone ? open : true}
        classes={{
          paper: dashboardType === "admin" ? "adminDrawer" : "drawerPaper",
        }}
        style={isPhone ? { position: "absolute", left: 0 } : {}}
      >
        {isPhone && <div className={classes.drawerHeader} />}

        <List style={{ padding: "2rem 0 0" }}>
          {!isPhone && (
            <img
              src={Logo}
              alt="nti"
              style={{ display: "block", margin: "0 auto 1rem" }}
            />
          )}
          {getLinks().map((link, index) => (
            <ListItem
              button
              className={getActive(link.link) ? "active-sidemenu" : ""}
              key={index}
              onClick={() => {
                link.link === "logout" ? handleLogout() : goto(link.link);
                link.link === "my-fees" &&
                  history.push("/student/my-fees/pay-fees");
              }}
            >
              {link.icon && (
                <ListItemIcon>
                  <img src={link.icon} alt="icon" />
                </ListItemIcon>
              )}
              <ListItemText
                primary={link.title}
                style={{ padding: "0 0 0 1rem" }}
              />
            </ListItem>
          ))}
        </List>
        {dashboardType === "student" ? (
          <img
            src={getSideBarImage()}
            alt=""
            style={{
              // position: "absolute",
              bottom: -100,
              left: 10,
              width: "90%",
            }}
          />
        ) : dashboardType === "admin" ? (
          <img
            src={getSideBarImage()}
            alt=""
            style={{
              // position: "absolute",
              bottom: -350,
              left: 10,
              width: "90%",
            }}
          />
        ) : dashboardType === "applicant" ? (
          <img
            src={getSideBarImage()}
            alt=""
            style={{
              position: "absolute",
              bottom: 0,
              left: 10,
              width: "90%",
            }}
          />
        ) : null}
      </Drawer>
      <main
        className={clsx(
          classes.content,
          {
            [classes.contentShift]: open,
          },
          dashboardType === "admin" && "mainContent"
        )}
        style={{ width: "100%" }}
      >
        {loader && (
          <div className="loader">
            <img src={Logo} alt="" />
          </div>
        )}

        {isPhone && <div className={classes.drawerHeader} />}
        <Grid container style={{ flexDirection: "column", minHeight: "100%" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent:
                dashboardType !== "admin" ? "space-between" : "flex-end",
              alignItems: "center",
            }}
          >
            {dashboardType !== "admin" ? (
              <h3 style={{ fontSize: isPhone ? 24 : 32, margin: 0 }}>
                {pageTitle}
              </h3>
            ) : (
              <></>
            )}

            {!isPhone && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: "#87939F", fontSize: 16, margin: "0 1rem" }}>
                  Hi, {name}
                </p>
                {dashboardType === "admin" ? (
                  <Link
                    className=""
                    to={"/admin/profile"}
                    style={{ textDecoration: "none", listStyleType: "none" }}
                  >
                    {admin?.adminData?.staff?.picture ? (
                      <img
                        alt=""
                        src={admin?.adminData?.staff?.picture}
                        width="40px"
                        height="40px"
                        style={{ borderRadius: "50%", objectFit: "cover" }}
                      />
                    ) : (
                      <AccountCircle style={{ fontSize: 40 }} />
                    )}
                  </Link>
                ) : dashboardType === "student" ? (
                  studentData?.profile_picture ? (
                    <img
                      alt=""
                      src={studentData?.profile_picture}
                      width="40px"
                      height="40px"
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                  ) : (
                    <AccountCircle style={{ fontSize: 40 }} />
                  )
                ) : applicantData?.profile_picture ? (
                  <img
                    alt=""
                    src={applicantData?.profile_picture}
                    width="40px"
                    height="40px"
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                ) : (
                  <AccountCircle style={{ fontSize: 40 }} />
                )}

                {loggingOut ? (
                  <CircularProgress size={20} style={{ color: "#46C35F" }} />
                ) : (
                  <ExitToApp
                    onClick={() => handleLogout()}
                    style={{
                      fontSize: 30,
                      marginLeft: "1rem",
                      cursor: "pointer",
                      color: PRIMARY_COLOR,
                    }}
                  />
                )}
              </div>
            )}
          </Grid>

          <Grid
            container
            item
            xs={12}
            style={{ flex: 1, flexDirection: "column" }}
          >
            <Grid container style={styles.section}>
              {dashboardType === "applicant" ? (
                dashlets.map((dashlet, key) => (
                  <Grid
                    item
                    md={4}
                    xs={12}
                    key={key}
                    style={{ padding: ".5rem" }}
                  >
                    <Dashlet dashlet={dashlet} />
                    {key === 0 && isPhone && (
                      <Divider
                        style={{
                          height: "2px",
                          width: "100%",
                          marginTop: ".8rem",
                        }}
                      />
                    )}
                  </Grid>
                ))
              ) : dashboardType === "student" ? (
                studentDashlets.map((dashlet, key) => (
                  <Grid
                    item
                    md={4}
                    xs={12}
                    key={key}
                    style={{ padding: ".5rem" }}
                  >
                    <Dashlet dashlet={dashlet} />
                    {key === 0 && isPhone && (
                      <Divider
                        style={{
                          height: "2px",
                          width: "100%",
                          marginTop: ".8rem",
                        }}
                      />
                    )}
                  </Grid>
                ))
              ) : (
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: smallerPhone ? "8px 24px" : "8px 24px",
                    width: "100%",
                    height: "94px",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      fontSize: isPhone ? 22 : 30,
                      margin: 0,
                    }}
                  >
                    {pageTitle}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: smallerPhone ? "center" : "space-between",
                      flexDirection: smallerPhone && "column",
                      fontSize: smallerPhone ? "10px" : "25px",
                      fontWeight: "500",
                      alignItems: "center",
                      color: "#19191D",
                    }}
                  >
                    <p
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    >
                      {activeSemester?.value && activeSemester?.value}
                    </p>
                    <p
                      style={{
                        // fontSize: smallerPhone && "10px",
                        margin: "0px 5px 0px 0px",
                      }}
                    >
                      {activeSemester?.session?.name &&
                        activeSemester?.session?.name}
                    </p>
                    <p
                      style={{
                        // fontSize: smallerPhone && "10px",
                        margin: "0px 5px 0px 0px",
                      }}
                    >
                      {activeSemester?.value && "Session"}
                    </p>
                  </div>
                </Grid>
              )}
            </Grid>

            {children}
          </Grid>
          <Grid item xs={12} className="sticky-footer">
            <DashboardFooter />
          </Grid>
        </Grid>
      </main>
    </div>
  );
};
const mapStateToProps = ({ applicant, admin, student }) => ({
  applicant,
  admin,
  student,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { logoutApplicant, logoutAdmin, logoutStudent },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
