import React from "react";
import PageContainer from "../../components/PageContainer";
import Img1 from "../../assets/images/how-to-images/pic1.png";
import Img2 from "../../assets/images/how-to.signin.png";
import Img3 from "../../assets/images/how-to-images/pic2.png";
import Img4 from "../../assets/images/how-to-images/pic9.jpg";
import Img5 from "../../assets/images/reset-pop-up.png";
import Img6 from "../../assets/images/dash-how-to.jpg";
import Img7 from "../../assets/images/profile-how-to.jpg";
import Img8 from "../../assets/images/register-course-how.jpg";
import Img9 from "../../assets/images/register-course-how2.png";
import Img10 from "../../assets/images/how-to-images/pic4.png";
import Img11 from "../../assets/images/how-to-images/pic5.png";
import Img12 from "../../assets/images/course-material-how.png";
import Img13 from "../../assets/images/carry-over-how.png";
import Img14 from "../../assets/images/access-result-how.jpg";
import Img15 from "../../assets/images/access-result-how2.png";
import Img16 from "../../assets/images/access-docs-how.jpg";
import Img17 from "../../assets/images/access-exam-how.png";
import Img18 from "../../assets/images/access-exam-how2.jpg";
import Img19 from "../../assets/images/access-fee-how.jpg";
import Img20 from "../../assets/images/verify-rrr-how.png";
import Img21 from "../../assets/images/verify-rrr-how2.jpg";
import Img22 from "../../assets/images/how-to-images/pic6.png";
import Img23 from "../../assets/images/payment-history-how2.jpg";
import Img24 from "../../assets/images/access-support-how.jpg";
import Img25 from "../../assets/images/how-to-images/pic3.png";
import { Helmet } from "react-helmet";
import PageTitle from "../../components/Website/PageTitle";
import Header from "../../components/Header";

function HowToRegister() {
  return (
    <PageContainer>
      <Helmet>
        <title>How to register | NTI</title>
      </Helmet>
      <PageTitle title="How To Register" />

      <div
        className="how-to-register-page"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ color: "#46c35f" }}>Student User Guide</h1>

        <h3> OVERVIEW </h3>

        <p>
          This page contains step-by-step processes of the system, the
          operations are listed below:{" "}
        </p>

        <ul>
          <li>How to get started </li>

          <li>How to Sign in </li>

          <li>How to Reset you Password </li>

          <li>How to access your Dashboard </li>

          <li>How to access your Profile </li>

          <li>How to access your Courses</li>

          <li>How to access your Results </li>

          <li>How to access your Documents </li>

          <li>How to access your Exams </li>

          <li>How to access your Fees </li>

          <li>How to access Support</li>

          <li>How to Log out</li>
        </ul>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO GET STARTED</h3>
          <p>
            To use the system, go to{" "}
            <a
              target={"_blank"}
              style={{ color: "#46c35f" }}
              href={"https://my.nti.edu.ng"}
            >
              my.nti.edu.ng
            </a>
          </p>
          <img src={Img1} alt="" />
          <ul>
            <li> Click on the “Current Student Apply” button to</li>
          </ul>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO SIGN IN</h3>
          <p>
            You can sign in with your provided username/email and password -
            these login credentials will be used subsequently.
          </p>
          <img src={Img2} alt="" />
          <ul>
            <li>
              {" "}
              Fill the input boxes with your login credentials (please provide a
              valid email/username and password)
            </li>
            <li>Click “Login” to log into the application</li>
          </ul>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">
            HOW TO USE THE FORGOT PASSWORD FEATURE
          </h3>
          <p>In a situation where a Student forgets his/her password</p>

          <ul>
            <li>Click on “Forgot Password” link on the login page</li>
          </ul>
          <img src={Img3} alt="" />
          <p>The following screen is displayed.</p>
        </div>

        <div className="how-to_wrapper">
          <img src={Img4} alt="" />
          <ul>
            <li>
              Enter your student email (the email associated with your account)
            </li>

            <li>
              Enter your new password and re-enter your password in the second
              confirmation password field.
            </li>
            <li>
              The following pop-up is displayed after you’ve successfully
              changed your password
            </li>
          </ul>
        </div>

        <div className="how-to_wrapper">
          <img src={Img5} alt="" />
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR DASHBOARD</h3>
          <p>
            A Student can access his/her Dashboard by navigating to the
            Dashboard in the side menu.
          </p>
          <img src={Img6} alt="" />
          <ol>
            <li>My Wallet: This feature is coming soon</li>
            <li>
              {" "}
              Registered Courses: views the total number of Registered courses.{" "}
            </li>
            <li>
              {" "}
              Course Registration: register your courses for the semester.
            </li>
            <li>
              {" "}
              Learning Portal: learn from your comfort zone, at your own pace
              whenever you want.
            </li>
            <li>MS 365 Account: access your 365 account. </li>
            <li>School Calendar: this feature is coming soon.</li>

            <li>My Courses: view your courses for the semester.</li>

            <li>Events: Access upcoming events</li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR PROFILE</h3>
          <p>
            A Student can access his/her Profile by navigating in the side menu
          </p>
          <img src={Img7} alt="" />
          <ol>
            <li>
              Update Image: change your photo to your profile Registered
              Courses: views the total number of Registered courses.{" "}
            </li>
            <li>Edit Profile: to change your profile information</li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR COURSES</h3>
          <p>
            A Student can access his/her Courses by navigating in the side menu
            These comprises of 3 tiles
          </p>
          <ul>
            <li>Register Courses</li>
            <li> Course Materials</li>
            <li>Carryover Courses</li>
          </ul>

          <h4>REGISTER COURSES</h4>
          <img src={Img8} alt="" />
          <ol>
            <li>
              Register Courses: Select your courses to register for the
              semester(click the check box at the top of the course Registration
              page to select all the courses)
            </li>
            <li>
              a. After Course Registration the following success screen is
              displayed
            </li>
          </ol>

          <img src={Img9} alt="" />

          <p>
            To access your course form after Registration, click on the
            “Download Course form” button at the bottom-right of your screen as
            seen below
          </p>

          <img src={Img10} alt="" />
          <p>
            The following screen is displayed when you click on the Download
            button.
          </p>

          <img src={Img11} alt="" />

          <ol>
            <li>
              Print: Click on the “Print” button to print your Course form
            </li>
            <li>
              Download: Click on the “Download” button to download a copy of
              your Course form
            </li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">COURSE MATERIALS</h3>

          <img src={Img12} alt="" />
          <p>
            To access your Course Materials, click on the Course materials tab
            to access the LMS for your course materials.
          </p>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">CARRYOVER COURSES</h3>
          <p>
            To access your Carry-over courses, click on the “Carry-over” tab
          </p>
          <img src={Img13} alt="" />
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR RESULT</h3>
          <p>
            A Student can access his/her Result by navigating to the side menu
          </p>

          <ul>
            <li>
              Click on the “Semester/Year” down-drop to select the level &
              session, your result will be displayed.(you can access your
              results when it is made available by the institution)
            </li>
          </ul>
          <img src={Img14} alt="" />
          <ol>
            <li>
              Print Result: have a hard copy of your result by printing it
            </li>
            <li>
              a. When you click on “Print”, the following preview screen is
              displayed.
            </li>
          </ol>
          <img src={Img15} alt="" />
          <ol>
            <li>Download: have a copy of your result on your local disk</li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR DOCUMENTS</h3>
          <p>
            A Student can access his/her Documents by navigating in the side
            menu
          </p>
          <img src={Img16} alt="" />
          <ol>
            <li>
              VIEW: have a look at your documents (Application form, Admission
              form, Course Registration form etc)
            </li>
            <li>DOWNLOAD: get a copy of your document to your local machine</li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR EXAMS</h3>
          <p>
            A Student can access his/her Documents by navigating in the side
            menu
          </p>
          <p>These comprises of two sections</p>
          <ul>
            <li>Exam card tab</li>
            <li>Exam Timetable tab</li>
          </ul>
          <img src={Img17} alt="" />
          <ol>
            <li>VIEW: access your semester exam card</li>
            <li>
              {" "}
              DOWNLOAD: get a copy of your semester exam card on your local disk
              or printed out copy.
            </li>
          </ol>

          <h4>Exam Timetable</h4>
          <img src={Img18} alt="" />

          <ol>
            <li>VIEW: access your semester exam timetable</li>
            <li>
              {" "}
              DOWNLOAD: get a copy of your semester exam timetable on your local
              disk or printed-out copy.
            </li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR FEES</h3>
          <p>A Student can access his/her Fees by</p>
          <p>navigating in the side menu These comprises of three sections</p>
          <ul>
            <li>Pay Fees</li>
            <li>Payment History</li>
            <li>Outstanding Payments</li>
          </ul>

          <h4>PAY FEES</h4>
          <img src={Img19} alt="" />
          <ol>
            <li>PAY VIA REMITA: pay fess online</li>
            <li>SAVE INVOICE: save your statement</li>
          </ol>
          <h4>VERIFY RRR</h4>

          <p>
            The “Verify RRR” search box is displayed as seen below. Enter your
            RRR and click on search to verify if your payment was successful or
            still pending.
          </p>
          <img src={Img21} alt="" />

          <h4>PAYMENT HISTORY</h4>
          <img src={Img22} alt="" />
          <ol>
            <li>
              SEARCH: Filter payment history by description, type, and academic
              session
            </li>
            <li>DOWNLOAD INVOICE: Get a copy of your receipt</li>
          </ol>

          <h4>OUTSTANDING PAYMENTS</h4>
          <img src={Img23} alt="" />
          <ol>
            <li>
              {" "}
              PAY NOW: pay your outstanding fees either online via remita or the
              bank
            </li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO ACCESS YOUR SUPPORT</h3>
          <p>
            A Student can access NTI FAQs (Frequently Asked Questions), click on
            the “Support” menu item by navigating in the side menu
          </p>
          <img src={Img24} alt="" />
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO LOG OUT</h3>
          <p>
            A Student can log out by clicking on the “log out” button at the top
            right corner of the screen, as displayed below.
          </p>
          <img src={Img25} alt="" />
        </div>
      </div>
    </PageContainer>
  );
}

export default HowToRegister;
