import {
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  SET_ADMIN_DETAILS,
  SET_APPLICANT_LIST,
  SET_DASHBOARD_ANALYTICS,
  SET_AUDIT_TRAILS_TODAY,
  SET_ADMIN_DATA_UPDATE,
} from "../actions/constants";

const adminData = JSON.parse(localStorage.getItem("nti_admin"));

const defaultState = {
  authenticated: !!adminData,
  adminData,
  applicantList: [],
  analytics: {},
  auditToday: [],
};

const adminReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADMIN_LOGOUT:
      return {
        authenticated: false,
        adminData: null,
        applicantList: [],
        analytics: {},
      };
    case ADMIN_LOGIN:
      return { ...state, authenticated: true, adminData: action.payload };
    case SET_ADMIN_DATA_UPDATE:
      return { ...state, adminData: action.payload };
    case SET_ADMIN_DETAILS:
      return { ...state, adminData: action.payload };
    case SET_DASHBOARD_ANALYTICS:
      return { ...state, analytics: action.payload };
    case SET_APPLICANT_LIST:
      return { ...state, applicantList: action.payload };
    case SET_AUDIT_TRAILS_TODAY:
      return { ...state, auditToday: action.payload };
    default:
      return state;
  }
};
export default adminReducer;
// export default (state = defaultState, action) => {
//   switch (action.type) {
//     case ADMIN_LOGOUT:
//       return { authenticated: false, adminData: null, applicantList: [], analytics: {}};
//     case ADMIN_LOGIN:
//       return { ...state, authenticated: true, adminData: action.payload };
//     case SET_ADMIN_DETAILS:
//       return { ...state, adminData: action.payload };
//     case SET_DASHBOARD_ANALYTICS:
//       return { ...state, analytics: action.payload };
//     case SET_APPLICANT_LIST:
//       return { ...state, applicantList: action.payload };
//     default:
//       return state;
//   }
// };
