import React, {useState} from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import RequestInformation from "../ExternalServices/RequestInformation"
import { useHistory } from "react-router-dom";
import InquirerIllustration from "../../assets/images/InquirerInfoIllustration.jpg";

const InquirerInformation = () => {
  const [active, setActive] = useState("Request Information");
  const history = useHistory();

  return (
    <div>
      <Grid>
        <h1>Inquirer Information</h1>
        <p>Please fill the form with the appropraite information</p>
        <Grid container spacing={10}>
          <Grid item md={9}>
            <div className="external-services-card">
              <Grid container>
                <Grid item md={6}>
                  <p>Your name</p>
                  <TextField
                    id="outlined-basic"
                    label="Enter Fullname"
                    variant="outlined"
                  />
                  <p>Phone Number</p>
                  <TextField
                    id="outlined-basic"
                    label="Enter phone number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6}>
                  <p>Your email address</p>
                  <TextField
                    id="outlined-basic"
                    label="Enter email address"
                    variant="outlined"
                  />
                  <p>Who is requesting?</p>
                  <TextField
                    id="outlined-basic"
                    label="Select institution type"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width:"85%",
                  height: "8rem",
                  alignItems: "center",
                }}
              >
                <button
                 style={{marginRight:"1rem"}}
                  className="external-services-button"
                  onClick={() =>
                    history.push("/external-services/external-layout")
                  }
                >
                  Previous
                </button>
                <button
                style={{marginLeft:"1rem"}}
                  className="external-services-button"
                  onClick={() => setActive("Request Information")}
                >
                  Next
                </button>
              </div>
            </div>
          </Grid>
          <Grid item md={3}>
            <img alt='' src={InquirerIllustration} />
          </Grid>
        </Grid>
      </Grid>
      {active === "Request Information" && <RequestInformation />}
    </div>
  );
};

export default InquirerInformation;
