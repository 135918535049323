import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import PageContainer from "../components/PageContainer";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import PageTitle from "../components/Website/PageTitle";
import Header from "../components/Website/LocationPages/Header";

const Locations = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);

  return (
    <PageContainer>
      <Helmet>
        <title>Locations | NTI</title>
      </Helmet>
      <PageTitle title="Locations" />

      <Header />
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};

export default Locations;
