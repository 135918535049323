import $axios from "./config";

const adminApi = {
  //auth
  async adminLogin(payload) {
    return $axios.post("/api/admin/login", payload);
  },

  async logoutAdmin() {
    return $axios.get("/api/admin/logout");
  },

  // editProfile
  async editAdminProfile(params) {
    return $axios.post("/api/admin/edit/profile", params);
  },
  async editAdminPhoto(params) {
    return $axios.post("/api/admin/edit/photo", params);
  },

  //dashboard
  async getDashboardAnalytics() {
    return $axios.get("/api/admin/dashboard");
  },

  //Applicant
  async getApplicantDashboardTotalCount() {
    return $axios.get(`/api/admin/dashboard/total/applicants`);
  },
  async getApplicantDashboardSubmitted() {
    return $axios.get(`/api/admin/dashboard/submitted/applicants`);
  },
  async getApplicantDashboardRejected() {
    return $axios.get(`/api/admin/dashboard/rejected/applicants`);
  },
  async getApplicantDashboardAdmitted() {
    return $axios.get(`/api/admin/dashboard/admitted/applicants`);
  },
  async getApplicantDashboardIncomplete() {
    return $axios.get(`/api/admin/dashboard/incomplete/applicants`);
  },
  async getApplicantDashboardByProgramme() {
    return $axios.get(`/api/admin/dashboard/programme/applicants`);
  },
  async getApplicantDashboardByZone() {
    return $axios.get(`/api/admin/dashboard/zone/applicants`);
  },
  async getApplicant(id) {
    return $axios.get(`/api/admin/review/${id}`);
  },
  async updateBioData(id, params) {
    return $axios.post(`/api/admin/review/bio-data/${id}`, params);
  },
  async updateProgrammeChoice(id, params) {
    return $axios.post(`/api/admin/review/programme/${id}`, params);
  },
  async grantAdmission(id, params) {
    return $axios.post(`api/admin/review/admission/${id}`, params);
  },

  // get all semester

  async getAllSemester() {
    return $axios.get(`/api/admin/semesters/all`);
  },
  //AudtiTrails
  async getAudtiTrailsToday() {
    return $axios.get(`/api/admin/audits/today`);
  },
  async getAllAudtiTrails() {
    return $axios.get(`/api/admin/audit/trails`);
  },
  async getAudtiTrails() {
    return $axios.get(`api/admin/audit/trails/all`);
  },
  async getAudtiTrailsActions() {
    return $axios.get(`/api/admin/all/trail/actions`);
  },
  async getAudtiTrailsAdmins() {
    return $axios.get(`/api/admin/all/admin`);
  },

  // Triggers
  async handleExportIncomplete() {
    return $axios.get(`/api/admin/incomplete/applicant/export`);
  },
  async handleExportSubmitted() {
    return $axios.get(`/api/admin/submitted/applicant/export`);
  },
  async handleExportRejected() {
    return $axios.get(`/api/admin/rejected/applicant/export`);
  },
  async handleExportAdmitted() {
    return $axios.get(`/api/admin/admitted/applicant/export`);
  },
  async handleExportFreshStud() {
    return $axios.get(`/api/admin/fresh/student/export`);
  },
  async handleExportRegisteredStud() {
    return $axios.get(`/api/admin/registered/student/export`);
  },
  async handleExportReturningStud() {
    return $axios.get(`/api/admin/returning/student/export`);
  },
  async handleExportCourses() {
    return $axios.get(`/api/admin/course/export`);
  },

  //Courses
  async getCoursesOffered() {
    return $axios.get(`/api/admin/courses/offered`);
  },
  async getAllCourses() {
    return $axios.get(`/api/admin/courses`);
  },
  async EditCourse(id, param) {
    return $axios.post(`/api/admin/edit/course/${id}`, param);
  },

  //students
  async getStudentsDashboardTotalCount() {
    return $axios.get(`/api/admin/dashboard/total/students`);
  },
  async getReturningStudentsDashboard() {
    return $axios.get(`/api/admin/dashboard/returning/students`);
  },
  async getFreshStudentsDashboard() {
    return $axios.get(`/api/admin/dashboard/fresh/students`);
  },
  async getRegisterStudentsDashboard() {
    return $axios.get(`/api/admin/dashboard/registered/students`);
  },
  async getUnRegisterStudentsDashboard() {
    return $axios.get(`/api/admin/dashboard/unregistered/students`);
  },
  async getStudentDashboardByZone() {
    return $axios.get(`/api/admin/dashboard/zone/students`);
  },
  async getFreshStudents() {
    return $axios.get(`api/admin/fresh/student`);
  },
  async getRegisterStudents() {
    return $axios.get(`api/admin/registered/student`);
  },
  async getReturningStudents() {
    return $axios.get(`api/admin/returning/student`);
  },
  async getSubmittedApplicants() {
    return $axios.get(`api/admin/submitted/applicant`);
  },
  async getIncompleteApplicants() {
    return $axios.get(`api/admin/incomplete/applicant`);
  },
  async getAdmittedApplicants() {
    return $axios.get(`api/admin/admitted/applicant`);
  },
  async getRejectedApplicants() {
    return $axios.get(`api/admin/rejected/applicant`);
  },

  //staff
  async getAllStaff() {
    return $axios.get(`/api/admin/staff/all`);
  },
  async AddNewStaff(param) {
    return $axios.post(`/api/admin/staff/new`, param);
  },
  async EditStaff(id, param) {
    return $axios.post(`/api/admin/staff/edit/${id}`, param);
  },
  async DeleteStaff(id) {
    return $axios.get(`/api/admin/staff/delete/${id}`);
  },

  //settings
  async getAllAcademicSession() {
    return $axios.get(`/api/admin/settings/sessions`);
  },
  async activateSemester(id) {
    return $axios.get(`/api/admin/settings/semester/activate/${id}`);
  },
  async editSession(id, params) {
    return $axios.post(`/api/admin/settings/sessions/edit/${id}`, params);
  },
  async getApplicationPortal(params) {
    console.log(params);
    return $axios.post(`/api/admin/settings/application`, params);
  },
  async getAllSubjects() {
    return $axios.get(`api/admin/settings/subjects`);
  },
  async AddSubjects(params) {
    return $axios.post(`api/admin/settings/subjects/add`, params);
  },
  async editSubjects(id, params) {
    return $axios.post(`api/admin/settings/subjects/edit/${id}`, params);
  },
  async deleteSubjects(id) {
    return $axios.get(`api/admin/settings/subjects/delete/${id}`);
  },
  async getAllGrades() {
    return $axios.get(`api/admin/settings/grades/all`);
  },
  async editGrades(id, params) {
    return $axios.post(`api/admin/settings/grades/edit/${id}`, params);
  },
  async addGrades(params) {
    return $axios.post(`api/admin/settings/grades/add`, params);
  },
  async deleteGrades(id) {
    return $axios.get(`api/admin/settings/grades/delete/${id}`);
  },
  async getAllZones() {
    return $axios.get(`api/admin/settings/zones/all`);
  },
  async addNewZone(params) {
    return $axios.post(`api/admin/settings/zones/new`, params);
  },
  async editZone(id, params) {
    return $axios.post(`api/admin/settings/zones/edit/${id}`, params);
  },
  async deleteZone(id) {
    return $axios.get(`api/admin/settings/zones/delete/${id}`);
  },
  async getAllCenters() {
    return $axios.get(`api/admin/settings/centers/all`);
  },
  async addCenters(params) {
    return $axios.post(`api/admin/settings/centers/add`, params);
  },
  async editCenters(id, params) {
    return $axios.post(`api/admin/settings/centers/edit/${id}`, params);
  },
  async deleteCenters(id) {
    return $axios.get(`api/admin/settings/centers/delete/${id}`);
  },
  async getAllStates() {
    return $axios.get(`api/admin/settings/states/all`);
  },
  async addNewStates(params) {
    return $axios.post(`api/admin/settings/states/add`, params);
  },
  async editStates(id, params) {
    return $axios.post(`api/admin/settings/states/edit/${id}`, params);
  },
  async deleteStates(id) {
    return $axios.get(`api/admin/settings/states/delete/${id}`);
  },
  async getAllLga() {
    return $axios.get(`api/admin/settings/lga/all`);
  },
  async addLga(params) {
    return $axios.post(`api/admin/settings/lga/add`, params);
  },
  async editLga(id, params) {
    return $axios.post(`api/admin/settings/lga/edit/${id}`, params);
  },
  async deleteLga(id) {
    return $axios.get(`api/admin/settings/lga/delete/${id}`);
  },
  async getAllLevel() {
    return $axios.get(`api/admin/settings/levels/all`);
  },
  async addLevel(params) {
    return $axios.post(`api/admin/settings/levels/add`, params);
  },
  async editLevel(id, params) {
    return $axios.post(`api/admin/settings/levels/edit/${id}`, params);
  },
  async deleteLevel(id) {
    return $axios.get(`api/admin/settings/levels/delete/${id}`);
  },
  async getAllProgramme() {
    return $axios.get(`api/admin/settings/programme/all`);
  },
  async editProgramme(id, params) {
    return $axios.post(`api/admin/settings/programme/edit/${id}`, params);
  },
  async addProgramme(params) {
    return $axios.post(`api/admin/settings/programme/add`, params);
  },
  async deleteProgramme(id) {
    return $axios.get(`api/admin/settings/programme/delete/${id}`);
  },
  async getAllSpecialization() {
    return $axios.get(`api/admin/settings/specialization/all`);
  },
  async getAllSpecialization() {
    return $axios.get(`api/admin/settings/specialization/all`);
  },
  async addSpecialization(params) {
    return $axios.post(`api/admin/settings/specialization/add`, params);
  },
  async editSpecialization(id, params) {
    return $axios.post(`api/admin/settings/specialization/edit/${id}`, params);
  },
  async deleteSpecialization(id) {
    return $axios.get(`api/admin/settings/specialization/delete/${id}`);
  },
  async createCourse(params) {
    return $axios.post(`api/admin/settings/course/add`, params);
  },
  async courseRegActivity(params) {
    return $axios.post(`/api/admin/course/register/activity`, params);
  },
  async settingsInfo(params) {
    return $axios.get(`/api/admin/settings/info`);
  },
  async changeLateRegistration(params) {
    return $axios.post(`/api/admin/student/late/registration`, params);
  },
  async carryoverActivity(params) {
    return $axios.post(`/api/admin/carryover/course/register/activity`, params);
  },
  async registrarSettings(params) {
    return $axios.post(`api/admin/settings/registrar`, params);
  },

  // Reports
  async getAdmissionReport() {
    return $axios.get(`api/admin/report/admission`);
  },
  async getStudentReport(params) {
    return $axios.post(`api/admin/report/student`, params);
  },
  async getStudycenterReport() {
    return $axios.get(`api/admin/report/studycenter`);
  },
  async downloadStudentReport(params) {
    return $axios.post(`api/admin/report/student/download`, params);
  },
  async getEducationalReport() {
    return $axios.get(`api/admin/report/educational`);
  },
  async getScholarshipReport() {
    return $axios.get(`api/admin/report/scholarship`);
  },
  async getFinanceReport() {
    return $axios.get(`api/admin/report/finance`);
  },

  // report trigger download
  async adminssionReportTrigger() {
    return $axios.get(`api/admin/admission/report`);
  },
  async studentReportTrigger() {
    return $axios.get(`api/admin/student/report`);
  },
  async studycenterReportTrigger() {
    return $axios.get(`api/admin/studycenter/report`);
  },
  async financeReportTrigger() {
    return $axios.get(`api/admin/finance/report`);
  },
  async scholarshipReportTrigger() {
    return $axios.get(`api/admin/scholarship/report`);
  },
  async educationalReportTrigger() {
    return $axios.get(`api/admin/educational/report`);
  },

  async getCertificate() {
    return $axios.get(`api/services/certificate/o_level`);
  },

  // bulk feature
  async getAllSchlarships() {
    return $axios.get(`api/admin/scholarships/all`);
  },
  async verifySchlarships(rrr) {
    return $axios.get(`api/admin/scholarship/verify/${rrr}`);
  },
  async createSchlarships(params) {
    return $axios.post(`api/admin/scholarship/create`, params);
  },
  async uploadBeneficiariesSchlarships(id, params) {
    return $axios.post(
      `api/admin/scholarship/upload/beneficiaries/${id}`,
      params,
      {
        timeout: 60 * 60 * 10 * 1000,
      }
    );
  },

  // bulk admission
  async waitingApplicants(params) {
    return $axios.post(`api/admin/admission/waiting`, params);
  },
  async admitWaitingApplicants(params) {
    return $axios.post(`api/admin/admission/waiting/admit`, params);
  },
  async uploadNormalApplication(params) {
    return $axios.post(`api/admin/bulk/application/add`, params);
  },
  async uploadedResult() {
    return $axios.get(`api/admin/uploaded/results`);
  },
  async bulkFeatureScholarshipVerifyRRR(rrr) {
    return $axios.get(`api/admin/scholarship/verify/${rrr}`);
  },
  async bulkFeatureScholarshipPayTuition(id, params) {
    return $axios.post(`api/admin/scholarship/pay/tuition/${id}`, params);
  },
  async bulkFeatureScholarshipRenewPayment(id, params) {
    return $axios.post(`api/admin/scholarship/renew/payment/${id}`, params);
  },
  async bulkFeatureScholarshipUploadBeneficiaries(id, params) {
    return $axios.post(
      `api/admin/scholarship/upload/beneficiaries/${id}`,
      params
    );
  },
  async bulkFeatureScholarshipAddPayment(id, params) {
    return $axios.post(`api/admin/scholarship/add/payment/${id}`, params);
  },
  async bulkFeatureScholarshipViewTransaction(id) {
    return $axios.get(`api/admin/scholarship/transaction/view/${id}`);
  },
  async bulkFeatureScholarshipDownloadBeneficiaries(id) {
    return $axios.get(`api/admin/scholarship/download/beneficiaries/${id}`);
  },

  // exams
  async addTimeTable(params) {
    return $axios.post(`/api/admin/exam/timetable/add`, params);
  },
  async editTimeTable(id, params) {
    return $axios.post(`/api/admin/exam/timetable/edit/${id}`, params);
  },
  async deleteTimeTable(id) {
    return $axios.get(`/api/admin/exam/timetable/delete/${id}`);
  },
  async allTimeTable() {
    return $axios.get(`/api/admin/exam/timetable/all`);
  },
  async getCourseById(id) {
    return $axios.get(`api/admin/result/courses/specialization/${id}`);
  },
  async getSemesterBySession(id) {
    return $axios.get(`/api/admin/semester/session/${id}`);
  },
  async getSemesterByValue(id) {
    return $axios.get(`api/admin/semesters/value/${id}`);
  },

  // result
  async uploadeGrades(params) {
    return $axios.post(`api/admin/result/grades`, params);
  },
  async uploadeCGPA(params) {
    return $axios.post(`api/admin/result/cummulative`, params);
  },
  async uploadeCarryover(params) {
    return $axios.post(`api/admin/result/carryover`, params);
  },

  // fees

  async getAllFeesTypes() {
    return $axios.get(`/api/admin/fee/types/all`);
  },
  async addFeesType(params) {
    return $axios.post(`/api/admin/fee/types/add`, params);
  },
  async editFeesType(id, params) {
    return $axios.post(`/api/admin/fee/types/edit/${id}`, params);
  },
  async deleteFeesType(id) {
    return $axios.get(`/api/admin/fee/types/delete/${id}`);
  },
  async getAllFeeDefination() {
    return $axios.get(`/api/admin/fee/definitions`);
  },
  async createApplicantFeeAssignment(params) {
    return $axios.post(`/api/admin/fee/assignment/applicant`, params);
  },
  async createStudentFeeAssignment(params) {
    return $axios.post(`/api/admin/fee/assignment/student`, params);
  },
  async createGeneralFeeAssignment(params) {
    return $axios.post(`/api/admin/fee/assignment/general`, params);
  },
  async getAllApplicantFeeAssignment() {
    return $axios.get(`/api/admin/fee/assignment/applicant/all`);
  },
  async getAllStudentFeeAssignment() {
    return $axios.get(`/api/admin/fee/assignment/student/all`);
  },
  async getAllGeneralFeeAssignment() {
    return $axios.get(`/api/admin/fee/assignment/general/all`);
  },

  // create student
  async createStudent(params) {
    return $axios.post(`/api/admin/student/create`, params);
  },

  // rrr verification
  async verifyRRR(rrr) {
    return $axios.post(`/api/admin/support/verify/rrr`, rrr);
  },

  // General search
  async generalSearch(params) {
    return $axios.post(`api/admin/general/search`, params);
  },
  async generalSearchEditStudentBio(id, params) {
    return $axios.post(`api/admin/general/student/bio/edit/${id}`, params);
  },
  async generalSearchStudentProgrammeChoice(id, params) {
    return $axios.post(
      `api/admin/general/student/programme/edit/${id}`,
      params
    );
  },
  async waveFee(params) {
    return $axios.post(`api/admin/general/waive/fee`, params);
  },
  async updateEmail(id, email) {
    return $axios.post(`/api/admin/review/email/${id}`, { email });
  },
  async getAllSessions() {
    return $axios.get(`api/admin/sessions/all`);
  },

  // scholarship
  async scholarshipAnalytics() {
    return $axios.get(`/api/admin/scholarship/analytics`);
  },
  async scholarshipData() {
    return $axios.get(`api/admin/scholarship/data`);
  },

  async getTertiaryCertificateType() {
    return $axios.get(`api/services/certificate/tertiary`);
  },

  // forget password
  async forgotPassword(payload) {
    return $axios.post("/api/admin/forgot", payload);
  },
  async resetPassword(payload) {
    return $axios.post("/api/admin/reset", payload);
  },
};
export default adminApi;
