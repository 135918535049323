import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  withRouter,
  Redirect,
} from "react-router-dom";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import { connect } from "react-redux";
import Home from "../pages/Home";
import ApplicantRoutes from "./ApplicantRoutes";
import ForgotPassword from "../pages/applicant/ForgotPassword";
import ResetPassword from "../pages/applicant/ResetPassword";
import ForgotPass from "../pages/student/ForgotPassword";
import ResetPass from "../pages/student/ResetPassword";
import Login from "../pages/applicant/Login";
import AdminLogin from "../pages/admin/Login";
import AdminRoutes from "./AdminRoutes";
import StudentLogin from "../pages/student/Login";
import NewApplicant from "../pages/applicant/NewApplicant";
import ProspectiveStudents from "../pages/ProspectiveStudents";
import Programs from "../pages/Programs";
import ContactUs from "../pages/ContactUs";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import HelpAndSupport from "../pages/HelpAndSupport";
import Locations from "../pages/Locations";
import NewsAndEvents from "../pages/NewsAndEvents";
import ExamTimetable from "../pages/ExamTimetable";
import Error404 from "../pages/Error404";
import { bindActionCreators } from "redux";
import { getServices } from "../redux/actions/serviceAction";
import StudentRoutes from "./StudentRoutes";
import ExternalHome from "../pages/externalServices/ExternalHome";
// import InquirerInformation from '../pages/externalServices/InquirerInformation'
import ExternalLayout from "../pages/externalServices/ExternalLayout";
import Ade from "../pages/programmes/Ade";
import Adee from "../pages/programmes/Adee";
import Bed from "../pages/programmes/Bed";
import Nce from "../pages/programmes/Nce";
import Pgde from "../pages/programmes/Pgde";
import Pttp from "../pages/programmes/Pttp";
import News from "../pages/news-media-event/News";
import Media from "../pages/news-media-event/Media";
import NewsContent from "../pages/news-media-event/NewsContent";
import AdminForgetPassword from "../pages/admin/AdminForgetPassword";
import AdminResetPassword from "../pages/admin/AdminResetPassword";
import HowToRegister from "../pages/studentGuides/HowToRegister";
import HowToApply from "../pages/studentGuides/HowToApply";

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return null;
};

const ResetScroll = withRouter(ScrollToTop);

const Routes = ({ applicant, admin, student, getServices }) => {
  const [applicantAuth, setApplicantAuth] = useState(applicant.authenticated);
  const [adminAuth, setAdminAuth] = useState(admin.authenticated);
  const [studentAuth, setStudentAuth] = useState(student.authenticated);
  const [visibility, setVisibility] = useState(false);

  // // FOR THE SCROLL TO TOP BUTTON
  // useEffect(() => {
  //   const changeVisibility = () => {
  //     if (window.scrollY >= 540) {
  //       setVisibility(true);
  //     } else {
  //       setVisibility(false);
  //     }
  //   };

  //   window.addEventListener("scroll", changeVisibility);

  //   return () => {
  //     window.removeEventListener("scroll", changeVisibility);
  //   };
  // }, []);
  // console.log(visibility);

  useEffect(() => {
    getServices();
  }, [getServices]);

  useEffect(() => {
    setApplicantAuth(applicant.authenticated);
  }, [applicant.authenticated]);

  useEffect(() => {
    setStudentAuth(student.authenticated);
  }, [student.authenticated]);

  useEffect(() => {
    setAdminAuth(admin.authenticated);
  }, [admin.authenticated]);

  const PrivateRoute = ({ component: Component, exact, ...rest }) => {
    const auth = rest.path.includes("admin")
      ? adminAuth
      : rest.path.includes("applicant")
      ? applicantAuth
      : rest.path.includes("student")
      ? studentAuth
      : "";
    return rest.path.includes("applicant") ? (
      <Route
        {...rest}
        render={(props) =>
          auth ? <Component {...props} /> : <Redirect to="/applicant/login" />
        }
        exact={exact}
      />
    ) : rest.path.includes("student") ? (
      <Route
        {...rest}
        render={(props) =>
          auth ? <Component {...props} /> : <Redirect to="/student/login" />
        }
        exact={exact}
      />
    ) : rest.path.includes("admin") ? (
      <Route
        {...rest}
        render={(props) =>
          auth ? <Component {...props} /> : <Redirect to="/admin/login" />
        }
        exact={exact}
      />
    ) : (
      <Route
        {...rest}
        render={(props) =>
          auth ? <Component {...props} /> : <Redirect to="/" />
        }
        exact={exact}
      />
    );
  };
  return (
    <BrowserRouter>
      {/* {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )} */}
      <ResetScroll />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/programs" component={Programs} />
        <Route
          exact
          path="/programmes/advanced-diploma-in-education-ADE"
          component={Ade}
        />
        <Route
          exact
          path="/programmes/advanced-diploma-in-environmental-education-ADEE"
          component={Adee}
        />
        <Route
          exact
          path="/programmes/bachelors-degree-in-education-BED"
          component={Bed}
        />
        <Route
          exact
          path="/programmes/nigeria-certificate-in-education-NCE"
          component={Nce}
        />
        <Route
          exact
          path="/programmes/post-graduate-diploma-in-education-PGDE"
          component={Pgde}
        />
        <Route
          exact
          path="/programmes/pivotal-teacher-training-programme-PTTP"
          component={Pttp}
        />
        <Route
          exact
          path="/news-media-event/media/:id"
          component={NewsContent}
        />
        <Route exact path="/news-media-event/media" component={Media} />
        <Route
          exact
          path="/news-media-event/news/:id"
          component={NewsContent}
        />
        <Route exact path="/news-media-event/news" component={News} />

        <Route exact path="/prospective" component={ProspectiveStudents} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/help-and-support" component={HelpAndSupport} />
        <Route exact path="/locations" component={Locations} />
        <Route exact path="/news-and-events/:Id" component={NewsAndEvents} />
        <Route exact path="/news-and-events" component={NewsAndEvents} />
        <Route exact path="/exam-timetable" component={ExamTimetable} />
        <Route exact path="/applicant/new" component={NewApplicant} />
        <Route exact path="/applicant/login" component={Login} />
        <Route
          exact
          path="/applicant/forgot-password"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/applicant/reset-password"
          component={ResetPassword}
        />
        <Route
          exact
          path="/external-services/external-home"
          component={ExternalHome}
        />
        <Route
          exact
          path="/external-services/external-layout"
          component={ExternalLayout}
        />
        <Route exact path="/how-to-register" component={HowToRegister} />
        <Route exact path="/how-to-apply" component={HowToApply} />

        {/* <Route exact path="/external-services/inquirer-information" component={InquirerInformation} /> */}
        <PrivateRoute path="/applicant" component={ApplicantRoutes} />

        <Route exact path="/student/forgot-password" component={ForgotPass} />
        <Route exact path="/student/reset-password" component={ResetPass} />
        <Route exact path="/student/login" component={StudentLogin} />
        <PrivateRoute path="/student" component={StudentRoutes} />

        <Route exact path="/admin/login" component={AdminLogin} />
        <Route
          exact
          path="/admin/forgot-password"
          component={AdminForgetPassword}
        />
        <Route
          exact
          path="/admin/reset-password"
          component={AdminResetPassword}
        />
        <PrivateRoute path="/admin" component={AdminRoutes} />

        <Route path="**">
          <Error404 />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getServices }, dispatch);
};

export default connect(
  ({ applicant, admin, student }) => ({ applicant, admin, student }),
  mapDispatchToProps
)(Routes);
