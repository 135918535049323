import React, { lazy, Suspense } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Error404 from "../pages/Error404";
// import { ErrorBoundary } from "react-error-boundary";
import Loader from "../components/Loader";

const PayFeesComponent = lazy(() => import("../pages/student/MyFees/PayFee"));
const PaymentHistoryComponent = lazy(() =>
  import("../pages/student/MyFees/PaymentHistory")
);
const OutstandingPaymentComponent = lazy(() =>
  import("../pages/student/MyFees/OutstandingPayment")
);
const DashboardComponent = lazy(() => import("../pages/student/Dashboard"));
const MyProfileComponent = lazy(() => import("../pages/student/MyProfile"));
const MyCoursesComponent = lazy(() => import("../pages/student/MyCourses"));
const MyWalletComponent = lazy(() => import("../pages/student/MyWallet"));
// const MyFeesComponent = lazy(() => import("../pages/student/MyFees"));
const SupportComponent = lazy(() => import("../pages/student/Support"));
const ExamComponent = lazy(() => import("../pages/student/Exam"));
const MyDocumentsComponent = lazy(() => import("../pages/student/MyDocuments"));
const MyResultsComponent = lazy(() => import("../pages/student/MyResults"));
const ResultComponent = lazy(() => import("../pages/student/Result"));
const CourseFormComponent = lazy(() => import("../pages/student/CourseForm"));
const ExamSlipComponent = lazy(() => import("../pages/student/ExamSlip"));
const PayGenFeesComponent = lazy(() => import("../pages/student/PayGenFees"));
const PayAssignedFeesComponent = lazy(() =>
  import("../pages/student/PayAssignedFees")
);
const PayOutstandingFeesComponent = lazy(() =>
  import("../pages/student/PayOutstandingFees")
);
const GeneratedReceiptComponent = lazy(() =>
  import("../pages/student/GeneratedReceipt")
);
const AdmissionLetterComponent = lazy(() =>
  import("../pages/student/AdmissionLetter")
);
const ApplicationFormComponent = lazy(() =>
  import("../pages/student/ApplicationForm")
);
// const TestCheckboxComponent = lazy(() =>
//   import("../pages/student/TestCheckbox")
// );
// const Error404Component = lazy(() => import("../pages/Error404"));

const StudentRoutes = () => {
  const { path, url } = useRouteMatch();

  // console logged by Peter Ihimire
  console.log(Redirect, url);
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route
          exact
          path={`${path}/dashboard`}
          component={DashboardComponent}
        />
        <Route path={`${path}/my-profile`} component={MyProfileComponent} />
        <Route path={`${path}/my-courses`} component={MyCoursesComponent} />
        <Route path={`${path}/my-wallet`} component={MyWalletComponent} />
        <Route
          exact
          path={`${path}/my-fees/pay-general-fees/:Id`}
          component={PayGenFeesComponent}
        />
        <Route
          exact
          path={`${path}/my-fees/pay-assigned-fees/:Id`}
          component={PayAssignedFeesComponent}
        />
        <Route
          exact
          path={`${path}/my-fees/pay-outstanding-fees/:fId/:sId`}
          component={PayOutstandingFeesComponent}
        />
        <Route
          path={`${path}/my-fees/receipt`}
          component={GeneratedReceiptComponent}
        />
        <Route path={`${path}/my-fees/pay-fees`} component={PayFeesComponent} />
        <Route
          path={`${path}/my-fees/payment-history`}
          component={PaymentHistoryComponent}
        />
        <Route
          path={`${path}/my-fees/outstanding-payments`}
          component={OutstandingPaymentComponent}
        />
        {/* <Route path={`${path}/my-fees`} component={MyFeesComponent} /> */}
        <Route path={`${path}/support`} component={SupportComponent} />
        <Route path={`${path}/exam`} component={ExamComponent} />

        <Route
          path={`${path}/my-documents/application-form`}
          component={ApplicationFormComponent}
        />
        <Route
          path={`${path}/my-documents/admission-letter`}
          component={AdmissionLetterComponent}
        />
        <Route
          path={`${path}/my-documents/result`}
          component={ResultComponent}
        />
        <Route
          path={`${path}/my-documents/course-form`}
          component={CourseFormComponent}
        />
        <Route
          path={`${path}/my-documents/exam-card`}
          component={ExamSlipComponent}
        />
        <Route path={`${path}/my-documents`} component={MyDocumentsComponent} />
        {/* <Route
          path={`${path}/test-checkbox`}
          component={TestCheckboxComponent}
        /> */}

        <Route path={`${path}/my-results`} component={MyResultsComponent} />
        <Route path="**">
          <Error404 />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default StudentRoutes;
