import { SET_PROGRAMMES, SET_SERVICES, SET_STATES } from "../actions/constants";

const defaultState = {
  programmesData: [],
  statesData: [],
};

const serviceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SERVICES:
      console.log(...action.payload);
      console.log(...state);
      return { ...action.payload };

    case SET_PROGRAMMES:
      return { ...state, programmesData: action.payload.data };

    case SET_STATES:
      return { ...state, statesData: action.payload.data };
    default:
      return state;
  }
};
export default serviceReducer;
