import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function createData(address, name, phone, email) {
  return { address, name, phone, email };
}

const rows = [
  createData(
    "ADSS Sani Abacha Barracks",
    "John Doe",
    "080 9000 9000",
    "support@nti.edu.ng"
  ),
  createData(
    "ADSS Sani Abacha Barracks",
    "John Doe",
    "080 9000 9000",
    "support@nti.edu.ng"
  ),
  createData(
    "ADSS Sani Abacha Barracks",
    "John Doe",
    "080 9000 9000",
    "support@nti.edu.ng"
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },

  tablehead: {
    border: "2px solid white",
    width: 125,
    backgroundColor: "rgba(52, 74, 61, 1)",
    color: "white",
  },

  tablecell: {
    border: "2px solid rgba(246, 246, 246, 1)",
  },
});

export default function CustomizedTables() {
  const classes = useStyles();

  return (
   
    <div style={{ marginTop: "30px" }} className="course-material-height event-scroll">
      <table className="fee-table-grid">
        <thead>
          <tr>
            <th>Address</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody className="applicant-doc-body">
          <tr>
            <td>
              <p>25 ADDS Sani Abacha Barracks</p>
            </td>
            <td width="90px">
              <p>John Doe</p>
            </td>
            <td>
              <p>08060958123</p>
            </td>
            <td>
              <p>support@gmail.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>25 ADDS Sani Abacha Barracks</p>
            </td>
            <td width="90px">
              <p>John Doe</p>
            </td>
            <td>
              <p>08060958123</p>
            </td>
            <td>
              <p>support@gmail.com</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>25 ADDS Sani Abacha Barracks</p>
            </td>
            <td>
              <p width="90px">John Doe</p>
            </td>
            <td>
              <p>08060958123</p>
            </td>
            <td>
              <p>support@gmail.com</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
