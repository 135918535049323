import {
  SET_STUDENT_DETAILS,
  STUDENT_LOGIN,
  SET_FEE_TYPE,
  STUDENT_LOGOUT,
} from "./constants";
import studentApi from "../api/student";

export const studentLogin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await studentApi.studentLogin(payload);
      console.log(response);
      const { data } = response.data;
      localStorage.removeItem("nti_applicant");
      localStorage.removeItem("nti_admin");
      localStorage.setItem("nti_student", JSON.stringify(data));
      await dispatch({
        type: STUDENT_LOGIN,
        payload: data,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getStudentData = () => {
  return async (dispatch) => {
    try {
      const response = await studentApi.getStudentInformation();

      const studentData = JSON.parse(
        localStorage.getItem("nti_student") || "{}"
      );

      const {
        student,
        my_courses,
        registered_course_count,
        my_carryover_courses,
      } = response.data.data;

      const newStudent = {
        ...studentData,
        ...{
          student,
          my_courses,
          registered_course_count,
          my_carryover_courses,
        },
      };

      localStorage.setItem("nti_student", JSON.stringify(newStudent));
      await dispatch({
        type: SET_STUDENT_DETAILS,
        payload: newStudent,
      });
      return Promise.resolve(newStudent);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getFeeTypes = () => {
  // setLoading(true);

  return async (dispatch) => {
    try {
      const response = await studentApi.getFeeType();
      console.log(response);
      const feeTypeData = JSON.parse(localStorage.getItem("fee_type") || "{}");
      const feeType = response.data.data;
      const allFeeTypeData = { ...feeTypeData, feeType };

      localStorage.setItem("fee_type", JSON.stringify(allFeeTypeData));

      await dispatch({
        type: SET_FEE_TYPE,
        payload: feeType,
      });
      return Promise.resolve(feeType);
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const logoutStudent = () => {
  return async (dispatch) => {
    try {
      const res = await studentApi.logoutStudent();
      return Promise.resolve(res.message);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      localStorage.removeItem("nti_student");
      localStorage.removeItem("fee_type");
      localStorage.removeItem("tuition_table");
      localStorage.removeItem("other_fee_table");
      localStorage.removeItem("student_fee_detail");

      await dispatch({
        type: STUDENT_LOGOUT,
      });
    }
  };
};
