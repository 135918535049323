import React, { useState, useEffect } from "react";

import BoyAnime from "../assets/images/anime-boy.png";
import Quote from "../assets/images/login-lms.png";
import CloudEmpty from "../assets/images/cloud-empty-shadow.svg";
import Close from "../assets/images/green-ex.svg";

const AdvertPopup = ({
  forAnimationClass,
  showClass,
  hideClass,
  AnimePix,
  animeClass,
  quoteClass,
  children,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  // FOR ANIMATION VISIBILITY VIA TIMEOUT
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 10000);
    setInterval(() => {
      setVisible(true);
    }, 900000);
  }, []);

  // REMOVE ANIMATION VISIBILITY
  const removeHandler = () => {
    setVisible(false);
  };
  return (
    <div className={`${forAnimationClass} ${visible ? showClass : hideClass}`}>
      <div
        className="close-animation"
        {...props}
        onClick={() => removeHandler()}
      >
        <img src={Close} alt="" />
      </div>
      <img src={AnimePix} alt="" className={animeClass} />
      <img src={CloudEmpty} alt="" className={quoteClass} />
      {children}
    </div>
  );
};

export default AdvertPopup;
