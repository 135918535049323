import React, { lazy, Suspense } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Error404 from "../pages/Error404";
import Loader from "../components/Loader";

const Courses = lazy(() => import("../pages/admin/Courses"));
const Students = lazy(() => import("../pages/admin/Students"));
const Dashboard = lazy(() => import("../pages/admin/Dashboard"));
const Applicants = lazy(() => import("../pages/admin/Applicants"));
const ReviewApplication = lazy(() =>
  import("../pages/admin/ReviewApplication")
);
const BulkAdmission = lazy(() => import("../pages/admin/BulkAdmission"));
const StudentsPages = lazy(() =>
  import("../components/Admin/student/Students")
);
const AuditTrails = lazy(() => import("../pages/admin/AuditTrails"));
const Fees = lazy(() => import("../pages/admin/Fees"));
const Scholarship = lazy(() => import("../pages/admin/Scholarship"));
const Report = lazy(() => import("../pages/admin/Report"));
const Staff = lazy(() => import("../pages/admin/Staff"));
const Exam = lazy(() => import("../pages/admin/Exam"));
const Settings = lazy(() => import("../pages/admin/Settings"));
const VerifyRRR = lazy(() => import("../pages/admin/VerifyRRR"));
const CreateStudents = lazy(() => import("../pages/admin/CreateStudents"));
const GeneralSearch = lazy(() => import("../pages/admin/GeneralSearch"));
const Profile = lazy(() => import("../pages/admin/Profile"));
const GeneralSearchReviewApplicants = lazy(() =>
  import("../components/Admin/generalSearch/GeneralSearchReviewApplicants")
);
const GeneralSearchEditStudent = lazy(() =>
  import("../components/Admin/generalSearch/GeneralSearchEditStudent")
);

const AdminRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={`${path}/courses`} component={Courses} />
        <Route exact path={`${path}/profile`} component={Profile} />
        <Route exact path={`${path}/students`} component={Students} />
        <Route exact path={`${path}/dashboard`} component={Dashboard} />
        <Route exact path={`${path}/applicants`} component={Applicants} />
        <Route
          exact
          path={`${path}/applicants/:section`}
          component={Applicants}
        />
        <Route
          exact
          path={`${path}/applicants/review-application/:id`}
          component={ReviewApplication}
        />
        <Route
          exact
          path={`${path}/bulk-admission`}
          component={BulkAdmission}
        />
        <Route exact path={`${path}/audit-trails`} component={AuditTrails} />
        <Route exact path={`${path}/fees`} component={Fees} />
        <Route exact path={`${path}/scholarship`} component={Scholarship} />
        <Route exact path={`${path}/reports`} component={Report} />
        <Route exact path={`${path}/staff`} component={Staff} />
        <Route exact path={`${path}/exams`} component={Exam} />
        <Route exact path={`${path}/verify-rrr`} component={VerifyRRR} />
        <Route
          exact
          path={`${path}/create-student`}
          component={CreateStudents}
        />
        <Route
          exact
          path={`${path}/general-search`}
          component={GeneralSearch}
        />
        <Route
          exact
          path={`${path}/general-search/applicant/:id`}
          component={GeneralSearchReviewApplicants}
        />
        <Route
          exact
          path={`${path}/general-search/student/:id`}
          component={GeneralSearchEditStudent}
        />
        <Route exact path={`${path}/settings`} component={Settings} />
        {/* <Route exact path={`${path}/:page_name`} component={BulkAdmission} /> */}
        <Route exact path={`${path}/students/:id`} component={StudentsPages} />

        <Route path="**">
          <Error404 />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AdminRoutes;

// import React, { lazy, Suspense } from "react";
// import { Switch, Route, useRouteMatch } from "react-router-dom";
// import Loader from "../components/Loader";
// import Courses from "../pages/admin/Courses";
// import Students from "../pages/admin/Students";
// import Dashboard from "../pages/admin/Dashboard";
// import Applicants from "../pages/admin/Applicants";
// import Error404 from "../pages/Error404";
// import ReviewApplication from "../pages/admin/ReviewApplication";
// import BulkAdmission from "../pages/admin/BulkAdmission";
// import StudentsPages from "../components/Admin/student/Students";
// import AuditTrails from "../pages/admin/AuditTrails";
// import Fees from "../pages/admin/Fees";
// import Scholarship from "../pages/admin/Scholarship";
// import Report from "../pages/admin/Report";
// import Staff from "../pages/admin/Staff";
// import Exam from "../pages/admin/Exam";
// import Settings from "../pages/admin/Settings";
// import VerifyRRR from "../pages/admin/VerifyRRR";
// import CreateStudents from "../pages/admin/CreateStudents";
// import GeneralSearch from "../pages/admin/GeneralSearch";
// import Profile from "../pages/admin/Profile";
// import GeneralSearchReviewApplicants from "../components/Admin/generalSearch/GeneralSearchReviewApplicants";
// import GeneralSearchEditStudent from "../components/Admin/generalSearch/GeneralSearchEditStudent";

// const ApplicantRoutes = () => {
//   const { path } = useRouteMatch();

//   return (
//     <Switch>
//       <Route exact path={`${path}/courses`} component={Courses} />
//       <Route exact path={`${path}/profile`} component={Profile} />
//       <Route exact path={`${path}/students`} component={Students} />
//       <Route exact path={`${path}/dashboard`} component={Dashboard} />
//       <Route exact path={`${path}/applicants`} component={Applicants} />
//       <Route
//         exact
//         path={`${path}/applicants/:section`}
//         component={Applicants}
//       />
//       <Route
//         exact
//         path={`${path}/applicants/review-application/:id`}
//         component={ReviewApplication}
//       />
//       <Route exact path={`${path}/bulk-admission`} component={BulkAdmission} />
//       <Route exact path={`${path}/audit-trails`} component={AuditTrails} />
//       <Route exact path={`${path}/fees`} component={Fees} />
//       <Route exact path={`${path}/scholarship`} component={Scholarship} />
//       <Route exact path={`${path}/reports`} component={Report} />
//       <Route exact path={`${path}/staff`} component={Staff} />
//       <Route exact path={`${path}/exams`} component={Exam} />
//       <Route exact path={`${path}/verify-rrr`} component={VerifyRRR} />
//       <Route exact path={`${path}/create-student`} component={CreateStudents} />
//       <Route exact path={`${path}/general-search`} component={GeneralSearch} />
//       <Route
//         exact
//         path={`${path}/general-search/applicant/:id`}
//         component={GeneralSearchReviewApplicants}
//       />
//       <Route
//         exact
//         path={`${path}/general-search/student/:id`}
//         component={GeneralSearchEditStudent}
//       />
//       <Route exact path={`${path}/settings`} component={Settings} />
//       {/* <Route exact path={`${path}/:page_name`} component={BulkAdmission} /> */}
//       <Route exact path={`${path}/students/:id`} component={StudentsPages} />

//       <Route path="**">
//         <Error404 />
//       </Route>
//     </Switch>
//   );
// };

// export default ApplicantRoutes;
