import React from "react";
import ReactPaginate from "react-paginate";
import Prev from "../assets/images/prev-paginate.svg";
import Next from "../assets/images/next-paginate.svg";

const pagination = ({
  pageCount,
  containerClassName,
  activeClassName,
  previousClassName,
  nextClassName,
  disabledClassName,
  breakLabel,
  marginPagesDisplayed,
  pageRangeDisplayed,
  onPageChange,
}) => {
  return (
    <ReactPaginate
      previousLabel={<img src={Prev} alt="" width="15px" />}
      nextLabel={<img src={Next} alt="" width="15px" />}
      breakLabel={breakLabel}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      previousClassName={previousClassName}
      nextClassName={nextClassName}
      pageRangeDisplayed={pageRangeDisplayed}
      containerClassName={containerClassName}
      activeClassName={activeClassName}
      disabledClassName={disabledClassName}
      onPageChange={onPageChange}
    />
  );
};

export default pagination;
