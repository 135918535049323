export const APPLICANT_LOGIN = "APPLICANT_LOGIN";
export const APPLICANT_LOGOUT = "APPLICANT_LOGOUT";
export const SET_APPLICANT_DETAILS = "SET_APPLICANT_DETAILS";
export const SET_FEE_AMT = "SET_FEE_AMT";
export const SET_APPLICANT_FEE_RRR = "SET_APPLICANT_FEE_RRR";
export const VERIFY_RRR = "VERIFY_RRR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SET_PAYMENT_RECEIPT = "SET_PAYMENT_RECEIPT";
export const SET_APPLICATION_RECEIPT = "SET_APPLICATION_RECEIPT";

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const SET_ADMIN_DETAILS = "SET_ADMIN_DETAILS";
export const SET_AUDIT_TRAILS_TODAY = "SET_AUDIT_TRAILS_TODAY";
export const SET_ADMIN_DATA_UPDATE = "SET_ADMIN_DATA_UPDATE";

export const STUDENT_LOGIN = "STUDENT_LOGIN";
export const STUDENT_LOGOUT = "STUDENT_LOGOUT";
export const SET_STUDENT_DETAILS = "SET_STUDENT_DETAILS";
export const SET_FEE_TYPE = "SET_FEE_TYPE";

export const SET_SERVICES = "SET_SERVICES";
export const SET_PROGRAMMES = "SET_PROGRAMMES";
export const SET_STATES = "SET_STATES";

export const SET_DASHBOARD_ANALYTICS = "SET_DASHBOARD_ANALYTICS";
export const SET_APPLICANT_LIST = "SET_APPLICANT_LIST";
export const SET_STUDENT_LIST = "SET_STUDENT_LIST";
