import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import { PRIMARY_COLOR } from "../../styles/colors";
import mainStyles from "../../styles/mainStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AlertComponent = ({
  openDialog,
  handleClose,
  handleAlertConfirm,
  processing,
  disabled,
  config,
}) => {
  return (
    <div id='applicant-body'>
      <Dialog
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent style={{ padding: "50px", textAlign: "center" }}>
          {config.image && (
            <Box>
              <img src={config.image} alt='alert' />
            </Box>
          )}

          <Typography
            style={{
              color: config.headerColor ? config.headerColor : PRIMARY_COLOR,
              fontSize: "36px",
              margin: "15px 0",
            }}
          >
            {config.headerText}
          </Typography>

          {config.body ? (
            config.body
          ) : (
            <Typography style={{ marginBottom: "15px" }}>
              {config.text}
            </Typography>
          )}

          {config.btn1Text && (
            <Button
              disabled={disabled}
              onClick={handleClose}
              variant='outlined'
              color={config.btnSec ? "secondary" : "primary"}
              style={styles.btn}
            >
              {config.btn1Text}
            </Button>
          )}

          {config.btn2Text && (
            <Button
              disabled={processing}
              onClick={handleAlertConfirm}
              variant='contained'
              color='primary'
              style={styles.btn}
            >
              {processing ? (
                <CircularProgress color='#FFF' size={20} />
              ) : (
                config.btn2Text
              )}
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const styles = {
  btn: {
    height: "50px",
    margin: "10px 0",
    ...mainStyles.btn,
    width: "100%",
  },
};

export default AlertComponent;
