import {
  PRIMARY_COLOR,
  TRANSPARENT,
  DARK_COLOR,
  WHITE_COLOR,
  GREY_COLOR,
} from "./colors";

const WebStyles = {
  heroHeader: {
    fontWeight: "900",
    fontSize: "42px",
    margin: "0 0 20px",
    color: "#FFF",
  },
  supportImages: {
    width: "100%",
  },
  subHeader: {
    fontWeight: "800",
    fontSize: "16px",
    margin: " 10px 0px",
    color: "black",
  },
  subtab: {
    height: "100%",
    width: "100%",
    padding: "0.5rem",
    textAlign: "center",
    cursor: "pointer",
  },
  locations: {
    // padding: "0 3rem",
    // marginBottom: "2rem",
  },

  newsGrid: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  pageTitle: {
    fontWeight: "800",
    fontSize: "36px",
    color: DARK_COLOR,
    margin: "20px auto",
  },
  text: {
    fontWeight: "normal",
    fontSize: "16px",
    margin: 0,
    lineHeight: "25px",
    color: DARK_COLOR,
  },
  heroContainer: {
    position: "absolute",
  },
  button: {
    backgroundColor: TRANSPARENT,
    color: "#FFF",
    border: "2px solid white",
    minHeight: "50px",
    padding: "5px 80px",
    width: "fit-content",
    fontWeight: "600",
    margin: "50px 15px 20px 0",
    fontFamily: "'Mulish', sans-serif",
    cursor: "pointer",
  },

  greenButton: {
    backgroundColor: PRIMARY_COLOR,
    color: "#FFF",
    border: "2px solid white",
    // minHeight: "40px",
    minHeight: "1.1876em",
    // padding: "5px 80px",
    width: "fit-content",
    fontWeight: "600",
    fontFamily: "'Mulish', sans-serif",
    cursor: "pointer",
  },

  pageHeading: {
    marginLeft: "3rem",
    margin: "1rem 0 0 0",
    color: WHITE_COLOR,
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    position: "relative",
    background: "rgba(0, 200, 83, 0.9)",
  },

  miniTab: {
    listStyleType: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "48px",
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    background: "#F6F6F6",
  },

  miniTabList: {
    width: "25%",
    padding: "auto",
    textAlign: "center",
  },

  accordion: {
    // margin: "4% 10%",
    margin: "0% 0%",
  },

  gridImages: {
    width: "90%",
    height: "auto",
    backgroundColor: PRIMARY_COLOR,
    display: "block",
    margin: "auto",
    position: "relative",
    zIndex: "0",
  },

  prospectiveDiv: {
    position: "absolute",
    top: "60%",
    zIndex: "15",
    margin: "2rem 3rem",
    width: "50%",
    color: "white",
  },

  gridSections: {
    marginTop: "1.5rem",
  },

  section: {
    backgroundColor: WHITE_COLOR,
    padding: "1rem",
    margin: "1rem 0",
    borderRadius: "5px",
    // boxShadow: '1px 1px 10px #d6d6d6'
  },
  sectionTitle: {
    fontSize: "22px",
    color: DARK_COLOR,
    fontWeight: "bold",
  },
  sectionText: {
    color: GREY_COLOR,
  },
};

export default WebStyles;
