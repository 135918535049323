import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import NTIKad from "../../assets/images/nti-kaduna.svg";
import {
  logoutApplicant,
  getApplicantData,
} from "../../redux/actions/applicantAction";
import {
  getStudentData,
  studentLogin,
} from "../../redux/actions/studentAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDFFile } from "../../util/pdfBuilder";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DashboardModal = ({
  openDialog,
  handleClose,
  handleAlertConfirm,
  processing,
  disabled,
  config,
  applicant,
  logoutApplicant,
  getStudentData,
  studentLogin,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [applicantData, setApplicantData] = useState(
    applicant?.applicantData?.applicant || {}
  );
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  // Generate admission letter
  const generateStudentLogin = async () => {
    setLoading(true);
    try {
      await generatePDFFile("studentLogin", applicantData).then(
        async (data) => {
          const pdfDocGenerator = pdfMake.createPdf(data);
          pdfDocGenerator.print();
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // Logs applicant out and redirects them to the student login page
  const applicantLogout = async () => {
    setLogout(true);
    try {
      await logoutApplicant();
      history.push("/student/login");
    } finally {
      setLogout(false);
    }
  };
  return (
    <>
      {/* {logout && <Loader />} */}
      <div id="dashboard-body">
        <Dialog
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth="md"
          fullWidth
        >
          <DialogContent
            style={{
              padding: "50px",
              width: "960px",
              // textAlign: "center"
            }}
            className="dialog-content-mobile"
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img src={NTIKad} alt="alert" />
              <Box>
                <Typography>For support and enquiries</Typography>
                <Typography>0700225569684</Typography>
                <Typography>SMS: 09064579779</Typography>
                <Typography>WhatsApp: 09097807503</Typography>
              </Box>
            </Box>

            <Box
              style={{
                padding: "10px",
                textAlign: "center",
              }}
            >
              <Typography
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "24px",
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  color: "#343434",
                }}
              >
                Your Account Has Been Successfull Created
              </Typography>
              <Typography
                style={{
                  // padding: "10px",
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  color: "#343434",
                }}
              >
                Please find below your Login Credentials
              </Typography>
            </Box>

            {config.bodyOne ? config.bodyOne : "No data"}
            <Box>
              <Typography
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "300",
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  color: "#F2000B",
                }}
              >
                Kindly print this slip and keep safePlease do ensure to change
                your password after logging into your account
              </Typography>
            </Box>
            <Box>
              {config.bodyTwo ? config.bodyTwo : "No data!!"}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{
                    textTransform: "none",
                    margin: "20px 10px 0 0",
                    padding: "10px 50px",
                    backgroundColor: "white",
                    border: "solid 1px #46C35F",
                    color: "#46C35F",
                  }}
                  onClick={() => generateStudentLogin()}
                >
                  {loading ? (
                    <CircularProgress color="#FFF" size={20} />
                  ) : (
                    <Typography>Print</Typography>
                  )}
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    margin: "20px 0 0 0",
                    padding: "10px 50px",
                    backgroundColor: "#46C35F",
                    color: "white",
                  }}
                  onClick={() => applicantLogout()}
                >
                  {logout ? (
                    <CircularProgress color="#FFF" size={20} />
                  ) : (
                    <Typography>Log into Student Account</Typography>
                  )}
                </Button>
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = ({ applicant }) => ({ applicant });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutApplicant,
      getStudentData,
      studentLogin,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardModal);
