import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import mainStyles from "../../styles/mainStyles";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import ForgetImage from "../../assets/images/forget-password-illustration.svg";
import PageContainer from "../../components/PageContainer";
import { useHistory } from "react-router-dom";
import applicantApi from "../../redux/api/applicant";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  applicantLogin,
  logoutApplicant,
  getApplicantData,
  forgotPazzword,
} from "../../redux/actions/applicantAction";

const ForgotPassword = ({ forgotPazzword }) => {
  const history = useHistory();
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [passwordForm, setPasswordForm] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const handleFormChange = ({ name, value }) => {
    setFormError("");
    setPasswordForm({ ...passwordForm, [name]: value });
  };

  const passwordHandler = async (e) => {
    e.preventDefault();
    setFormError("");
    setLoading(true);
    try {
      const res = await applicantApi.forgotPassword(passwordForm);
      history.push("/applicant/reset-password");
    } catch (err) {
      console.log(err);
      setFormError("Error occured");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <Container
        style={isPhone ? mainStyles.phoneContainer : mainStyles.container}
      >
        <Helmet>
          <title>Forgot Password | NTI</title>
        </Helmet>

        <Box
          className="slide-box"
          style={isPhone ? {} : { margin: "20px 100px" }}
        >
          <Paper elevation={5}>
            <Grid
              className="log-div"
              style={{
                ...mainStyles.subContainer,
                ...{ padding: "30px 50px" },
              }}
            >
              <Grid
                className="log-grid"
                item
                xs={12}
                sm={6}
                style={{
                  borderRadius: "10px 0 0 10px",
                  padding: "30px 60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="" src={ForgetImage} />
              </Grid>

              <Grid item xs={12} sm={6} style={{ padding: "20px 15px" }}>
                <Typography
                  className="login-main"
                  style={{
                    fontWeight: 500,
                    fontSize: "30px",
                    margin: "5%",
                    marginBottom: ".5rem",
                  }}
                >
                  Forgot your Password?{" "}
                </Typography>
                <Typography
                  className="log-sub"
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#9F9F9F",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}
                >
                  Enter email address linked with your account and we will send
                  you a message on how to change your password
                </Typography>
                <form onSubmit={(e) => passwordHandler(e)}>
                  <Grid item style={mainStyles.inputGrid}>
                    <TextField
                      label="Email Address"
                      name="email"
                      style={mainStyles.input}
                      variant="outlined"
                      onChange={(e) => handleFormChange(e.target)}
                    />
                    <FormHelperText
                      error
                      style={{ textAlign: "center", marginBottom: "1rem" }}
                    >
                      {formError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} style={mainStyles.inputGrid}>
                    <Box mb={6}>
                      <Button
                        fullWidth
                        style={{ height: "50px" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {loading ? (
                          <CircularProgress color="inherit" size="15px" />
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </PageContainer>
  );
};
const mapStateToProps = ({ applicant }) => ({ applicant });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { applicantLogin, logoutApplicant, getApplicantData, forgotPazzword },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
