import {
  APPLICANT_LOGIN,
  APPLICANT_LOGOUT,
  FORGOT_PASSWORD,
  SET_APPLICANT_DETAILS,
  SET_APPLICANT_FEE_RRR,
  SET_FEE_AMT,
  VERIFY_RRR,
  SET_PAYMENT_RECEIPT,
} from "./constants";
import applicantApi from "../api/applicant";

export const applicantLogin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await applicantApi.loginApplicant(payload);
      const { data } = response.data;
      localStorage.setItem("nti_applicant", JSON.stringify(data));
      await dispatch({
        type: APPLICANT_LOGIN,
        payload: data,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const createApplicant = (payload) => {
  return async (dispatch) => {
    try {
      const user = await (await applicantApi.createApplicant(payload)).data;
      Promise.all([user]).then(async (res) => {
        const { applicant, token } = res[0];

        localStorage.setItem(
          "nti_applicant",
          JSON.stringify({ applicant, token })
        );
        await dispatch({
          type: APPLICANT_LOGIN,
          payload: { applicant, token },
        });
        return Promise.resolve({ applicant, token });
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getApplicantData = () => {
  return async (dispatch) => {
    try {
      const response = await applicantApi.getApplicantInformation();

      const applicantData = JSON.parse(
        localStorage.getItem("nti_applicant") || "{}"
      );

      const { applicant, user } = response.data.data;

      const newApplicant = { ...applicantData, ...{ applicant, user } };

      localStorage.setItem("nti_applicant", JSON.stringify(newApplicant));
      await dispatch({
        type: SET_APPLICANT_DETAILS,
        payload: newApplicant,
      });
      return Promise.resolve(newApplicant);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getApplicantFeeRRR = () => {
  return async (dispatch) => {
    try {
      const response = await applicantApi.getApplicantFeeRRR();

      const { applicant, user } = response.data.data;
      const appFeeRRR = { applicant, user };
      await dispatch({
        type: SET_APPLICANT_FEE_RRR,
        payload: appFeeRRR,
      });
      return Promise.resolve(appFeeRRR);
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const getFeeAmtData = (programmeId) => {
  return async (dispatch) => {
    try {
      const response = await applicantApi.getFeeAmt(programmeId);
      let feeAmtData = response.data;

      await dispatch({
        type: SET_FEE_AMT,
        payload: feeAmtData,
      });
      return Promise.resolve(feeAmtData);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const verifiedRRR = (payload) => {
  return async (dispatch) => {
    try {
      const res = await applicantApi.verifyRRR(payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      await dispatch({
        type: VERIFY_RRR,
      });
    }
  };
};

export const forgotPazzword = (payload) => {
  return async (dispatch) => {
    try {
      const res = await applicantApi.forgotPassword(payload);
      const { data } = res.data;
      await dispatch({
        type: FORGOT_PASSWORD,
        payload: data,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const paymentReceipt = (payload) => {
  return async (dispatch) => {
    try {
      const res = await applicantApi.transactionReceipt(payload);
      const { data } = res.data;
      await dispatch({
        type: SET_PAYMENT_RECEIPT,
        payload: data,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const logoutApplicant = () => {
  return async (dispatch) => {
    try {
      const res = await applicantApi.logoutApplicant();
      return Promise.resolve(res.message);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      localStorage.removeItem("nti_applicant");
      await dispatch({
        type: APPLICANT_LOGOUT,
      });
    }
  };
};
