import React, { useEffect, useState } from "react";
import servicesApi from "../../../redux/api/services";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Parser from "html-react-parser";
import moment from "moment";
import NewsAvater from "../../../assets/images/gallery-avatar.png";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";

const ImportantDates = () => {
  const eventsData = JSON.parse(localStorage.getItem("events_data") || "[]");
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const [importantEvents, setImportantEvents] = useState([]);
  const [courseEvents, setCourseEvents] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [newsMediaEvents, setNewsMediaEvents] = useState([]);
  const [newsEvents, setNewsEvents] = useState([]);
  const [mediaEvents, setMediaEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [calendarNews, setCalendarNews] = useState([]);
  const [calendarNews2, setCalendarNews2] = useState([]);
  const [calendarNews3, setCalendarNews3] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  // Use this to get events
  useEffect(() => {
    setLoading(true);
    const dashboardEvents = async () => {
      console.log("first");
      try {
        const importantEvents = await servicesApi.getImportantEvents();
        console.log(importantEvents);
        const dataResponse = await servicesApi.getEvents();
        const courseEvents = await servicesApi.getCourseEvents();
        const newsEvents = await servicesApi.getNewsEvents();
        const mediaEvents = await servicesApi.getMediaEvents();

        // console.log(dataResponse);
        const data = dataResponse.data;
        localStorage.setItem("events_data", JSON.stringify(data));
        setEvents(data);
        setImportantEvents(importantEvents?.data);
        setCourseEvents(courseEvents?.data);
        setNewsEvents(newsEvents?.data);
        setMediaEvents(mediaEvents.data);
        setNewsMediaEvents(newsEvents?.data.concat(mediaEvents?.data));
        setCalendarNews(newsEvents?.data.concat(mediaEvents?.data));
        setCalendarNews2(importantEvents?.data);
        setCalendarNews3(
          newsEvents?.data.concat(mediaEvents?.data, importantEvents?.data)
        );
      } catch (error) {
        console.log("err", error);
      } finally {
        setLoading(false);
      }
    };
    dashboardEvents();
  }, []);

  // Function to update selected date and calander text
  const handleDateChange = (value) => {
    setDate(value);
    // console.log(`The selected Date is ${value.toDateString()}`);

    const newData = newsMediaEvents?.filter((item) => {
      // console.log(item);
      return moment(item.date).format("LL") === moment(value).format("LL");
    });
    const newData2 = importantEvents?.filter((item) => {
      // console.log(item);
      return moment(item.date).format("LL") === moment(value).format("LL");
    });

    setCalendarNews(newData);
    setCalendarNews2(newData2);
  };

  return (
    <div className="news-sectio" style={{ background: "#fff" }}>
      <main className="container content grid-paynows ">
        <div data-aos="fade-up" className="right-paynow ">
          <div style={{ marginBottom: "30px" }}>
            <h3 style={{ fontSize: "20px", fontWeight: "700px" }}>
              Important Dates
            </h3>
          </div>
          {loading ? (
            <div className="for-important">
              <CircularProgress />
            </div>
          ) : (
            <div className="important-dates event-scroll">
              {calendarNews2?.length === 0 || "" || undefined ? (
                <div className="for-important">
                  <h4>No news available!</h4>
                </div>
              ) : (
                calendarNews2?.map((event) => {
                  return (
                    <div
                      className="dates-card-blue"
                      style={{ marginBottom: "20px", cursor: "pointer" }}
                      key={event.id}
                      onClick={() => {
                        history.push(`/news-media-event/news/${event?.id}`);
                      }}
                    >
                      <div className="for-dates-img">
                        <img
                          className="image"
                          src={`https://www.nti.edu.ng/static/media/Logo.b5d023f99a9bae47a2886b993915b4d9.svg`}
                          alt=""
                          width="200px"
                          height="150px"
                        />
                      </div>

                      <div
                        className="for-dates-txt"
                        style={{ marginLeft: "20px" }}
                      >
                        <p>{`${moment(event?.created_at).format("LL")}`}</p>
                        <h3>{Parser(event?.title)}</h3>
                        {Parser(`${event?.paragraph?.slice(0, 40)}...`)}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
        <div data-aos="fade-up" className="left-paynow">
          <div style={{ marginBottom: "30px" }}>
            <h3 style={{ fontSize: "20px", fontWeight: "700px" }}>
              School Calendar
            </h3>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <div className="calendar-container">
              <Calendar
                onChange={handleDateChange}
                value={date}
                tileClassName={({ date, view }) => {
                  if (
                    calendarNews3.find(
                      (event) =>
                        moment(event?.created_at).format("LL") ===
                        moment(date).format("LL")
                    )
                  ) {
                    return "calendar-event-highlight";
                  }
                }}
              />
            </div>
          </div>

          {loading ? (
            <div className="for-sch">
              <CircularProgress />
            </div>
          ) : (
            <div className="school-calendar event-scroll">
              {calendarNews?.length === 0 || "" || undefined ? (
                <div className="for-sch">
                  <h4>No news available!</h4>
                </div>
              ) : (
                React.Children.toArray(
                  calendarNews?.map((event) => {
                    return (
                      <div
                        className="dates-card-2"
                        style={{ marginBottom: "20px", cursor: "pointer" }}
                        onClick={() => {
                          history.push(
                            `/news-media-event/${
                              event?.category === "news"
                                ? "news"
                                : event?.categories === "media"
                                ? "media"
                                : ""
                            }/${event?.id}`
                          );
                        }}
                      >
                        <div className="for-dates-img">
                          <img
                            className="image"
                            src={`https://www.nti.edu.ng/static/media/Logo.b5d023f99a9bae47a2886b993915b4d9.svg`}
                            alt=""
                            width="100px"
                            height="100px"
                          />
                        </div>
                        <div
                          className="for-dates-txt"
                          style={{ marginLeft: "20px" }}
                        >
                          <p>{`${moment(
                            event?.updated_at
                              ? event?.updated_at
                              : event?.created_at
                          ).format("LL")}`}</p>
                          <h3>{Parser(event?.title?.slice(0, 25))}</h3>
                          {Parser(`${event?.paragraph.slice(0, 30)}...`)}
                        </div>
                      </div>
                    );
                  })
                )
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ImportantDates;
