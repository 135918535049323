import PageContainer from "../../components/PageContainer";
import Tab from "../../components/Website/NewsEventTab";
import { Helmet } from "react-helmet";
import PageTitle from "../../components/Website/PageTitle";
import { Menu, Search, ExpandLess } from "@material-ui/icons";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Grid } from "@material-ui/core";
import servicesApi from "../../redux/api/services";
import Parser from "html-react-parser";
import moment from "moment";
import Pagination from "../../components/CustomPaginate";
import MapImg from "../../assets/images/map-image.png";
import { CircularProgress } from "@material-ui/core";

const News = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const eventsData = JSON.parse(localStorage.getItem("events_data") || "[]");

  const [events, setEvents] = useState([]);
  const [section, setSection] = useState("brief"); // value can be 'brief' || 'full'
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const [pageNum, setPageNum] = useState(0);
  const historyPerPage = 6;
  const pagesVisited = pageNum * historyPerPage;

  // Handles page count numbers
  const pageCount = Math.ceil(events && events.length / historyPerPage);

  //Handle page click
  const changePageHandler = ({ selected }) => {
    return setPageNum(selected);
  };

  // Use this to get events
  useEffect(() => {
    setLoading(true);
    const dashboardEvents = async () => {
      try {
        const dataResponse = await servicesApi.getNewsEvents();
        const data = dataResponse.data;
        setEvents(data);
      } catch (error) {
        console.log("err", error);
      } finally {
        setLoading(false);
      }
    };
    dashboardEvents();
  }, []);
  // console.log(events);

  let oneEvent = events?.find((item) => {
    return item.id === selectedId;
  });
  // console.log(oneEvent);
  // console.log(selectedId);

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);

  return (
    <div>
      <Grid>
        <PageContainer>
          <Helmet>
            <title>News | NTI</title>
          </Helmet>
          <PageTitle title="News And Events" />
          <Tab />
          <div className=" container content">
            <>
              {loading ? (
                <div className="for-news-media">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {events?.length === 0 || "" || undefined ? (
                    <div className="for-news-media">
                      <h4>No news available!</h4>
                    </div>
                  ) : (
                    <div className="grid-events">
                      {events
                        ?.slice(pagesVisited, pagesVisited + historyPerPage)
                        .map((event) => {
                          return (
                            <div
                              className="dates-card"
                              style={{ marginBottom: "20px" }}
                              key={event?.id}
                              onClick={() => {
                                setSelectedId(event?.id);
                                history.push(
                                  `/news-media-event/news/${event?.id}`
                                );
                              }}
                            >
                              <div className="for-dates-img">
                                <img
                                  className="image"
                                  src={`https://cms.nti.edu.ng/public/images/${event?.image}`}
                                  alt=""
                                  width="150px"
                                  height="150px"
                                />
                              </div>

                              <div
                                className="for-dates-txt"
                                style={{ marginLeft: "20px" }}
                              >
                                <p>{`${moment(event?.created_at).format(
                                  "LL"
                                )}`}</p>
                                <h3>{event?.title?.slice(0, 40)}</h3>
                                {Parser(`${event?.paragraph?.slice(0, 40)}...`)}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </>
              )}
              <div className="events-pagination-div">
                <Pagination
                  pageCount={pageCount}
                  onPageChange={changePageHandler}
                  containerClassName="pagination"
                  activeClassName="paginate-active"
                  disabledClassName="paginate-disabled"
                  previousClassName="paginate-previous"
                  nextClassName="paginate-next"
                  breakLabel="..."
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                />
              </div>
            </>
          </div>
          {visibility && (
            <button
              onClick={() => window.scrollTo(0, 0)}
              className="scroll-top top-btn"
            >
              <ExpandLess />
            </button>
          )}
        </PageContainer>
      </Grid>
    </div>
  );
};

export default News;
