import {
  STUDENT_LOGIN,
  STUDENT_LOGOUT,
  SET_STUDENT_DETAILS,
  SET_FEE_TYPE,
} from "../actions/constants";

const studentData = JSON.parse(localStorage.getItem("nti_student"));
const feeTypeData = JSON.parse(localStorage.getItem("fee_type"));

const defaultState = {
  authenticated: !!studentData,
  studentData,
  feeTypeData,
};

const studentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case STUDENT_LOGOUT:
      return { authenticated: false, studentData: null };
    case STUDENT_LOGIN:
      return { ...state, authenticated: true, studentData: action.payload };
    case SET_STUDENT_DETAILS:
      return { ...state, studentData: action.payload };
    case SET_FEE_TYPE:
      return { ...state, feeTypeData: action.payload };
    default:
      return state;
  }
};
export default studentReducer;
