import $axios from "./config";
import axios from "axios";
import { store } from "../../App";

// const baseURL = "https://nti-v3-api-suafh.ondigitalocean.app/";

const getUser = () => {
  const applicant = store.getState().applicant;
  const admin = store.getState().admin;
  const token = admin.authenticated
    ? admin.adminData.token
    : applicant.authenticated
    ? applicant.applicantData.token
    : "";

  return token || "";
};

const config = { headers: { "X-Requested-With": "XMLHttpRequest" } };

const applicantApi = {
  async loginApplicant(payload) {
    return $axios.post("/api/applicants/login", payload);
  },
  async getApplicantInformation() {
    return $axios.get("/api/applicants/user");
  },

  async getApplicantionReceipt() {
    return $axios.get("/api/applicants/application/fee/receipt");
  },

  async completeApplication() {
    return $axios.get("/api/applicants/application/submit");
  },

  async setProgrammeDetails(payload) {
    return $axios.post("/api/applicants/application/programme", payload);
  },
  async logoutApplicant() {
    return $axios.get("/api/applicants/logout");
  },

  async createApplicant(payload) {
    return $axios.post("/api/applicants/register", payload);
  },

  async personalInformation(payload) {
    return $axios.post("/api/applicants/application/personal", payload);
  },

  async editPersonalInfo(payload) {
    return $axios.post("/api/applicants/application/edit", payload);
  },

  async forgotPassword(payload) {
    return $axios.post("/api/applicants/forgot", payload);
  },
  async resetPassword(payload) {
    return $axios.post("/api/applicants/reset", payload);
  },
  // async educationInformationNormal(payload) {
  //   return $axios.post(
  //     "/api/applicants/application/certificate/normal",
  //     payload,
  //   );
  // },

  async educationInformationNormal(formData) {
    return axios.post(
      "https://nti-v-3-api-odzih.ondigitalocean.app/api/applicants/application/certificate/normal",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getUser(),
        },
      }
    );
  },

  async educationInformationTertiary(formData) {
    return axios.post(
      "https://nti-v-3-api-odzih.ondigitalocean.app/api/applicants/application/certificate/tertiary",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getUser(),
        },
      }
    );
  },

  async workPlaceExperience(payload) {
    return $axios.post("/api/applicants/application/experience", payload);
  },

  // async saveDigitalLiteracy(payload) {
  //   return $axios.post("/api/applicants/digital-literacy", payload);
  // },
  async uploadProfilePicture(formData) {
    return axios.post(
      "https://api.cloudinary.com/v1_1/omniswift/image/upload",
      formData,
      config
    );
  },
  async uploadDocument(formData) {
    return axios.post(
      "https://nti-v-3-api-odzih.ondigitalocean.app/api/applicants/application/document",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getUser(),
        },
      }
    );
  },

  async savePicture(formData) {
    return axios.post(
      "https://nti-v-3-api-odzih.ondigitalocean.app/api/applicants/application/photo",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getUser(),
        },
      }
    );
  },

  // async updatePersonalInformation(payload) {
  //   return $axios.post("/api/applicants/personal-information", payload);
  // },
  // async savePicture(payload) {
  //   return $axios.post("/api/applicants/upload-profile-picture", payload);
  // },
  // async updateCertificateObtained(certificate_type, payload) {
  //   return $axios.post(
  //     `/api/applicants/certificate/${certificate_type}`,
  //     payload,
  //   );
  // },
  // async updateWorkExperience(payload) {
  //   return $axios.post("/api/applicants/experience", payload);
  // },
  // async completeApplication() {
  //   return $axios.post("/api/applicants/completedStatus");
  // },
  // async getFeeFormData() {
  //   return $axios.post(`/api/applicants/payment/process`);
  // },

  async getFeeAmt(payload) {
    return $axios.get(`/api/applicants/application/fee/amount/${payload}`);
  },
  // async saveRRR(payload) {
  //   return $axios.post(`/api/applicants/payment/save-rrr`, payload);
  // },

  // async savePaymentTransaction(payload) {
  //   return $axios.post("/api/applicants/payment/verification", payload);
  // },

  //   Added this to get the application fee RRR
  async getApplicantFeeRRR() {
    return $axios.get("/api/applicants/application/fee/rrr");
  },

  // verify RRR POST
  async verifyRRRPost(payload) {
    return $axios.post(`/api/applicants/application/fee/verify`, payload);
  },

  // verify RRR GET
  async verifyRRRGet(payload) {
    return $axios.get(`/api/applicants/verify/${payload}`);
  },

  // Tuition Fee RRR
  async getTuitionFeeRRR() {
    return $axios.get("/api/applicants/application/tuition");
  },

  // Verify Tuition RRR POST
  async verifyTuitionPost(payload) {
    return $axios.post(
      "/api/applicants/application/tuition/fee/verify",
      payload
    );
  },

  // verify Tuition RRR GET
  async verifyTuitionGet(payload) {
    return $axios.get(
      `/api/applicants/application/tuition/fee/verify/${payload}`
    );
  },

  // Payment History
  async getPaymentHistory() {
    return $axios.get(`/api/applicants/application/payment/history`);
  },

  // General Fees
  async getGeneralFees() {
    return $axios.get(`/api/applicants/application/general/fees`);
  },

  //Pay General Fees
  async payGeneralFees(payload) {
    return $axios.get(
      `/api/applicants/application/general/fees/pay/${payload}`
    );
  },

  //Transactions Receipt
  async transactionReceipt(payload) {
    return $axios.get(`/api/applicants/application/get/transaction/${payload}`);
  },

  //Transactions Receipt General
  async transactionReceiptGeneral(payload) {
    return $axios.get(
      `/api/applicants/application/get/transaction/general/${payload}`
    );
  },

  //Pay Any Fees
  async payAnyFee(payload) {
    return $axios.get(`/api/applicants/application/pay/unpaid/fee/${payload}`);
  },

  //Verify Unpaid Fees Get
  async verifyUnpaidGet(payload) {
    return $axios.get(
      `/api/applicants/application/get/verify/unpaid/fee/${payload}`
    );
  },

  //Verify Unpaid Fees Post
  async verifyUnpaidPost(payload) {
    return $axios.post(
      `/api/applicants/application/verify/unpaid/fee`,
      payload
    );
  },

  // All Documents
  async getDocuments() {
    return $axios.get(`/api/applicants/application/documents`);
  },

  // Accept Admission
  async acceptAdmission() {
    return $axios.get(`/api/applicants/application/accept/admission`);
  },

  // admission letter
  async getAdmissionLetter() {
    return $axios.get(`/api/applicants/application/admission/letter`);
  },

  // dashboard events
  async getEvents() {
    return axios.get(
      `http://139.59.188.88/wp-json/tribe/events/v1/events/?start_date=2017-09-08&per_page=2`
    );
  },
};
export default applicantApi;
