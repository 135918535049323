import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import AOS from "aos";
import "aos/dist/aos.css";
import HowToLogIn from "../../../assets/images/how-to.png";
import { useMediaQuery } from "react-responsive";

SwiperCore.use([Pagination, Autoplay]);

const HowToLms = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <div
      data-aos="fade-up"
      className="how-to-lms-wrapper"
      style={{
        flexDirection: isPhone && "column",
      }}
    >
      <div className="how-to-lms">
        <h3 style={{ fontSize: "30px", fontWeight: "800px" }}>
          Important Information
        </h3>
        <p>
          Ensure that you have registered for the current session before trying
          to access your learning portal
        </p>

        <h3 style={{ fontSize: "30px", fontWeight: "800px" }}>
          NTI SERVICOM Division
        </h3>
        <p>
          If your complaint is unresolved or you are dissatisfied with the
          resolution use our Online Complaint Form
        </p>
      </div>

      <div className="how-to-lms-img">
        <img src={HowToLogIn} alt="HowToLogIn" width={"100%"} />
      </div>
    </div>
  );
};

export default HowToLms;
