import * as colors from "./colors";

const mainStyles = {
  container: {
    padding: "50px",
  },
  phoneContainer: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "25px",
  },
  pageTitle: {
    fontWeight: "600",
    fontSize: "28px",
    color: colors.DARK_COLOR,
  },
  subContainer: {
    backgroundColor: colors.WHITE_COLOR,
    padding: "70px",
    margin: "30px 0",
    boxShadow: "0px 0px 5px 2px #E8E8E8",
  },
  subPhoneContainer: {
    padding: "50px 10px",
  },
  subContainerTwo: {
    backgroundColor: colors.WHITE_COLOR,
    padding: "10px",
    margin: "30px 0",
    boxShadow: "0px 0px 5px 2px #E8E8E8",
  },
  subPhoneContainerTwo: {
    padding: "50px 10px",
  },

  subContainerThree: {
    backgroundColor: colors.WHITE_COLOR,
    padding: "0px",
    margin: "30px 0",
    // boxShadow: "0px 0px 5px 2px #E8E8E8",
  },
  subPhoneContainerThree: {
    padding: "50px 10px",
  },
  input: {
    color: colors.DARK_COLOR,
    width: "100%",
    marginBottom: 10,
    height: 65,
  },
  verifyRRRInput: {
    color: colors.DARK_COLOR,
    width: "100%",
    marginBottom: 0,
    height: 60,
  },
  inputGrid: {
    margin: "25px 25px 0",
  },
  inputGridLms: {
    margin: "0px 25px",
  },
  selectLabel: {
    marginBottom: ".5rem",
    color: colors.DARK_COLOR,
  },
  radioInput: {
    borderRadius: "4px",
    border: "1px solid",
    width: "45%",
    margin: 0,
    borderColor: "#bbb",
    padding: "6px",
  },
  btn: {
    height: "55px",
    width: "48%",
    textTransform: "capitalize",
    boxShadow: "none",
  },
};

export default mainStyles;
