import {
  APPLICANT_LOGOUT,
  APPLICANT_LOGIN,
  SET_APPLICANT_DETAILS,
  SET_FEE_AMT,
  SET_APPLICANT_FEE_RRR,
  FORGOT_PASSWORD,
  SET_PAYMENT_RECEIPT,
} from "../actions/constants";

const applicantData = JSON.parse(localStorage.getItem("nti_applicant"));

const defaultState = {
  authenticated: !!applicantData,
  applicantData: applicantData,
  applicantFee: "",
  receipt: {},
  applicantFeeRrrData: {},
};

const applicantReducer = (state = defaultState, action) => {
  switch (action.type) {
    case APPLICANT_LOGOUT:
      return { authenticated: false, applicantData: null };

    case APPLICANT_LOGIN:
      return { ...state, authenticated: true, applicantData: action.payload };

    case SET_APPLICANT_DETAILS:
      return { ...state, applicantData: action.payload };

    case SET_FEE_AMT:
      return { ...state, applicantFee: action.payload };

    case SET_APPLICANT_FEE_RRR:
      return { ...state, applicantFeeRrrData: action.payload };

    case SET_PAYMENT_RECEIPT:
      return { ...state, receipt: action.payload };
    default:
      return state;
  }
};
export default applicantReducer;
// export default (state = defaultState, action) => {
//   switch (action.type) {
//     case APPLICANT_LOGOUT:
//       return { authenticated: false, applicantData: null};
//     case APPLICANT_LOGIN:
//       return { ...state, authenticated: true, applicantData: action.payload };
//     case SET_APPLICANT_DETAILS:
//       return { ...state, applicantData: action.payload };
//     default:
//       return state;
//   }
// };
