import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const ApplicantFooter = () => {
  return (
    <footer id="applicant">
      <Box style={{ textAlign: "center" }}>
        <Typography style={{ fontSize: "14px" }}>
          Copyright © 2023 National Teachers Institute. All rights reserved.
          Powered by{" "}
          <a
            className="dash-footer-link"
            rel="noreferrer"
            target="_blank"
            href="https://omniswift.com/"
            style={{ color: "#46c35f" }}
          >
            Omnswift
          </a>{" "}
          |{" "}
          <Link
            className="dash-footer-link"
            to="https://subdomain.nti.edu.ng/terms-and-conditions"
            style={{ color: "#46c35f" }}
          >
            Terms of Use
          </Link>{" "}
          |{" "}
          <Link
            className="dash-footer-link"
            to="https://subdomain.nti.edu.ng/privacy-policy"
            style={{ color: "#46c35f" }}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </footer>
  );
};

export default ApplicantFooter;
