// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";

// const useStyles = makeStyles({
//   root: {
//     minWidth: 320,
//     minHeight: 100,
//     borderLeft: "3px solid #00C853",
//     cursor: "pointer",
//     marginTop: "1rem",
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
//   content: {
//     padding: "1.5rem",
//   },
// });

// export default function SimpleCard({ heading, text, date, link, style}) {
//   const classes = useStyles();
//   const [textColor, setTextcolor] = useState("#353535");
//   const [border, setBorder] = useState("trasparent");

//   const handleStyle = () => {
//     setTextcolor("#00C853");
//     setBorder("1px solid #00C853");
//   };
//   return (
//     <>
//     <a href={link}>
//     <Card
//       className={classes.root}
//       style={{ borderTop: border, borderRight: border, borderBottom: border }}
//       onClick={handleStyle}
//     >
//       <CardContent className={classes.content}>
//         <Typography
//           variant="body2"
//           component="p"
//           style={{ paddingBottom: "1rem" }}
//         >
//           {date}
//         </Typography>
//         <Typography
//           variant="body1"
//           component="h2"
//           style={{ fontWeight: "600", color: textColor }}
//         >
//           {heading}
//         </Typography>

//         <Typography variant="body2" component="p">
//           {text}
//         </Typography>
//       </CardContent>
//     </Card>

//     </a>
//     </>
//      );
// }


import React from 'react'
import { Grid } from "@material-ui/core";


const Card = ({title, minimum, years, sideTitle}) => {
  const cardStyle = {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      borderLeft: '3px solid #00C853',
      width: '100%',
      padding:'1rem 2rem',
      fontWeight: 600,
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px'
  }

  const greySide = {
    background:'#F6F6F6',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column'
  }

  const containerStyle = {
    margin: '2rem 0',
    minHeight: '100px',
    boxShadow:'0px 3px 6px rgba(0, 0, 0, 0.12)',
  }
  return (
   

    <Grid container style={containerStyle} >
    <Grid item xs={10}style={cardStyle}>{title}</Grid>
    <Grid item xs={2}style={greySide}>
      <p style={{marginBottom:"-1rem"}}>{minimum}</p>
        <h2 style={{color: "#00C853"}}>{sideTitle}</h2>
      <p style={{marginTop:"-1rem"}}>{years}</p>
    </Grid>
  </Grid>
  )
}

export default Card
