import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Img1 from "../../assets/images/applicant-getting-started.png";
import Img2 from "../../assets/images/applicant-sign-in.png";
import Img_2_1 from "../../assets/images/applicant-sign-in2.png";
import Img3 from "../../assets/images/forget-password-how.png";
import Img4 from "../../assets/images/reset-password-how.png";
import Img5 from "../../assets/images/reset-pop-up.png";
import Img6 from "../../assets/images/applicant-process1.png";
import Img7 from "../../assets/images/applicant-process2.png";
import Img8 from "../../assets/images/applicant-process3.png";
import Img9 from "../../assets/images/applicant-process4.png";
import Img10 from "../../assets/images/applicant-process5.png";
import Img11 from "../../assets/images/applicant-process6.png";
import Img12 from "../../assets/images/applicant-process7.png";
import Img13 from "../../assets/images/applicant-process8.png";
import Img14 from "../../assets/images/applicant-process9.png";
import Img15 from "../../assets/images/applicant-process10.png";

import Img16 from "../../assets/images/access-applicant-dash1.png";
import Img17 from "../../assets/images/access-applicant-dash2.png";
import Img18 from "../../assets/images/access-applicant-dash3.png";
import Img19 from "../../assets/images/access-applicant-dash4.png";
import Img20 from "../../assets/images/access-applicant-dash5.png";
import Img21 from "../../assets/images/access-applicant-dash6.png";
import Img22 from "../../assets/images/applicant-process11.png";
import Img23 from "../../assets/images/applicant-process12.png";
import Img25 from "../../assets/images/logout-how.png";
import { Helmet } from "react-helmet";
import PageTitle from "../../components/Website/PageTitle";

function HowToApply() {
  return (
    <PageContainer>
      <Helmet>
        <title>How to apply | NTI</title>
      </Helmet>
      <PageTitle title="How To Apply" />

      <div
        className="how-to-register-page"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ color: "#46c35f" }}>Applicant User Guide</h1>

        <h3> OVERVIEW </h3>

        <p>
          The NTI application portal is a Web-accessible platform that delivers
          NTI related services (account creation, Application for Admission).
          The Portal provides the NTI application eforms which consists of
          various section (i.e. Personal Information, Educational Information,
          Work Experience Information) in an easily understandable format, where
          users/applicant can provide, modify or manipulate their data and
          submit for scrutiny and possible admission into the National Teachers
          Institutes.
        </p>

        <p>
          After the completion of the Application process, the Applicant can
          track his/her application status, if it’s “granted”, “on probation” or
          “rejected”, Other features include access to Documents (i.e.,
          Application forms, Admission letters and Payment Receipts). Video link{" "}
          <a
            href="https://res.cloudinary.com/omniswift/video/upload/v1677020875/Illustration%20Videos/o8j2dy2vyhdri5jihexn.mp4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#46c35f" }}
          >
            Application Process
          </a>
        </p>

        <p>
          This document contains step-by-step processes of the system, the
          operations are listed below:
        </p>

        <ul>
          <li>How to get started </li>

          <li>How to Sign in </li>

          <li>How to Reset you Password </li>

          <li>How to the begin application</li>

          <li>How to begin Programme & Course Registration </li>

          <li>How to pay fees</li>

          <li>How to continue your application</li>

          <li>How to Log out</li>
        </ul>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO GET STARTED</h3>
          <p>
            To use the system, go to{" "}
            <a
              target={"_blank"}
              style={{ color: "#46c35f" }}
              href={"https://my.nti.edu.ng "}
            >
              my.nti.edu.ng
            </a>
          </p>
          <img src={Img1} alt="" />
          <ul>
            <li>
              Click on the “New Student Apply” to get started with your
              application journey.
            </li>
          </ul>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO SIGN IN</h3>
          <p>
            If you have created an account and want to continue your
            Application, follow the steps below
          </p>

          <ul>
            <li>Click on the “New Student Apply” button on the Home page</li>
            <li>
              Then click on the “Continue Application” button as seen in the
              screen below
            </li>
          </ul>
          <img src={Img2} alt="" />
          <p>The screen below is displayed.</p>
          <ul>
            <li>
              Fill the input boxes with your login credentials (please provide a
              valid email and password)
            </li>
          </ul>

          <ul>
            <img src={Img_2_1} alt="" />
            <li>Click “Login” to log into the application</li>
          </ul>
          <p>
            These login credentials will be used subsequently for the
            Applicant’s portal.
          </p>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">
            HOW TO USE THE FORGOT PASSWORD FEATURE
          </h3>
          <p>In a situation where an Applicant forgets his/her password</p>

          <ul>
            <li>Click on “Forgot Password” link on the login page</li>
          </ul>
          <img src={Img3} alt="" />
          <p>The following screen is displayed.</p>
        </div>

        <div className="how-to_wrapper">
          <img src={Img4} alt="" />
          <ul>
            <li>
              Enter your applicant email (the email associated with your
              account)
            </li>

            <li>
              Enter your new password and re-enter your password in the second
              confirmation password field.
            </li>
            <li>
              The following pop-up is displayed after you’ve successfully
              changed your password
            </li>
          </ul>
        </div>

        <div className="how-to_wrapper">
          <img src={Img5} alt="" />
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO START APPLICATION</h3>
          <p>
            An Applicant can start his/her application process by following the
            steps below:
          </p>
          <ul>
            <li>Click on the “New Student Apply” button on the Home page.</li>
            <li>The form in the screen below is displayed.</li>
          </ul>
          <img src={Img6} alt="" />
          <ul>
            <li>
              Fill in the necessary details in the form displayed above (Kindly
              provide a valid email and phone number) and click on the “Apply”
              button.{" "}
            </li>
            <li>
              After clicking on the “Apply” button, the pop-up form below is
              displayed.
            </li>
          </ul>

          <img src={Img7} alt="" />
          <ul>
            <li>
              Click “Ok, Submit form” if the provided information is accurate.
            </li>
          </ul>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">
            HOW TO BEGIN PROGRAMME & COURSE SELECTION
          </h3>
          <p>
            An Applicant must choose the appropriate programme and course of
            choice.
          </p>
          <ul>
            <li>
              Select your preferred options from the screen displayed below and
              click “Continue“.
            </li>
          </ul>

          <img src={Img8} alt="" />
          <ul>
            <li>After which the Applicant will click on Continue to proceed</li>
          </ul>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">
            HOW TO PAY FEES(APPLICATION FEES)
          </h3>
          <p
            style={{
              textAlign: "center",
            }}
          >
            The Applicant’s details are automatically generated, and the
            Applicant can choose whether to pay online via Remita (Remita is a
            payment solution that helps individuals and businesses make and
            receive payments) or save the Invoice to pay in the bank
          </p>
          <img src={Img9} alt="" />
          <h4> Pay via Remita</h4>
          <p>
            Click on the “Pay Via Remita” button, you will be redirected to the
            Remita portal. Make payment and return to the NTI Applicants portal
            to continue your application process.
          </p>
          <h4>Pay via Bank</h4>
          <ul>
            <li>
              To make Bank Payment save your Invoice by clicking on the “Save
              Invoice” button.
            </li>
            <li>
              A pdf viewer will be displayed on another window, download, and
              take to the bank for payment.
            </li>
          </ul>

          <p>
            After payment is done, Applicants are to verify RRR (RRR stands for
            Remita Retrieval Reference number which can be used to verify if
            your payment status is “paid” or “unpaid”) to continue their
            application.
          </p>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">
            HOW TO CONTINUE YOUR APPLICATION{" "}
          </h3>
          <p>
            After making payment, an Applicant can continue his/her Application
            after login (see “How to Sign in” in the previous sections of the
            guide).
          </p>

          <h4>Personal Information Page</h4>
          <ul>
            <li>
              Upload your profile picture by clicking on the “Upload Photo” on
              the left side of your screen. (you can take a picture with your
              web or mobile camera if you don’t have a passport photograph).
            </li>
            <li>
              Fill in your personal information and click on the “Continue”
              button.{" "}
            </li>
          </ul>
          <img src={Img10} alt="" />

          <h4>Educational Information</h4>

          <ul>
            <li>
              Fill in your educational information(as seen in the screenshot
              below), and kindly upload your certificate file and enter your O
              Level Academic Information.
            </li>
            <li>Click on the “Continue” button. </li>
          </ul>

          <img src={Img11} alt="" />
          <h4>Tertiary Information</h4>

          <ul>
            <li>
              Fill in your tertiary information (seen in the screenshot below),
              and kindly upload your certificate file and enter your Academic
              Information.
            </li>
            <li> Click on the “Continue” button. </li>
          </ul>
          <img src={Img12} alt="" />

          <h4> Workplace Information</h4>
          <ul>
            <li>
              Fill in your Workplace information, if available, if not you can
              “Skip” this step.
            </li>
            <li> Click on the “Continue” button.</li>
          </ul>

          <img src={Img13} alt="" />
          <h4>Upload Other Documents</h4>
          <p>
            This section allows the applicant to upload some other documents
            like your Birth certificate (required certification for submission),
            NYSC discharge certificate, marriage certificate, etc.
          </p>

          <img src={Img14} alt="" />

          <h4>Review Application</h4>
          <p>
            After all necessary information has been entered, the applicant can
            choose to submit the application or edit the application if there is
            a need to change or correct missing information.
          </p>
          <img src={Img15} alt="" />
          <ol>
            <li>
              Edit Application: make changes to your application before
              submission.
            </li>
            <li>
              Submit Application: Submission of application after all necessary
              details have been provided.
            </li>
          </ol>

          <p>
            After clicking on the submit button, the following pop-up is
            displayed with your application number
          </p>
          <img src={Img22} alt="" />
          <ol>
            <li>
              “Print Application” form: click on the “Print Application” form to
              print a copy or “Download” a copy of your application form.
            </li>
            <img src={Img23} alt="" />
            <li>
              Click on the “Proceed to Dashboard” to access your Applicant
              Dashboard
            </li>
          </ol>
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">
            HOW TO ACCESS THE APPLICANTS’ DASHBOARD
          </h3>
          <p>
            A Student can access his/her Result by navigating to the side menu
          </p>

          <ul>
            <li>Click on the “New Student Apply” button.</li>
            <li>
              Then click on the “Continue Application” button as seen on the
              screen below
            </li>
          </ul>
          <img src={Img16} alt="" />
          <p>The screen below is displayed.</p>
          <img src={Img17} alt="" />
          <ol>
            <li>
              Fill the input boxes with your login credentials (please provide a
              valid email and password)
            </li>
            <li>Click “Login” to log into the application.</li>

            <li>The following screen will be displayed</li>
          </ol>

          <img src={Img18} alt="" />

          <ul>
            <li>
              The Applicant dashboard consists of dash-lets that display your
              “Application form” status, and “Admission Status” (either Pending,
              Admitted or Denied).
            </li>
            <li>
              {" "}
              When your application is verified and admission is granted, the
              following screen is displayed with your application status
              displayed as “granted.
            </li>
          </ul>

          <img src={Img19} alt="" />

          <ul>
            <li>
              {" "}
              Click on the “Print Letter” to print/download your Admission
              letter.
            </li>
            <li>
              {" "}
              Click on the “Pay Tuition” button to pay your tuition fees,{" "}
            </li>
          </ul>

          <h4> Payment History</h4>

          <p>
            The Payment History page shows your payment history (pending and
            confirmed payment details)
          </p>

          <ul>
            <li>Click on the “View Receipt” button to view your Receipt </li>
          </ul>

          <img src={Img20} alt="" />

          <h4>Documents</h4>

          <p>
            You can access your documents in the “Documents” page as seen below.
          </p>

          <img src={Img21} alt="" />
        </div>

        <div className="how-to_wrapper">
          <h3 className="how-to-register-h3">HOW TO LOG OUT</h3>
          <p>
            An Applicant can log out by clicking on the “log out” button at the
            top right corner of the screen, as displayed below.
          </p>
          <img src={Img25} alt="" />
        </div>
      </div>
    </PageContainer>
  );
}

export default HowToApply;
