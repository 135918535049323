import React from "react";

const Footer = () => {
  return (
    // replaced the anchor link with a button Peter Ihimire
    <div>
      <p>
        Copyright © 2018 National Teachers Institute. All rights reserved.
        Powered by
        <span className='external-footer-link'>
          {/* <a>Omniswift | Terms of Use | Privacy Policy</a> */}
          <button>Omniswift | Terms of Use | Privacy Policy</button>
        </span>
      </p>
    </div>
  );
};

export default Footer;
