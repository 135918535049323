import React from "react";

const CourseCard = ({ img, date, header, text }) => {
  return (
    <div>
      <div className='course-card-div'>
        <img alt='' src={img} />
        <div className='course-card'>
          <p>{date}</p>
          <h3>{header}</h3>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export { CourseCard };
