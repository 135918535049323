import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { DARK_COLOR } from "../styles/colors";
import { ArrowRightAlt } from "@material-ui/icons";

const Dashlet = ({
  dashlet,
  switchLabel,
  dataTextSize,
  buttonVariant,
  buttonIconEnd,
  variant = "primary",
}) => {
  return (
    <>
      {variant === "secondary" ? (
        <>
          <Grid
            container
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt=""
              src={dashlet.icon}
              style={{
                width: 50,
                height: 50,
                objectFit: "contain",
                ...dashlet.iconStyle,
              }}
            />
            <Typography
              style={{
                fontWeight: "bold",
                color: DARK_COLOR,
                fontSize: dataTextSize || 14,
                margin: "1rem auto 0",
              }}
            >
              {dashlet.data}
            </Typography>
            <Typography
              style={{
                color: "#87939F",
                fontSize: 16,
                textAlign: "center",
              }}
            >
              {dashlet.label}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid container style={{ flexWrap: "nowrap" }}>
            {typeof dashlet.icon === "string" ? (
              <img
                alt=""
                src={dashlet.icon}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "contain",
                  ...dashlet.iconStyle,
                }}
              />
            ) : (
              dashlet.icon
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: 10,
                flexDirection: switchLabel ? "column-reverse" : "column",
              }}
            >
              <Typography style={{ color: "#87939F", fontSize: 14 }}>
                {dashlet.label}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: DARK_COLOR,
                  fontSize: dataTextSize || 14,
                }}
              >
                {dashlet.data}
              </Typography>
            </div>
          </Grid>
          {dashlet.buttonText && (
            <Button
              variant={buttonVariant ? buttonVariant : "contained"}
              color="primary"
              style={{
                marginTop: 20,
                height: 30,
                minWidth: !buttonVariant ? "50%" : "auto",
                textTransform: "capitalize",
                fontSize: "12px",
                justifyContent: buttonIconEnd ? "flex-start" : "",
              }}
              onClick={dashlet.buttonAction}
              endIcon={buttonIconEnd && <ArrowRightAlt />}
            >
              {dashlet.buttonText}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default Dashlet;
