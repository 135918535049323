import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import { Helmet } from "react-helmet";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import HomeCarousel from "../components/Website/HomeFeatures/HomeCarousel";
import CourseSection from "../components/Website/HomeFeatures/CourseSection";
import News from "../components/Website/HomeFeatures/News";
import ImportantDates from "../components/Website/HomeFeatures/ImportantDates";
import HowToLms from "../components/Website/HomeFeatures/HowToLms";
// import LmsPopup from "../components/LmsPopupPortal";
import AdvertPopup from "../components/AdvertPopup";
import GirlAnime from "../assets/images/anime-girl.png";
import serviceApi from "../redux/api/services";

const Home = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingSemster, setLoadingSemster] = useState(false);
  const [portalStatus, setPortalStatus] = useState("close");
  const [semester, setSemester] = useState({});

  // FOR ANIMATION VISIBILITY VIA TIMEOUT
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 10000);
  }, []);

  // REMOVE ANIMATION VISIBILITY
  const removeHandler = () => {
    setVisible(false);
  };

  const getPortalStatus = async () => {
    setLoadingStatus(true);
    try {
      const res = await serviceApi?.getPortalStatus();

      setPortalStatus(res?.data?.message);
    } catch (error) {
      console.log(error);
      setLoadingStatus(false);
    } finally {
      setLoadingStatus(false);
    }
  };
  const getSemester = async () => {
    setLoadingSemster(true);
    try {
      const res = await serviceApi?.getSemester();

      setSemester(res?.data?.data);
    } catch (error) {
      console.log(error);
      setLoadingSemster(false);
    } finally {
      setLoadingSemster(false);
    }
  };

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);

  useEffect(() => {
    getPortalStatus();
    getSemester();
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>Home | NTI</title>
      </Helmet>
      <div>
        <div className="">
          <HomeCarousel status={portalStatus} semester={semester} />
        </div>

        {/* <LmsPopup /> */}
        <AdvertPopup
          forAnimationClass="for-animation-portal"
          showClass="showw-portal"
          hideClass="hide-portal"
          AnimePix={GirlAnime}
          animeClass="boyanime"
          quoteClass="quotelms-portal"
        >
          <div className="cloud-txt-portal">
            <h4>Do you know we have LMS?</h4>
            <p>
              Please
              <span>
                <a
                  href="https://www.lms.nti.edu.ng/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Login
                </a>
              </span>
              to continue with your learning path.
            </p>
          </div>
        </AdvertPopup>
        <div className="course-news-section">
          <main className="container content">
            <h3 style={{ fontSize: "30px", fontWeight: "800px" }}>
              Courses News
            </h3>
            <CourseSection />
            <HowToLms />
          </main>
        </div>

        <News />
        <ImportantDates />
      </div>
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};

export default Home;
