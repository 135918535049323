import { PRIMARY_COLOR, DARK_COLOR, WHITE_COLOR, GREY_COLOR } from "./colors";

const styles = {
  heroHeader: {
    fontWeight: "900",
    fontSize: "42px",
    margin: "0 0 20px",
    color: "#FFF",
  },
  pageTitle: {
    fontWeight: "800",
    fontSize: "36px",
    color: DARK_COLOR,
    margin: "20px auto",
  },
  text: {
    fontWeight: "normal",
    fontSize: "16px",
    margin: 0,
    lineHeight: "25px",
    color: DARK_COLOR,
  },
  heroContainer: {
    position: "absolute",
  },
  button: {
    // backgroundColor: PRIMARY_COLOR,
    color: "#FFF",
    fontSize: "14px",
    outline: "none",
    border: 0,
    minHeight: "50px",
    padding: "5px 80px",
    width: "fit-content",
    fontWeight: "600",
    margin: "50px 0 20px",
    fontFamily: "'Mulish', sans-serif",
    cursor: "pointer",
  },
  section: {
    backgroundColor: WHITE_COLOR,
    padding: "0rem",
    margin: "1rem 0",
    borderRadius: "5px",
    // boxShadow: '1px 1px 10px #d6d6d6'
  },

  section2: {
    backgroundColor: WHITE_COLOR,
    padding: "2rem",
    margin: "1rem 0",
    borderRadius: "5px",
    // boxShadow: '1px 1px 10px #d6d6d6'
  },

  section3: {
    backgroundColor: WHITE_COLOR,
    padding: "2rem 0rem",
    margin: "1rem 0",
    borderRadius: "5px",
    // boxShadow: '1px 1px 10px #d6d6d6'
  },
  section4: {
    backgroundColor: WHITE_COLOR,
    padding: "2rem 0",
    margin: "1rem 0",
    borderRadius: "5px",
    // boxShadow: '1px 1px 10px #d6d6d6'
  },

  sectionSupport: {
    backgroundColor: WHITE_COLOR,
    padding: "1rem 2rem 2rem 2rem",
    margin: "1rem 0",
    borderRadius: "5px",
    // boxShadow: '1px 1px 10px #d6d6d6'
  },

  sectionEvent: {
    // backgroundColor: WHITE_COLOR,
    // padding: "1rem",
    // margin: "1rem 0",
    borderRadius: "1px",
    height: "270px",
    marginBottom: "10px",
    // overflowY: "scroll",
    // height: 500px;
    overflow: "auto",
    position: "relative",
    // backgroundColor: "#e9e9e9",
    // border: "solid .5px #e5e5e5",
    // overflow: "auto",
    // position: "relative",
    // boxShadow: '1px 1px 10px #d6d6d6'
  },
  sectionPayfee: {
    borderRadius: "1px",
    height: "270px",
    marginBottom: "10px",
    overflow: "auto",
    position: "relative",
    border: "solid .5px #e5e5e5",
  },
  sectionPayfee2: {
    borderRadius: "1px",
    height: "170px",
    marginBottom: "10px",
    overflow: "auto",
    position: "relative",
    border: "solid .5px #e5e5e5",
  },
  sectionTitle: {
    fontSize: "22px",
    color: DARK_COLOR,
    fontWeight: "bold",
  },
  sectionText: {
    color: GREY_COLOR,
  },
  eventList: {
    listStyleType: "none",
    paddingLeft: "0",
  },
  eventListText: {
    // padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
    paddingLeft: "10px",
  },
  eventTitleText: {
    color: "#000",
    margin: "0px 0px 0px 0px",
    paddingLeft: "10px",
    fontSize: "18px",
    fontWeight: "300",
  },
};

export default styles;
