import React, { useState, useEffect } from "react";
import styles from "../../../styles/webStyles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AOS from "aos";
import "aos/dist/aos.css";

const StateOffices = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const [state, setState] = React.useState("");

  const handleChange = (event) => {
    setState(event.target.value);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <div data-aos="fade-up" className="states-office">
      <Grid container style={styles.locations}>
        <Grid item xs={12} md={6} style={styles.gridSections}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              States
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state}
              onChange={handleChange}
              label="States"
            >
              <MenuItem value={"abia"}>Abia</MenuItem>
              <MenuItem value={"adamawa"}>Adamawa</MenuItem>
              <MenuItem value={"akwaibom"}>Akwa Ibom</MenuItem>
            </Select>
          </FormControl>

          <h4>Address:</h4>
          <p>
            World Bank Quarters, Ikot Ekpene Road by Opet Filling Station,
            Umuahia
          </p>
          <h4>Co-ordinator:</h4>
          <p>Mrs Augustina O. Ekuenugo</p>
          <h4>Email:</h4>
          <h4>Phone number:</h4>
          <p>07033256849</p>
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridSections}>
          <iframe
            src="https://maps.google.com/maps?q=KM%205%20Kaduna%20%E2%80%93%20Zaria%20Express%20Way,%20Rigachikun,%20Kaduna,%20Nigeria.&t=&z=13&ie=UTF8&iwloc=&output=embed"
            style={{
              marginLeft: isPhone ? "0rem" : "7rem",
              border: 0,
              marginBottom: "3rem",
              width: isPhone ? "100%" : "80%",
              height: 350,
            }}
            allowfullscreen=""
          ></iframe>
        </Grid>
      </Grid>

    
    </div>
  );
};

export default StateOffices;
