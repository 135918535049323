import React, { useState } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";
// import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import { Link, useLocation, useHistory, NavLink } from "react-router-dom";
import { Select, Box, MenuItem, Menu } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";

const Tab = () => {
  const history = useHistory();
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const newsTab = [
    { title: "News", link: "/news-media-event/news" },
    {
      title: "Event And Media",
      link: "/news-media-event/media",
    },
  ];

  // const [open, setOpen] = useState("StateOffices");
  const [val, setVal] = React.useState("News");

  const handleChange = (event) => {
    setVal(event.target.value);
  };
  console.log(val);
  return (
    <div>
      {isPhone ? (
        <div className="container content">
          <Box
            // sx={{ Width: 100 }}
            className="for-mobile-tab"
          >
            <Select
              className="mobile-select"
              // value={val}
              // onChange={handleChange}
              displayEmpty
              disableUnderline
              inputProps={{
                "aria-label": "Without label",
              }}
              style={{
                padding: "0.5rem",
                // margin: "20px 0px",
                width: "100%",
                textAlign: "center",
                color: "#fff",
                background: "#46c35f",
                borderRadius: "4px",
              }}
            >
              {newsTab.map(({ link, title }, key) => (
                <MenuItem
                  onClick={() => history.push(link)}
                  // value={title}
                  key={key}
                >
                  {title}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </div>
      ) : (
        <Grid>
          <List
            style={{
              display: "flex",
              backgroundColor: "#F6F6F6",
              alignItems: "center",
              height: "48px",
              width: "1171px",
              margin: "0 auto",
            }}
          >
            {newsTab.map(({ link, title }, key) => (
              <NavLink
                to={link}
                key={key}
                className="newsTabLink"
                activeClassName="activeNewsTabList"
              >
                <ListItem
                  className="newsTabList"
                  style={{ textAlign: "center" }}
                >
                  <h4>{title}</h4>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Grid>
      )}
    </div>
  );
};

export default Tab;
