import {
    AppBar,
    Box,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemText,
    Toolbar,
  } from "@material-ui/core";
  import { Menu } from "@material-ui/icons";
  import React, { useState } from "react";
  import { DARK_COLOR, PRIMARY_COLOR } from "../../styles/colors";
  import { Link } from "react-router-dom";
  import { useMediaQuery } from "react-responsive";
  import Logo from "../../assets/images/logo.svg";
  import Twitter from "../../assets/images/twitter.svg";
  import Facebook from "../../assets/images/facebook.svg";
  import YouTube from "../../assets/images/youtube.svg";
  import LinkedIn from "../../assets/images/linkedIn.svg";
  import { CloseOutlined } from "@material-ui/icons";
  import styles from "../../styles/styles";
  
  const Navbar = () => {
    const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
    const [open, setOpen] = useState(false);
    // const [display, setDisplay] = useState(false);
    const links = [
      { title: "Home", link: "/" },
      { title: "Transcript & Alumni", link: "/" },
      { title: "Help & Support", link: "/" },
      { title: "How to Apply", link: "/" },
    ];
  
    const toggleDrawer = () => {
      setOpen(!open);
    };
   
    // const search = () => {
    //     setDisplay(!display);
    // }
  
    const socials = [
      { link: "/", icon: Twitter },
      { link: "/", icon: Facebook },
      { link: "/", icon: YouTube },
      { link: "/", icon: LinkedIn },
    ];
  
    return (
      <AppBar position="sticky" style={{ backgroundColor: "#FFF" }}>
        <Toolbar className="menu-content">
          <Grid item xs={1} style={{ paddingLeft: "0px" }}>
            <Box>
              <Link to="/">
                <img src={Logo} height={45} width={45} alt="Logo" />
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {isPhone ? (
              <Menu
                onClick={() => toggleDrawer()}
                style={{ color: PRIMARY_COLOR, fontSize: 50 }}
              />
            ) : (
              <>
                {links.map(({ link, title }, key) => {
                  return (
                    <Link className="nav-link" to={link} key={key}>
                      {title}
                    </Link>
                  );
                })}
              </>
            )}
          </Grid>
          <Grid item xs={1}>
          {/* the search icon should be here */}
          </Grid>
          <Drawer
            className="drawer"
            anchor="right"
            open={open}
            onClose={() => toggleDrawer()}
          >
            <div role="presentation">
              <div
                style={{
                  minWidth: "100%",
                  height: "100vh",
                  padding: "10px 15px",
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <img width={45} alt="logo" src={Logo} />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CloseOutlined
                      onClick={() => toggleDrawer()}
                      style={{ fontSize: 45 }}
                    />
                  </Grid>
                </Grid>
  
                <List
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "40%",
                  }}
                >
                  {links.map(({ title, link }, key) => (
                    <Link
                      onClick={() => toggleDrawer()}
                      to={link}
                      key={key}
                      style={{ color: DARK_COLOR }}
                    >
                      <ListItem>
                        <ListItemText primary={title} />
                      </ListItem>
                    </Link>
                  ))}
                </List>
  
                <div
                  style={{
                    bottom: 30,
                    left: 0,
                    width: "100%",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      ...styles.text,
                      textAlign: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    Follow us on:
                  </p>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {socials.map(({ link, icon }, key) => {
                      return (
                        <Link
                          onClick={() => toggleDrawer()}
                          className="nav-link"
                          to={link}
                          key={key}
                        >
                          <img src={icon} alt="img" />
                        </Link>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    );
  };
  
  export default Navbar;
  