import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import PageContainer from "../components/PageContainer";
import { Helmet } from "react-helmet";
import PageTitle from "../components/Website/PageTitle";
import axios from "axios";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import styles from "../styles/webStyles";
import { Grid } from "@material-ui/core";
import Header from "../components/Website/EventsPages/Header";

const NewsAndEvents = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    async function getEvents() {
      const URL = "http://139.59.188.88/wp-json/wp/v2/posts?per_page=100";
      try {
        const res = await axios.get(URL);
        console.log(res.data);
        setEvents(res?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getEvents();
  }, []);

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);

  return (
    <PageContainer>
      <Helmet>
        <title>News And Events | NTI</title>
      </Helmet>
      <PageTitle title="News And Events" />

      <Header />
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};

export default NewsAndEvents;
