import React from "react";
import Nav from "./Applicant/Nav";
import Footer from "./Footer";
import Header from "./Header";
import ApplicantFooter from "./Applicant/ApplicantFooter";

const PageContainer = ({ children }) => {
  const applicantPage = window.location.pathname.includes("applicant");
  const studentPage = window.location.pathname.includes("student");
  return (
    <div
      id={applicantPage ? "applicant-body" : studentPage ? "student-body" : "website"}
      style={{ paddingBottom: applicantPage ? "30px" : "" }}
    >
      {applicantPage ? <Nav /> : <Header />}
      {children}
      {applicantPage ? <ApplicantFooter /> : <Footer />}
    </div>
  );
};

export default PageContainer;
