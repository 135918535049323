import React, { useEffect } from "react";
import Card from "../Card";
import { Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { ReplaceSepcialChars } from "../../../util/escapeSpecialChar";

const AdeRequirements = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid>
        <p>{content?.requirements_body}</p>
        <Card
          title={content?.first_requirement}
          sideTitle={content?.first_requirement_minimum}
          years={"years:"}
          minimum={"minimum"}
        />
        <p>{content?.other_requirement}</p>
      </Grid>
    </div>
  );
};

const AdeeRequirements = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid>
        <p>{content?.requirements_body}</p>
        <Card
          title={content?.first_requirement}
          // minimum={content?.first_requirement_minimum}
          sideTitle={content?.first_requirement_minimum}
          years={"years:"}
        />
        <Card title={content?.second_requirement} />
      </Grid>
    </div>
  );
};

const BedRequirements = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  const dot = (
    <>
      <div
        style={{
          display: "inline-block",
          color: "#00C853",
          marginRight: "6px",
          transform: "scale(0.8)",
        }}
      >
        •
      </div>
    </>
  );
  return (
    <div data-aos="fade-up">
      <Grid>
        <p>{content?.requirements_body}</p>
        <Card
          title={content?.first_requirement}
          minimum={"minimum"}
          sideTitle={content?.first_requirement_minimum}
          years={"Years"}
        />

        <Card
          title={content?.second_requirement}
          minimum={"minimum"}
          sideTitle={content?.second_requirement_minimum}
          years={"Years"}
        />

        <Card
          title={content?.other_requirement}
          minimum={"minimum"}
          sideTitle={content?.other_requirement_minimum}
          years={"Years"}
        />
        <div
          style={{ padding: "1rem 0" }}
          dangerouslySetInnerHTML={{
            __html: content?.additional_entry_requirements || "",
          }}
        />
      </Grid>
    </div>
  );
};

const NceRequirements = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid>
        <p>{content?.requirements_body}</p>
        <Card
          minimum={"minimum"}
          sideTitle={content?.first_minimum}
          years={"Years"}
          title={
            <>
              <p>{ReplaceSepcialChars(content?.first_requirement)}</p>
              <p>{content?.first_requirement?.slice(0, ",")}</p>
            </>
          }
        />
        <Card
          minimum={"minimum"}
          sideTitle={content?.second_minimum}
          years={"Years"}
          title={
            <>
              <p>{content?.second_requirement}</p>
              <p>{content?.second_requirement?.slice(0, ",")}</p>
            </>
          }
        />
        <Card
          minimum={"minimum"}
          sideTitle={content?.third_minimum}
          years={"Years"}
          title={
            <>
              <p>{content?.third_requirement}</p>
              <p>{content?.third_requirement?.slice(0, ",")}</p>
            </>
          }
        />
        <Card
          minimum={"minimum"}
          sideTitle={content?.fourth_minimum}
          years={"Years"}
          title={
            <>
              <p>{content?.fourth_requirement}</p>
              <p>{content?.fourth_requirement?.slice(0, ",")}</p>
            </>
          }
        />
        <Card
          title={"Higher Islamic Studies Certificate (HISC)"}
          minimum={"minimum"}
          sideTitle={content?.fifth_minimum}
          years={"Years"}
        />
      </Grid>
    </div>
  );
};

const PgdeRequirements = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid>
        <p>
          Candidates who possess any of the under listed qualifications are
          eligible for admission:
        </p>

        <Card
          title={content?.first_requirement}
          minimum={"minimum"}
          sideTitle={content?.first_requirement_minimum}
          years={"Years"}
        />

        <Card
          title={content?.second_requirement}
          minimum={"minimum"}
          sideTitle={content?.second_requirement_minimum}
          years={"Years"}
        />

        <Card title={content?.other_requirement} />
      </Grid>
    </div>
  );
};

const PttpRequirements = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid>
        <p>
          Eligible candidates should possess any of the following qualification
          (s);
        </p>
        <Card
          minimum={"minimum"}
          sideTitle={content?.first_minimum}
          years={"Years"}
          title={ReplaceSepcialChars(content?.first_requirement)}
        />
        <Card
          minimum={"minimum"}
          sideTitle={content?.second_minimum}
          years={"Years"}
          title={content?.second_requirement}
        />
        <Card
          minimum={"minimum"}
          sideTitle={content?.third_minimum}
          years={"Years"}
          title={content?.third_requirement}
        />
        <Card
          minimum={"minimum"}
          sideTitle={content?.fourth_minimum}
          years={"Years"}
          title={content?.fourth_requirement}
        />
        <Card
          minimum={"minimum"}
          sideTitle={content?.fifth_minimum}
          years={"Years"}
          title={content?.fifth_requirement}
        />
      </Grid>
    </div>
  );
};

export {
  AdeRequirements,
  AdeeRequirements,
  BedRequirements,
  NceRequirements,
  PgdeRequirements,
  PttpRequirements,
};
