import {
  SET_APPLICANT_LIST,
  SET_DASHBOARD_ANALYTICS,
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  SET_AUDIT_TRAILS_TODAY,
  SET_ADMIN_DATA_UPDATE,
} from "./constants";
import adminApi from "../api/admin";

export const adminLogin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await adminApi.adminLogin(payload);
      console.log(response);
      const { data } = response.data;
      localStorage.removeItem("nti_applicant");
      localStorage.removeItem("nti_student");
      localStorage.setItem("nti_admin", JSON.stringify(data));
      await dispatch({
        type: ADMIN_LOGIN,
        payload: data,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getAnalytics = () => {
  return async (dispatch) => {
    try {
      await adminApi.getDashboardAnalytics().then(async (res) => {
        const { message, ...response } = res.data;
        await dispatch({
          type: SET_DASHBOARD_ANALYTICS,
          payload: response,
        });
        return Promise.resolve(response);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
export const editAdminPhoto = (formData) => {
  return async (dispatch) => {
    try {
      await adminApi.editAdminPhoto(formData).then(async (res) => {
        console.log(res);
        const staff = res?.data?.data.staff;
        const adminData = JSON.parse(localStorage.getItem("nti_admin"));

        console.log(adminData);

        const newAdminData = { ...adminData, staff };

        console.log(newAdminData);

        localStorage.setItem("nti_admin", JSON.stringify(newAdminData));
        await dispatch({
          type: SET_ADMIN_DATA_UPDATE,
          payload: newAdminData,
        });
        return Promise.resolve(newAdminData);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getAllApplicants = () => {
  return async (dispatch) => {
    try {
      await adminApi.filterApplicants().then(async ({ data }) => {
        await dispatch({
          type: SET_APPLICANT_LIST,
          payload: data.data.data,
        });
        return Promise.resolve(data.data.data);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
export const getAudtiTrailsToday = () => {
  return async (dispatch) => {
    try {
      await adminApi.getAudtiTrailsToday().then(async ({ data }) => {
        console.log(data);
        await dispatch({
          type: SET_AUDIT_TRAILS_TODAY,
          payload: data.data,
        });

        return Promise.resolve(data.data);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const logoutAdmin = () => {
  return async (dispatch) => {
    try {
      const res = await adminApi.logoutAdmin();
      return Promise.resolve(res.message);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      localStorage.removeItem("nti_admin");
      localStorage.removeItem("nti_applicant");
      localStorage.removeItem("nti_student");
      localStorage.removeItem("userStates");
      localStorage.removeItem("appAnalytics");
      localStorage.removeItem("activeSemester");
      localStorage.removeItem("semester");
      localStorage.removeItem("programmes");
      localStorage.removeItem("generalSearchResult");
      await dispatch({
        type: ADMIN_LOGOUT,
      });
    }
  };
};
