import React from "react";

const ProofOfPayment = () => {
  return (
    <div
      style={{
        display: "flex",
        marginLeft: "20rem",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <main className="external-services-card">
        <h1>fgltiurguhgrhe;aeruguguguiyu</h1>
        <p>OR</p>
        <p>
          {/* replaced anchor with button */}
          <span className="external-footer-link">
            {/* <a>Browse File</a> */}
            <button>Browse FIle</button>
          </span>{" "}
          from your folder
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "8rem",
            alignItems: "center",
            marginTop: "5rem",
          }}
        >
          <button
            style={{
              marginRight: "1rem",
              backgroundColor: "#AEB7B1",
              padding: "0.5rem 3rem",
            }}
          >
            Upload
          </button>
          <button
            style={{
              marginRight: "1rem",
              backgroundColor: "#AEB7B1",
              padding: "0.5rem 3rem",
            }}
          >
            Cancel
          </button>
        </div>
      </main>
    </div>
  );
};

export default ProofOfPayment;
