import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import EventsAndCalender from "./EventsAndCalender";
// import StudyCenters from "./EventsAndCalender";
import NewsAndMedia from "./NewsAndMedia";
import { Select, Box, MenuItem } from "@material-ui/core";
// import StateOffices from "./NewsAndMedia";

const Header = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [open, setOpen] = useState("Events");
  const [val, setVal] = React.useState("Events");

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  // console logged by Peter Ihimire
  console.log(val, handleChange);
  return (
    <div style={{ background: "#fff", paddingBottom: "50px" }}>
      {isPhone ? (
        <div className="container content">
          <Box sx={{ Width: 100 }}>
            <Select
              className="mobile-select"
              value={val}
              onChange={handleChange}
              displayEmpty
              disableUnderline
              inputProps={{
                "aria-label": "Without label",
              }}
              style={{
                padding: "0.5rem",
                width: "100%",
                textAlign: "center",
                color: "#fff",
                background: "#46c35f",
                borderRadius: "4px",
              }}
            >
              <MenuItem onClick={() => setOpen("Events")} value="Events">
                News
              </MenuItem>
              <MenuItem onClick={() => setOpen("Media")} value="Media">
                Events And Media
              </MenuItem>
            </Select>
          </Box>
        </div>
      ) : (
        <div className="container conten">
          <main style={{ marginTop: "-1.5rem" }}>
            <nav>
              <ul className="media-details">
                <li
                  onClick={() => setOpen("Events")}
                  style={{
                    background: open === "Events" ? "#00C853" : "#F6F6F6",
                    color: open === "Events" ? "white" : "black",
                  }}
                >
                  News
                </li>
                <li
                  onClick={() => setOpen("Media")}
                  style={{
                    background: open === "Media" ? "#00C853" : "#F6F6F6",
                    color: open === "Media" ? "white" : "black",
                  }}
                >
                  Events And Media
                </li>
              </ul>
            </nav>
          </main>
        </div>
      )}

      <div>
        <div className="container">
          {open === "Events" && <EventsAndCalender />}
          {open === "Media" && <NewsAndMedia />}
        </div>
      </div>
    </div>
  );
};

export default Header;
