import React, { useState, useEffect } from "react";
import styles from "../styles/webStyles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import PageTitle from "../components/Website/PageTitle";
import Card from "../components/Website/Card";
import PageContainer from "../components/PageContainer";
import Tab from "../components/Website/Tab";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import MiniCard from "../components/Website/MiniCard";
import AOS from "aos";
import "aos/dist/aos.css";

const Programs = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);

  const cardLook = {
    borderLeft: "3px solid #00C853",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.12)",
    width: "100%",
    padding: "0.5rem 1rem",
    height: "160px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>Programs | NTI</title>
      </Helmet>
      <PageTitle title="Programs" />
      <Tab />
      <div
        data-aos="fade-up"
        className="container"
        style={{ marginBottom: "5rem" }}
      >
        <Grid container>
          <Grid Grid item xs={12} md={10} style={styles.gridSections}>
            <section style={{ margin: isPhone ? "0 2rem 5rem" : "0" }}>
              <h1 style={{ fontWeight: "800", fontSize: "30px" }}>
                {/* General Admission Requirments */}
                NTI Academic and CPD Programmes
              </h1>
              <p>
                Since inception, the Institute has mounted various programmes
                aimed at accelerating teacher development at all levels
                particularly at the basic education sector. This is with a view
                to raising the quality of education in the country in particular
                and the quality of overall national development in general.
              </p>
              <p>
                In 2015, new upgrading (academic) programmes were introduced.
                They include: Nigeria Certificate in Education (NCE) in Business
                Education and in Computer Science Education and the Degree
                Programmes (BA. Ed English Language, BA. Ed Social Studies, BA.
                Ed Primary Education, BSc. Ed Mathematics, BSc. Ed Integrated
                Science, BSc. Ed Health Education.)
              </p>

              <p
                style={{
                  paddingBottom: "1rem",
                  width: isPhone ? "100%" : "70%",
                }}
                className="academic-program-span"
              >
                The <span> Academic Programmes</span> of the Institute by ODL
                are as follows:
              </p>
              <Grid container spacing={3}>
                <Grid data-aos="zoom-in" item xs={12} md={4}>
                  <a href="/programmes/advanced-diploma-in-environmental-education-ADEE">
                    <div className="program-card">
                      <h4 className="prg-card-header">
                        Advanced Diploma in Environmental Education (ADEE)
                      </h4>
                      {/* <p style={{ marginTop: "-1rem" }}>
                        The programme is hosted under the Green Teacher Nigeria
                        (GTN) in collaboration with the Common wealth of
                        Learning (COL)
                      </p> */}
                    </div>
                  </a>
                </Grid>

                <Grid data-aos="zoom-in" item xs={12} md={4}>
                  <a href="/programmes/advanced-diploma-in-education-ADE">
                    <div className="program-card">
                      <h4>Advanced Diploma in Education (ADE)</h4>
                      {/* <p style={{ marginTop: "-1rem" }}>
                        The programme is hosted under the Green Teacher Nigeria
                        (GTN) in collaboration with the Common wealth of
                        Learning (COL)
                      </p> */}
                    </div>
                  </a>
                </Grid>

                <Grid data-aos="zoom-in" item xs={12} md={4}>
                  <a href="/programmes/bachelors-degree-in-education-BED">
                    <div className="program-card">
                      <h4>Bachelors Degree in Education (BED)</h4>
                      {/* <p style={{ marginTop: "-1rem" }}>
                        The programme is hosted under the Green Teacher Nigeria
                        (GTN) in collaboration with the Common wealth of
                        Learning (COL)
                      </p> */}
                    </div>
                  </a>
                </Grid>

                <Grid data-aos="zoom-in" item xs={12} md={4}>
                  <a href="/programmes/nigeria-certificate-in-education-NCE">
                    <div className="program-card">
                      <h4>Nigeria Certificate in Education (NCE)</h4>
                      {/* <p style={{ marginTop: "-1rem" }}>
                        The programme is hosted under the Green Teacher Nigeria
                        (GTN) in collaboration with the Common wealth of
                        Learning (COL)
                      </p> */}
                    </div>
                  </a>
                </Grid>

                <Grid data-aos="zoom-in" item xs={12} md={4}>
                  <a href="/programmes/post-graduate-diploma-in-education-PGDE">
                    <div className="program-card">
                      <h4>Postgraduate Diploma in Education (PGDE)</h4>
                      {/* <p style={{ marginTop: "-1rem" }}>
                        The programme is hosted under the Green Teacher Nigeria
                        (GTN) in collaboration with the Common wealth of
                        Learning (COL)
                      </p> */}
                    </div>
                  </a>
                </Grid>

                <Grid data-aos="zoom-in" item xs={12} md={4}>
                  <a href="/programmes/pivotal-teacher-training-programme-PTTP">
                    <div className="program-card">
                      <h4>Pivotal Teacher Training Programme (PTTP)</h4>
                      {/* <p style={{ marginTop: "-1rem" }}>
                        The programme is hosted under the Green Teacher Nigeria
                        (GTN) in collaboration with the Common wealth of
                        Learning (COL)
                      </p> */}
                    </div>
                  </a>
                </Grid>
              </Grid>
            </section>
          </Grid>
        </Grid>
      </div>
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};

export default Programs;
