import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";

const PaymentMethods = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  const listLink = {
    color: "#00C853",
  };
  const dot = (
    <>
      <div
        style={{
          display: "inline-block",
          color: "#00C853",
          marginRight: "6px",
          transform: "scale(0.8)",
        }}
      >
        •
      </div>
    </>
  );

  const listOneLeft = [
    {
      text: (
        <span>
          Go to{" "}
          <a href="/" style={listLink}>
            my.nti.edu.ng
          </a>
        </span>
      ),
    },
    { text: "Apply for Admission" },
    { text: "Fill the Application form" },
    { text: "Generate RRR invoice" },
    { text: "Save the generated RRR invoice or print it" },
  ];

  const listOneRight = [
    {
      text: "Select Pay via Remita/ or Go online through Remita https://remita.net/ (Select Pay An Invoice)",
    },
    { text: "Verify the Payment" },
    { text: "Continue your Application" },
  ];

  const listTwoLeft = [
    {
      text: (
        <span>
          Go to{" "}
          <a href="/" style={listLink}>
            my.nti.edu.ng
          </a>
        </span>
      ),
    },
    { text: "Apply for Admission" },
    { text: "Fill the Application form" },
    { text: "Generate RRR invoice" },
  ];

  const listTwoRight = [
    { text: "Save the generated RRR invoice or print it" },
    { text: "Go to the Bank and make payment with the invoice" },
    { text: "Login on the account to Verify the payment " },
  ];

  return (
    <div data-aos="fade-up">
      <Grid>
        <h3>Online Payment Methods:</h3>
        <Grid container>
          <Grid item md={6}>
            {listOneLeft.map(({ text }) => {
              return (
                <p>
                  {dot}
                  {text}
                </p>
              );
            })}
          </Grid>
          <Grid item md={6}>
            {listOneRight.map(({ text }) => {
              return (
                <p>
                  {dot}
                  {text}
                </p>
              );
            })}
          </Grid>
        </Grid>
        <h3>Bank Payment Methods</h3>
        <Grid container>
          <Grid item md={6}>
            {listTwoLeft.map(({ text }) => {
              return (
                <p>
                  {dot}
                  {text}
                </p>
              );
            })}
          </Grid>
          <Grid item md={6}>
            {listTwoRight.map(({ text }) => {
              return (
                <p>
                  {dot}
                  {text}
                </p>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentMethods;
