import React, { useEffect, useState, useCallback } from "react";
import styles from "../styles/webStyles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
import PageTitle from "../components/Website/PageTitle";
import PageContainer from "../components/PageContainer";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import mainStyles from "../styles/mainStyles";
import serviceApi from "../redux/api/services";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Select,
  Box,
  MenuItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import ViewIcon from "../assets/images/eye-view.svg";
import ViewIcon2 from "../assets/images/view-eye.svg";
import Pagination from "../components/CustomPaginate";
import AOS from "aos";
import "aos/dist/aos.css";

const ExamTimetable = () => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const [showing, setShowing] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [load, setLoad] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [state, setState] = React.useState("");
  const [program, setProgram] = React.useState("");
  const [timetable, setTimetable] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [allSemester, setAllSemester] = React.useState([]);
  const [programmes, setAllProgrammes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [searchForm, setSearchForm] = useState({
    session: "",
    semester: "",
    programme: "",
    level: "",
  });

  const [searchedData, setSearchedData] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  // Get timetable
  useEffect(() => {
    const examTimetableHandler = async () => {
      setLoading(true);
      try {
        const res = await serviceApi.getAllTimetable();
        setTimetable(res.data.data);
        setSearchedData(res.data.data);
        console.log(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    examTimetableHandler();
  }, []);

  // TO get all session
  useEffect(() => {
    const sessionHandler = async () => {
      setShowing(true);
      try {
        const res = await serviceApi.getAllSessions();
        setAllSession(res.data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setShowing(false);
      }
    };
    sessionHandler();
  }, []);

  // TO get all programmes
  useEffect(() => {
    const programmeHandler = async () => {
      setProcessing(true);
      try {
        const res = await serviceApi.getProgrammes();
        setAllProgrammes(res.data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setProcessing(false);
      }
    };
    programmeHandler();
  }, []);

  const handleFormChange = ({ name, value }) => {
    setSearchForm({ ...searchForm, [name]: value });
  };

  const handleSearch = () => {
    const newData = timetable
      .filter((item) => {
        return (
          String(item.programme.id) ===
          (searchForm.programme === ""
            ? String(item.programme.id)
            : searchForm.programme)
        );
      })
      .filter((item) => {
        return (
          String(item.semester.session.id) ===
          (searchForm.session === ""
            ? String(item.semester.session.id)
            : searchForm.session)
        );
      })
      .filter((item) => {
        return (
          String(item.semester.name) ===
          (searchForm.semester === ""
            ? String(item.semester.name)
            : searchForm.semester)
        );
      })
      .filter((item) => {
        return (
          String(item.programme.level.level) ===
          (searchForm.level === ""
            ? String(item.programme.level.level)
            : searchForm.level)
        );
      });

    setSearchedData(newData);
  };

  // Get all semester function
  const getSemesterHandler = useCallback(async () => {
    setLoad(true);
    try {
      const res = await serviceApi.getSemesterBySession(searchForm.session);
      console.log(res.data);
      setAllSemester(res.data.data);
    } finally {
      setLoad(false);
    }
  }, [searchForm.session]);

  // useEffect to get all semester depending on if we have session data
  useEffect(() => {
    if (searchForm.session) {
      getSemesterHandler(searchForm.session);
    } else {
      setAllSemester([]);
    }
  }, [getSemesterHandler, searchForm.session]);
  console.log(allSemester);

  // For the pagination
  const [pageNum, setPageNum] = useState(0);
  const tablePerPage = 5;
  const pagesVisited = pageNum * tablePerPage;

  // Handles page count numbers
  const pageCount = Math.ceil(
    searchedData && searchedData.length / tablePerPage
  );

  //Handle page click
  const changePageHandler = ({ selected }) => {
    return setPageNum(selected);
  };

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);
  return (
    <PageContainer>
      <Helmet>
        <title>Examination Timetable | NTI</title>
      </Helmet>
      <PageTitle title="Examination Timetable" />

      <div className="news-sectio" style={{ background: "#fff" }}>
        <main data-aos="fade-up" className="container content ">
          <h2>Exam Period</h2>

          <div className="timetable-grid">
            <div className="">
              <FormControl variant="outlined" style={mainStyles.input}>
                <Select
                  native
                  // label="Payment description"
                  variant="outlined"
                  notched
                  onChange={(e) => handleFormChange(e.target)}
                  name="session"
                >
                  <option value="">
                    {showing ? "Loading..." : "  Select session"}
                  </option>
                  {allSession.map((session) => {
                    return (
                      <option value={session.id} key={session.id}>
                        {`${session.name} ${
                          session.status === "green" ? "Active" : "In-active"
                        }`}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="">
              <FormControl variant="outlined" style={mainStyles.input}>
                <Select
                  native
                  variant="outlined"
                  notched
                  onChange={(e) => handleFormChange(e.target)}
                  name="semester"
                >
                  <option value="">
                    {load ? "Loading..." : "  Select Semester"}
                  </option>
                  {allSemester.map((semester) => {
                    return (
                      <option value={semester.name}>{semester.value}</option>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="">
              <FormControl variant="outlined" style={mainStyles.input}>
                <Select
                  native
                  variant="outlined"
                  notched
                  onChange={(e) => handleFormChange(e.target)}
                  name="programme"
                >
                  <option value="">
                    {processing ? "Loading..." : "  Select programme"}
                  </option>
                  {programmes.map((program) => {
                    return <option value={program.id}>{program.abbr}</option>;
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="">
              <FormControl variant="outlined" style={mainStyles.input}>
                <Select
                  native
                  variant="outlined"
                  notched
                  onChange={(e) => handleFormChange(e.target)}
                  name="level"
                >
                  <option value="">Select level</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </Select>
              </FormControl>
            </div>

            <div className="exam-search-btn">
              <Button
                onClick={() => handleSearch()}
                style={{ ...mainStyles.btn, ...{ width: "100%" } }}
                variant="contained"
                color="primary"
              >
                Search
              </Button>
            </div>
          </div>

          <div>
            {loading ? (
              <div className="course-material-height event-scroll-grid">
                <table className="fee-table-grid">
                  <thead>
                    <tr className="">
                      <th className="applicant-doc-th" width="25%">
                        Programme
                      </th>
                      <th className="applicant-doc-th" width="0%">
                        Level
                      </th>
                      <th className="applicant-doc-th" width="0%">
                        Session
                      </th>
                      <th className="applicant-doc-th" width="0%">
                        Semester
                      </th>
                      <th className="applicant-doc-th">Action</th>
                    </tr>
                  </thead>
                </table>
                <div className="center-text vertical-center ">
                  <CircularProgress size={30} style={{ color: "#344a3d" }} />
                </div>
              </div>
            ) : (
              <div className=" course-material-height event-scroll-grid">
                {searchedData.length === 0 || "" || undefined ? (
                  <>
                    <table className="fee-table-grid">
                      <thead>
                        <tr className="">
                          <th className="applicant-doc-th" width="25%">
                            Programme
                          </th>
                          <th className="applicant-doc-th" width="0%">
                            Level
                          </th>
                          <th className="applicant-doc-th" width="0%">
                            Session
                          </th>
                          <th className="applicant-doc-th" width="0%">
                            Semester
                          </th>
                          <th className="applicant-doc-th">Action</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="center-text">
                      <h3>No timetable available!</h3>
                    </div>
                  </>
                ) : (
                  <table className="fee-table-grid">
                    <thead>
                      <tr className="">
                        <th className="applicant-doc-th" width="25%">
                          Programme
                        </th>
                        <th className="applicant-doc-th" width="0%">
                          Level
                        </th>
                        <th className="applicant-doc-th" width="0%">
                          Session
                        </th>
                        <th className="applicant-doc-th" width="0%">
                          Semester
                        </th>
                        <th className="applicant-doc-th">Action</th>
                      </tr>
                    </thead>
                    <tbody className="applicant-doc-body">
                      {searchedData
                        .slice(pagesVisited, pagesVisited + tablePerPage)
                        .map((document, index) => {
                          return (
                            <tr key={index}>
                              <td className="td-des course-mat" width="25%">
                                <p>{`${document?.programme?.abbr}`}</p>
                              </td>
                              <td className="td-des course-mat">
                                <p>{` ${document?.programme?.level?.name}`}</p>
                              </td>
                              <td className="td-des course-mat">
                                <p>{` ${document?.semester?.session?.name}`}</p>
                              </td>
                              <td className="td-des course-mat">
                                <p>{`${
                                  document?.semester?.name === 1
                                    ? "First Semester"
                                    : "Second Semester"
                                }`}</p>
                              </td>
                              <td className="td-bt course-mat">
                                {document?.link === null ? (
                                  <button className="download-disabled">
                                    <a
                                      href="/"
                                      className="text-icon2"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span className="remove">View</span>
                                    </a>
                                  </button>
                                ) : (
                                  <button className="download-btn btn-timetable">
                                    <a
                                      href={document?.link}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div className="text-icon2">
                                        <img src={ViewIcon} alt="" />
                                        <span
                                          className="remove"
                                          style={{ color: "#344A3D" }}
                                        >
                                          View
                                        </span>
                                      </div>
                                    </a>
                                  </button>
                                )}
                                {/* */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>

          <div className="pagination-info-container">
            <div>{`Total : ${searchedData.length}`}</div>

            <Pagination
              pageCount={pageCount}
              onPageChange={changePageHandler}
              containerClassName="pagination"
              activeClassName="paginate-active"
              disabledClassName="paginate-disabled"
              previousClassName="paginate-previous"
              nextClassName="paginate-next"
              breakLabel="..."
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
            />
          </div>
        </main>
      </div>
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};
export default ExamTimetable;
