import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../../styles/webStyles";
import { useMediaQuery } from "react-responsive";

const PageTitle = ({ title }) => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  return (
    <div style={styles.pageHeading}>
      <div className="container">
        <h1 style={{ paddingLeft: isPhone ? "2.5rem " : "0" }}>{title}</h1>
      </div>
      <Grid item xs={12} className="pageTitle-container"></Grid>
    </div>
  );
};

export default PageTitle;
