import axios from "axios";
import { store } from "../../App";

// const baseURL = "https://nti-v3-api-suafh.ondigitalocean.app/";

// const baseURL = "https://nti-v-3-api-odzih.ondigitalocean.app/";
const baseURL = `${process.env.REACT_APP_BACKEND_URL}`;

const getUser = () => {
  const applicant = store.getState().applicant;
  const student = store.getState().student;
  const admin = store.getState().admin;
  const token = admin.authenticated
    ? admin.adminData.token
    : applicant.authenticated
    ? applicant.applicantData.token
    : student.authenticated
    ? student.studentData.token
    : "";

  return token || "";
};

const config = {
  headers: {
    Accept: "application/json",
    ContentType: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer ",
  },
};

let $axios = axios.create({
  ...config,
  baseURL,
});

$axios.interceptors.request.use((request) => {
  request.headers["Authorization"] = "Bearer " + getUser();
  return request;
});

$axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.message === "Unauthenticated."
    ) {
      localStorage.removeItem("nti_applicant");
      localStorage.removeItem("nti_student");
      localStorage.removeItem("nti_admin");
      if (error.config?.url.includes("admin")) {
        window.location.href = "/admin/login";
      } else if (error.config?.url.includes("student")) {
        window.location.href = "/student/login";
      } else {
        window.location.href = "/applicant/login";
      }
    }
    return Promise.reject(error.response);
  }
);

export default $axios;

//   $axios.interceptors.response.use(
//     async (response) => response,
//     async (error) => {
//         if (error && error?.response?.status === 401 || error && error?.response?.data?.message === 'Unauthenticated.') {
//             localStorage.removeItem("nti_applicant");
//             localStorage.removeItem("nti_admin");
//             if(error.config?.url.includes('admin')) {
//                 window.location.href = "/admin/login";
//             } else {
//                 window.location.href = "/applicant/login";
//             }
//         }
//         return Promise.reject(error.response);
//     }
// );
