import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import PageTitle from "../components/Website/PageTitle";
import PageContainer from "../components/PageContainer";
import ProspectiveImgOne from "../assets/images/ProspectiveImgOne.jpg";
import ProspectiveImgTwo from "../assets/images/ProspectiveImgTwo.jpg";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import AOS from "aos";
import "aos/dist/aos.css";

const ProspectiveStudents = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>Prospective Students | NTI</title>
      </Helmet>
      <PageTitle title="Prospective Students" />

      <div data-aos="fade-up" className="container content">
        <Grid container style={{ paddingTop: isPhone ? "2rem" : "5rem" }}>
          <Grid xs={12} md={6}>
            <div data-aos="zoom-in" className="prospective-container">
              <img
                alt=""
                className="prospective-img"
                src={ProspectiveImgTwo}
                style={{
                  width: "100%",
                }}
              />
              <div className="prospective-div  prospective-div-on">
                <h1 style={{ lineHeigh: "0.1" }}>Apply for Admission</h1>
                <p>
                  2021/2022 Application is on going, check the portal for more
                  updates on application.
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <div data-aos="zoom-in" className="prospective-container">
              <img
                alt=""
                className="prospective-img"
                src={ProspectiveImgOne}
                style={{
                  width: "100%",
                }}
              />
              <div className="prospective-div  prospective-div-tw">
                <h1 style={{ lineHeigh: "0.1" }}>Register to study at NTI</h1>
                <p>
                  Registration is closed for 2022 undergraduate and diploma
                  course for 2021
                </p>
              </div>
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <button
            onClick={() => history.push("/programs")}
            className="carousel-btn"
            style={{
              margin: "1.5rem 0 5rem 0",
            }}
          >
            Go To Program
          </button>
        </div>
      </div>
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};

export default ProspectiveStudents;
