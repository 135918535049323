import $axios from "./config";
import axios from "axios";
import { sha512 } from "../../util/generalUtils";
import moment from "moment";

const serviceApi = {
  async getSemester() {
    return $axios.get(`/api/services/active/semester`);
  },
  // Disturbing cypress testing [/api/services/session] editted by adding[s/all]
  async getSession() {
    return $axios.get(`/api/services/sessions/all`);
  },
  async getProgrammes() {
    return $axios.get(`/api/services/programmes`);
  },
  async getSpecializations(programme_id = "") {
    return $axios.get(
      "/api/services/specializations" + (programme_id ? `/${programme_id}` : "")
    );
  },
  async getAllSpecializations() {
    return $axios.get("/api/services/specialization/all");
  },
  async getLevels(programme_id) {
    return $axios.get(`/api/services/levels/${programme_id}`);
  },
  async getStudyCenters(state_id, programme_id) {
    return $axios.get(`/api/services/centers/${state_id}/${programme_id}`);
  },
  //   async getGrades(cert_id, result_type) {
  //     return $axios.get(`/api/services/grades/${cert_id}/${result_type}`);
  //   },
  async getGrades(result_type) {
    return $axios.get(`/api/services/grades/o_level/${result_type}`);
  },
  async getCOEGrades() {
    return $axios.get(`/api/services/grades/tertiary/coe`);
  },
  // getSubjects() {
  //     return $axios.get(`/api/services/subjects`);
  // },
  getSubjects() {
    return $axios.get(`/api/services/subject/o_level`);
  },
  getResultType() {
    return $axios.get(`/api/services/certificate/o_level`);
  },
  async getStates() {
    return $axios.get(`/api/services/states`);
  },
  async getAllCenters() {
    return $axios.get(`/api/services/study-centers`);
  },
  async getLGAs(state_id) {
    return $axios.get(`/api/services/lga/${state_id}`);
  },
  getZones() {
    return $axios.get(`/api/services/zones`);
  },
  getFeeTypes() {
    return $axios.get(`/api/services/fee-types`);
  },
  getPortalInfo() {
    return $axios.get(`/api/services/application/portal`);
  },

  getAllTimetable() {
    return $axios.get(`/api/services/timetable/all`);
  },

  getActiveSemester() {
    return $axios.get(`/api/services/active/semester`);
  },
  getAllSessions() {
    return $axios.get(`/api/services/sessions/all`);
  },
  getSemesterBySession(sessionId) {
    return $axios.get(`/api/services/semester/session/${sessionId}`);
  },
  getPortalStatus() {
    return $axios.get(`/api/services/application/portal`);
  },

  // getAllEvents() {
  //   return axios.get(` https://cms.nti.edu.ng/api/news-and-updates`);
  // },
  getEvents() {
    return axios.get(` https://cms.nti.edu.ng/api/news-and-updates`);
  },
  getImportantEvents() {
    return axios.get(`https://cms.nti.edu.ng/api/important-dates`);
  },
  getCourseEvents() {
    return axios.get(`https://cms.nti.edu.ng/api/course-news`);
  },
  getNewsEvents() {
    return axios.get(`https://cms.nti.edu.ng/api/news-and-updates`);
  },
  getMediaEvents() {
    return axios.get(`https://cms.nti.edu.ng/api/news-and-updates`);
  },

  // PROGRAMS
  getAdeContent() {
    return axios.get(`https://cms.nti.edu.ng/api/programme-ade`);
  },
  getAdeeContent() {
    return axios.get(`https://cms.nti.edu.ng/api/programme-adee`);
  },
  getBedContent() {
    return axios.get(`https://cms.nti.edu.ng/api/programme-bed`);
  },
  getNceContent() {
    return axios.get(`https://cms.nti.edu.ng/api/programme-nce`);
  },
  getPgdeContent() {
    return axios.get(`https://cms.nti.edu.ng/api/programme-pgde`);
  },
  getPttpContent() {
    return axios.get(`https://cms.nti.edu.ng/api/programme-pttp`);
  },

  async generateRRR(payload) {
    const merchantId = "1543411755";
    const apiKey = "874963";

    const hashString =
      merchantId +
      payload.serviceTypeId +
      payload.orderId +
      payload.amount +
      apiKey;

    return sha512(hashString).then((hash) => {
      const base = "https://login.remita.net/remita/exapp/api/v1/send/api";

      return axios.post(
        `${base}/echannelsvc/merchant/api/paymentinit`,
        payload,
        {
          headers: {
            Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${hash}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    });
  },
  async getFeeDefinitions(feeId, programmeId, specializationId) {
    // level, semester and category are optional parameters
    return $axios.get(
      `/api/services/fee/${feeId}/${programmeId}/${specializationId}`
    );
  },
  // // Disturbing testing hence commented it out
  // async fetchEvents() {
  //   const startDate = moment().subtract(31, "days").format("YYYY-MM-DD");
  //   return axios.get(
  //     `http://161.35.76.88/wp-json/tribe/events/v1/events?start_date=${startDate}`
  //   );
  // },
};
export default serviceApi;
