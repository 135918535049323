import React, {useState} from "react";
import Navbar from "./Navbar";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/Website/PageTitle";
import InquirerInformation from "../../components/ExternalServices/InquirerInformation"
import RequestInformation from "../../components/ExternalServices/RequestInformation"
import ProofOfPayment from "../../components/ExternalServices/ProofOfPayment"

const ExternalLayout = () => {
  const [active, setActive] = useState("Inquirer Information");

  return (
    <div>
      <Navbar />
      <PageTitle title="External Services" />
      <Grid>
        <ul className="external-services-menu">
          <li
            onClick={() => setActive("Inquirer Information")}
            className="external-listItems"
          >
            Inquirer Information
          </li>
          <li
            onClick={() => setActive("Request Information")}
            className="external-listItems"
          >
            Request Information
          </li>
          <li
            onClick={() => setActive("Proof of Payment")}
            className="external-listItems"
          >
            Proof of Payment
          </li>
        </ul>
      </Grid>
      <main className="container" style={{ marginTop: "5rem" }}>
      <Grid item md={9} className="container">
        {active === "Inquirer Information" && <InquirerInformation />}
        {active === "Request Information" && <RequestInformation />}
        {active === "Proof of Payment" && <ProofOfPayment />}
      </Grid>
      </main>
    </div>
  );
};

export default ExternalLayout;
