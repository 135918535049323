import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { Menu, Search } from "@material-ui/icons";
import React, { useEffect, useState, useRef } from "react";
import { DARK_COLOR, PRIMARY_COLOR } from "../styles/colors";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Logo from "../assets/images/logo.svg";
import Twitter from "../assets/images/twitter.svg";
import Facebook from "../assets/images/facebook.svg";
import YouTube from "../assets/images/youtube.svg";
import LinkedIn from "../assets/images/linkedIn.svg";
import SearchIcon from "../assets/images/search-icon.svg";
import SearchWhite from "../assets/images/white-search.svg";
import DropdownIcon from "../assets/images/dropdown-icon.svg";
import { CloseOutlined } from "@material-ui/icons";
import styles from "../styles/styles";
import ListLinks from "./List";
// import SearchIcon from "@material-ui/icons/Search";
import { ChevronDown } from "lucide-react";

const Header = () => {
  // const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const isPhone = useMediaQuery({ query: "(max-width: 1200px)" });
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [showPlatform, setShowPlatform] = useState(false);
  const [showHowTo, setShowHowTo] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const navigate = useHistory();

  const links = [
    { title: "Home", link: "/" },
    // { title: "Prospective Students", link: "/prospective" },
    { title: "Applicant Login", link: "/applicant/login" },
    { title: "Current Students", link: "/student/login" },
    { title: "Programs", link: "/programs" },
    // { title: "Locations", link: "/locations" },
    // { title: "News and Events", link: "/news-and-events" },
    // { title: "Contact Us", link: "/contact-us" },
  ];

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const search = () => {
    setDisplay(!display);
  };

  const socials = [
    { link: "/", icon: Twitter },
    { link: "/", icon: Facebook },
    { link: "/", icon: YouTube },
    { link: "/", icon: LinkedIn },
  ];

  const ref = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // For the search component
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  // FOr mobile dropdown
  useEffect(
    () => {
      setShowPlatform(false);
      // setShowResources(false);
    },
    [
      // router.pathname
    ]
  );
  return (
    <AppBar position="sticky" style={{ backgroundColor: "#fff" }}>
      <Toolbar
        className="menu-content"
        // style={{ width: "1171px", margin: "auto" }}
      >
        <Grid
          item
          xs={1}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Box>
            <Link to="/">
              <img src={Logo} height={45} width={45} alt="Logo" />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: "0rem",
          }}
        >
          {isPhone ? (
            <Menu
              onClick={() => toggleDrawer()}
              style={{ color: PRIMARY_COLOR, fontSize: 50 }}
            />
          ) : (
            <>
              {/* {links.map(({ link, title }, key) => {
                return (
                  <Link className="nav-link" to={link} key={key}>
                    {title}
                  </Link>
                );
              })} */}

              <Box className="nav-content" id="float">
                <Grid
                  item
                  // alternativey the xs={12}, when you add location, change this
                  // xs={9}
                  xs={12}
                  id="float-div"
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* <nav className="dropdownn">
                    <a
                      className="nav-link"
                      activeClassName="active"
                      href="https://nti.edu.ng/about/"
                      target="_blank"
                    >
                      About
                    </a>
                  </nav> */}

                  <nav className="dropdownn">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/"
                      exact={true}
                    >
                      Home
                    </NavLink>
                  </nav>

                  <nav className="dropdownn">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/applicant/login"
                      exact={true}
                    >
                      Applicant Login
                    </NavLink>
                  </nav>
                  {/* 
                  <nav className="dropdownn">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/prospective"
                      exact={true}
                    >
                      Prospective Students
                    </NavLink>
                  </nav> */}

                  <nav className="dropdownn">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/student/login"
                    >
                      Current Students
                    </NavLink>
                  </nav>

                  <nav className="dropdownn">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/programs"
                    >
                      Programmes
                    </NavLink>
                  </nav>
                  {/* <nav className="dropdownn">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/locations"
                    >
                      Locations
                    </NavLink>
                  </nav> */}

                  <nav className="dropdownn">
                    <div className="drop-wrapper">
                      <NavLink
                        className="nav-link-drop"
                        activeClassName="active"
                        to="/how-to-apply"
                      >
                        How to Apply/Register
                      </NavLink>
                      <ChevronDown
                        color="#37C756"
                        size={20}
                        className="chevronBtn"
                      />
                    </div>
                    <div className="dropdown-content">
                      <NavLink
                        className="nav-link-drop"
                        activeClassName="active"
                        to="/how-to-register"
                      >
                        How to Register
                      </NavLink>
                      {/* <a
                        href="https://cms.nti.edu.ng/docs/NTI%20V3_Portal_Student%20guide_1.0.pdf"
                        activeClassName="active"
                      >
                        How to Register
                      </a> */}
                      <NavLink
                        className="nav-link-drop"
                        activeClassName="active"
                        to="/how-to-apply"
                      >
                        How to Apply
                      </NavLink>
                      {/* <a
                        href="https://cms.nti.edu.ng/docs/NTI_V3_Portal_Applicant_guide_1.0.pdf"
                        activeClassName="active"
                      >
                        How to Apply
                      </a> */}
                    </div>
                  </nav>
                  <nav className="dropdownn">
                    <div className="drop-wrapper">
                      <NavLink
                        className="nav-link-drop"
                        activeClassName="active"
                        to="/news-media-event/news"
                      >
                        News & Events
                      </NavLink>
                      <ChevronDown
                        color="#37C756"
                        size={20}
                        className="chevronBtn"
                      />
                    </div>

                    <div className="dropdown-content">
                      <NavLink to="/exam-timetable" activeClassName="active">
                        Exam Timetable
                      </NavLink>
                    </div>
                  </nav>

                  {/* <nav className="dropdownn">
                    <a
                      className="nav-link"
                      activeClassName="active"
                      // to=""
                      href="https://my.nti.edu.ng/application-guide"
                      target="_blank"
                    >
                      How to Apply
                    </a>
                    <div className="dropdown-content">
                      <a
                        // to=""
                        target="_blank"
                        href="https://my.nti.edu.ng/registration-guide/1.1/en/topic/starting-your-registration-returning-students"
                      >
                        How to Register
                      </a>
                      <Link to="/help-and-support">Help & Support</Link>
                    </div>
                  </nav> */}

                  <nav className="dropdownn">
                    <div className="drop-wrapper">
                      <NavLink
                        className="nav-link-drop"
                        activeClassName="active"
                        to="/contact-us"
                      >
                        Contact Us
                      </NavLink>
                      <ChevronDown
                        color="#37C756"
                        size={20}
                        className="chevronBtn"
                      />
                    </div>
                    <div className="dropdown-content">
                      <NavLink to="/help-and-support" activeClassName="active">
                        Help And Support
                      </NavLink>
                    </div>
                  </nav>
                  {/* <nav className="dropdownn">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="#"
                    > */}
                  <div ref={ref}>
                    <button
                      onClick={() => setIsMenuOpen(true)}
                      style={{
                        border: "none",
                        background: "transparent",
                        padding: "0px 0px 0px 26px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={SearchIcon} alt="" />
                    </button>

                    {isMenuOpen && (
                      <div>
                        <div className="searchArea ">
                          <form>
                            <input
                              onChange={inputHandler}
                              type="text"
                              className="search__field"
                              maxlength="30"
                              placeholder="Search…"
                              title="Search for:"
                            />
                            <button>
                              <img src={SearchWhite} alt="" />
                              {/* <i className="far fa-search"></i> */}
                            </button>
                          </form>
                          <ListLinks input={inputText} />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* </NavLink>
                  </nav> */}
                </Grid>
              </Box>
            </>
          )}
        </Grid>

        {/* <Grid item xs={2} style={{paddingLeft: "3rem"}}>
        <a href="https://play.google.com/store/apps/details?id=com.myNTI.studentMobileApp" target="_blank">
        <button className="play-store-btn">
          <img src={Play} alt="img"/>
          <div>
            <p>GET IT ON</p>
            <p style={{marginTop:"-0.6rem"}}>Google Play</p>
          </div>
        </button>
        </a>      
        </Grid> */}

        <Drawer
          className="drawer"
          anchor="left"
          open={open}
          onClose={() => toggleDrawer()}
        >
          <div role="presentation">
            <div
              style={{
                minWidth: "100%",
                height: "100vh",
                padding: "20px 15px 10px 15px",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Link to="/" onClick={() => toggleDrawer()}>
                    <img width={45} alt="logo" src={Logo} />
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CloseOutlined
                    onClick={() => toggleDrawer()}
                    style={{ fontSize: 45 }}
                  />
                </Grid>
              </Grid>

              <List
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  marginTop: "15%",
                }}
                className="side-drawer-height event-scroll"
              >
                {links.map(({ title, link }, key) => (
                  <NavLink
                    onClick={() => toggleDrawer()}
                    to={link}
                    key={key}
                    style={{ color: DARK_COLOR }}
                    activeClassName="active"
                    exact={true}
                  >
                    <ListItem>
                      <ListItemText primary={title} />
                    </ListItem>
                  </NavLink>
                ))}

                <div style={{ padding: "8px 16px" }} className="hasDropdown">
                  <button
                    className="hasDropdownBtn"
                    onClick={() => {
                      setShowHowTo(!showHowTo);
                    }}
                  >
                    <span>How to Apply/Register</span>
                    <img src={DropdownIcon} alt="" />
                  </button>
                  <div className={`dropdownMenu ${showHowTo ? "show" : ""}`}>
                    <div className="dropdownContainer">
                      <NavLink
                        onClick={() => toggleDrawer()}
                        to="/how-to-register"
                        style={{ color: DARK_COLOR }}
                        activeClassName="active"
                      >
                        <ListItem className="dropdownWrapper">
                          <ListItemText primary="How to Register" />
                        </ListItem>
                      </NavLink>

                      <NavLink
                        onClick={() => toggleDrawer()}
                        to="/how-to-apply"
                        style={{ color: DARK_COLOR }}
                        activeClassName="active"
                      >
                        <ListItem className="dropdownWrapper">
                          <ListItemText primary="How to Apply" />
                        </ListItem>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div style={{ padding: "8px 16px" }} className="hasDropdown">
                  <button
                    className="hasDropdownBtn"
                    onClick={() => {
                      setShowPlatform(!showPlatform);
                    }}
                  >
                    <span>News and Events</span>
                    <img src={DropdownIcon} alt="" />
                  </button>
                  <div className={`dropdownMenu ${showPlatform ? "show" : ""}`}>
                    <div className="dropdownContainer">
                      <NavLink
                        onClick={() => toggleDrawer()}
                        to="/news-media-event/news"
                        style={{ color: DARK_COLOR }}
                        activeClassName="active"
                      >
                        <ListItem className="dropdownWrapper">
                          <ListItemText primary="News" />
                        </ListItem>
                      </NavLink>

                      <NavLink
                        onClick={() => toggleDrawer()}
                        to="/exam-timetable"
                        style={{ color: DARK_COLOR }}
                        activeClassName="active"
                      >
                        <ListItem className="dropdownWrapper">
                          <ListItemText primary="Exam Timetable" />
                        </ListItem>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div style={{ padding: "8px 16px" }} className="hasDropdown">
                  <button
                    className="hasDropdownBtn"
                    onClick={() => {
                      setShowContact(!showContact);
                    }}
                  >
                    <span>Contact Us</span>
                    <img src={DropdownIcon} alt="" />
                  </button>
                  <div className={`dropdownMenu ${showContact ? "show" : ""}`}>
                    <div className="dropdownContainer">
                      <NavLink
                        onClick={() => toggleDrawer()}
                        to="/contact-us"
                        style={{ color: DARK_COLOR }}
                        activeClassName="active"
                      >
                        <ListItem className="dropdownWrapper">
                          <ListItemText primary="Contact Us" />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        onClick={() => toggleDrawer()}
                        to="/help-and-support"
                        style={{ color: DARK_COLOR }}
                        activeClassName="active"
                      >
                        <ListItem className="dropdownWrapper">
                          <ListItemText primary="Help And Support" />
                        </ListItem>
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div ref={ref} style={{ padding: "8px 16px" }}>
                  <button
                    onClick={() => setIsMenuOpen(true)}
                    style={{
                      border: "none",
                      background: "transparent",
                      padding: "0px 0px 0px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={SearchIcon} alt="" />
                  </button>

                  {isMenuOpen && (
                    <div className="search-box">
                      <div className="searchArea event-scroll">
                        <form>
                          <input
                            onChange={inputHandler}
                            type="text"
                            className="search__field"
                            maxlength="150"
                            placeholder="Search…"
                            title="Search for:"
                          />
                          <button>
                            <img src={SearchWhite} alt="" />
                          </button>
                        </form>
                        <ListLinks input={inputText} />
                      </div>
                    </div>
                  )}
                </div>
              </List>

              <div
                style={{
                  bottom: 30,
                  left: 0,
                  width: "100%",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  // alignContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: "0px 32px",
                }}
              >
                <p
                  style={{
                    ...styles.text,
                    textAlign: "left",
                    marginBottom: "1rem",
                  }}
                >
                  Follow us on:
                </p>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {socials.map(({ link, icon }, key) => {
                    return (
                      <Link
                        onClick={() => toggleDrawer()}
                        className="nav-link-bottom"
                        to={link}
                        key={key}
                      >
                        <img src={icon} alt="img" />
                      </Link>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
