import PageContainer from "../../components/PageContainer";
import Tab from "../../components/Website/NewsEventTab";
import { Helmet } from "react-helmet";
import PageTitle from "../../components/Website/PageTitle";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Grid } from "@material-ui/core";
import servicesApi from "../../redux/api/services";
import Parser from "html-react-parser";
import { Menu, Search, ExpandLess } from "@material-ui/icons";

import moment from "moment";
import Pagination from "../../components/CustomPaginate";
import MapImg from "../../assets/images/map-image.png";
import { useHistory, useParams, useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const SingleNews = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const params = useParams();
  const eventsData = JSON.parse(localStorage.getItem("events_data") || "[]");

  const [events, setEvents] = useState([]);
  const [newsMediaEvents, setNewsMediaEvents] = useState([]);
  const [section, setSection] = useState("brief"); // value can be 'brief' || 'full'
  const [selectedId, setSelectedId] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const historyPerPage = 6;
  const pagesVisited = pageNum * historyPerPage;

  // Handles page count numbers
  const pageCount = Math.ceil(eventsData && eventsData.length / historyPerPage);

  //Handle page click
  const changePageHandler = ({ selected }) => {
    return setPageNum(selected);
  };

  // Use this to get events
  useEffect(() => {
    setLoading(true);
    const dashboardEvents = async () => {
      try {
        const dataResponse = await servicesApi.getEvents();
        const newsEvents = await servicesApi.getNewsEvents();
        const mediaEvents = await servicesApi.getMediaEvents();
        console.log(dataResponse);
        const data = dataResponse.data.events;
        console.log(data);
        setEvents(newsEvents?.data.concat(mediaEvents?.data));
        setNewsMediaEvents(newsEvents?.data.concat(mediaEvents?.data));
      } catch (error) {
        console.log("err", error);
      } finally {
        setLoading(false);
      }
    };
    dashboardEvents();
  }, []);

  let oneEvent = events.find((item) => {
    return item.id === Number(id);
  });

  // Get Id from URL
  useEffect(() => {
    setId(params?.id);
  }, [params?.id]);

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);

  return (
    <div>
      <Grid>
        <PageContainer>
          <Helmet>
            <title>News | NTI</title>
          </Helmet>
          <PageTitle title="News And Events" />
          <Tab />
          {/* <div className="container"> */}
          <div className=" container content">
            <button onClick={history.goBack} className="news-back-btn">
              Back
            </button>
            {/* Find and return item with id, setTheItem, now if available diplay in setSectionPage */}

            {loading ? (
              <div className="for-news-media">
                <CircularProgress />
              </div>
            ) : (
              <div className="right-news">
                {oneEvent && <h3>{oneEvent?.title}</h3>}
                {oneEvent && Parser(oneEvent?.paragraph)}
              </div>
            )}
          </div>
          {visibility && (
            <button
              onClick={() => window.scrollTo(0, 0)}
              className="scroll-top top-btn"
            >
              <ExpandLess />
            </button>
          )}
        </PageContainer>
      </Grid>
    </div>
  );
};

export default SingleNews;
