import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import servicesApi from "../../../redux/api/services";
import Parser from "html-react-parser";
import moment from "moment";
import Pagination from "../../CustomPaginate";
import MapImg from "../../../assets/images/map-image.png";

const EventsAndCalender = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const eventsData = JSON.parse(localStorage.getItem("events_data") || "[]");

  const [events, setEvents] = useState([]);
  const [section, setSection] = useState("brief"); // value can be 'brief' || 'full'
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [pageNum, setPageNum] = useState(0);
  const historyPerPage = 6;
  const pagesVisited = pageNum * historyPerPage;

  // Handles page count numbers
  const pageCount = Math.ceil(eventsData && eventsData.length / historyPerPage);

  //Handle page click
  const changePageHandler = ({ selected }) => {
    return setPageNum(selected);
  };

  // Use this to get events
  useEffect(() => {
    setLoading(true);
    const dashboardEvents = async () => {
      try {
        const dataResponse = await servicesApi.getEvents();
        console.log(dataResponse);
        const data = dataResponse.data.events;
        console.log(data);
        setEvents(data);
      } catch (error) {
        console.log("err", error);
      } finally {
        setLoading(false);
      }
    };
    dashboardEvents();
  }, []);

  let oneEvent = eventsData.find((item) => {
    return item.id === selectedId;
  });
  console.log(oneEvent);
  console.log(selectedId);

  return (
    <div className="content">
      {section === "brief" && (
        <>
          <div className="grid-events">
            {eventsData
              .slice(pagesVisited, pagesVisited + historyPerPage)
              .map((event) => {
                return (
                  <div
                    className="dates-card"
                    style={{ marginBottom: "20px" }}
                    key={event.id}
                    onClick={() => {
                      setSelectedId(event.id);
                      setSection("page");
                    }}
                  >
                    <div className="for-dates-img">
                      <img
                        className="image"
                        src={MapImg}
                        alt=""
                        width="150px"
                        height="150px"
                      />
                    </div>

                    <div
                      className="for-dates-txt"
                      style={{ marginLeft: "20px" }}
                    >
                      <p>{`${moment(event.date).format("LL")}`}</p>
                      <h3>{event.title.rendered.slice(0, 40)}</h3>
                      {Parser(`${event.excerpt.rendered.slice(0, 40)}...`)}
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="events-pagination-div">
            <Pagination
              pageCount={pageCount}
              onPageChange={changePageHandler}
              containerClassName="pagination"
              activeClassName="paginate-active"
              disabledClassName="paginate-disabled"
              previousClassName="paginate-previous"
              nextClassName="paginate-next"
              breakLabel="..."
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              // renderOnZeroPageCount={null}
            />
          </div>
        </>
      )}

      {section === "page" && (
        <>
          <button
            onClick={() => {
              setSection("brief");
            }}
            className="news-back-btn"
          >
            Back
          </button>
          {/* Find and return item with id, setTheItem, now if available diplay in setSectionPage */}
          <div className="right-news">
            {oneEvent && <h3>{oneEvent.title.rendered}</h3>}
            {oneEvent && Parser(oneEvent.content.rendered)}
          </div>
        </>
      )}
    </div>
  );
};

export default EventsAndCalender;
