import React, { lazy, Suspense } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Error404 from "../pages/Error404";
import Loader from "../components/Loader";

const ProgrammeSelection = lazy(() =>
  import("../pages/applicant/ProgrammeSelection")
);
const Preview = lazy(() => import("../pages/applicant/Preview"));
const Preview1 = lazy(() => import("../pages/applicant/Preview1"));

const AdmissionLetter = lazy(() =>
  import("../pages/applicant/AdmissionLetter")
);
const ApplicantInvoice = lazy(() =>
  import("../pages/applicant/ApplicantInvoice")
);
const TuitionReceipt = lazy(() => import("../pages/applicant/TuitionReceipt"));
const GeneratedReceipt = lazy(() =>
  import("../pages/applicant/GeneratedReceipt")
);
const Dashboard = lazy(() => import("../pages/applicant/Dashboard"));
const EditApplication = lazy(() =>
  import("../pages/applicant/EditApplication")
);
const Documents = lazy(() => import("../pages/applicant/Documents"));
const PaymentHistory = lazy(() => import("../pages/applicant/PaymentHistory"));
const PayTuition = lazy(() => import("../pages/applicant/PayTuition"));
const payGenFees = lazy(() => import("../pages/applicant/PayGenFees"));
const payAnyFee = lazy(() => import("../pages/applicant/PayAnyFee"));
const ReceiptDownload = lazy(() =>
  import("../pages/applicant/ReceiptDownload")
);

const ApplicantRoutes = () => {
  const { path, url } = useRouteMatch();

  // console logged by Peter Ihimire
  console.log(Redirect, url);
  console.log(path);
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route
          path={`${path}/personal-information`}
          component={ProgrammeSelection}
        />
        <Route path={`${path}/preview`} component={Preview} />
        <Route path={`${path}/preview1`} component={Preview1} />
        <Route
          path={`${path}/documents/admission-letter`}
          component={AdmissionLetter}
        />
        <Route
          path={`${path}/documents/applicant-invoice`}
          component={ApplicantInvoice}
        />
        <Route
          path={`${path}/documents/tuition-receipt`}
          component={TuitionReceipt}
        />
        <Route
          path={`${path}/documents/receipt`}
          component={GeneratedReceipt}
        />
        <Route
          path={`${path}/documents/new-receipt`}
          component={ReceiptDownload}
        />
        <Route exact path={`${path}/dashboard`} component={Dashboard} />
        <Route exact path={`${path}/documents`} component={Documents} />
        <Route
          exact
          path={`${path}/payment-history`}
          component={PaymentHistory}
        />
        <Route
          exact
          path={`${path}/dashboard/pay-tuition`}
          component={PayTuition}
        />
        <Route exact path={`${path}/edit`} component={EditApplication} />
        <Route
          exact
          path={`${path}/dashboard/pay-general-fees/:Id`}
          component={payGenFees}
        />
        <Route
          exact
          path={`${path}/dashboard/pay-any-fee/:Id`}
          component={payAnyFee}
        />

        <Route path="**">
          <Error404 />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default ApplicantRoutes;

// import React from "react";
// import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
// import Error404 from "../pages/Error404";
// import ProgrammeSelection from "../pages/applicant/ProgrammeSelection";
// import Preview from "../pages/applicant/Preview";
// import AdmissionLetter from "../pages/applicant/AdmissionLetter";
// import ApplicantInvoice from "../pages/applicant/ApplicantInvoice";
// import TuitionReceipt from "../pages/applicant/TuitionReceipt";
// import GeneratedReceipt from "../pages/applicant/GeneratedReceipt";
// import Dashboard from "../pages/applicant/Dashboard";
// import Documents from "../pages/applicant/Documents";
// import PaymentHistory from "../pages/applicant/PaymentHistory";
// import PayTuition from "../pages/applicant/PayTuition";
// import payGenFees from "../pages/applicant/PayGenFees";
// import payAnyFee from "../pages/applicant/PayAnyFee";

// const ApplicantRoutes = () => {
//   const { path, url } = useRouteMatch();

//   // console logged by Peter Ihimire
//   console.log(Redirect, url);
//   console.log(path);
//   return (
//     <Switch>
//       <Route
//         path={`${path}/personal-information`}
//         component={ProgrammeSelection}
//       />
//       <Route path={`${path}/preview`} component={Preview} />
//       <Route
//         path={`${path}/documents/admission-letter`}
//         component={AdmissionLetter}
//       />
//       <Route
//         path={`${path}/documents/applicant-invoice`}
//         component={ApplicantInvoice}
//       />
//       <Route
//         path={`${path}/documents/tuition-receipt`}
//         component={TuitionReceipt}
//       />
//         <Route
//         path={`${path}/documents/receipt`}
//         component={GeneratedReceipt}
//       />
//       <Route exact path={`${path}/dashboard`} component={Dashboard} />
//       <Route exact path={`${path}/documents`} component={Documents} />
//       <Route
//         exact
//         path={`${path}/payment-history`}
//         component={PaymentHistory}
//       />
//       <Route
//         exact
//         path={`${path}/dashboard/pay-tuition`}
//         component={PayTuition}
//       />
//       <Route
//         exact
//         path={`${path}/dashboard/pay-general-fees/:Id`}
//         component={payGenFees}
//       />
//        <Route
//         exact
//         path={`${path}/dashboard/pay-any-fee/:Id`}
//         component={payAnyFee}
//       />

//       <Route path="**">
//         <Error404 />
//       </Route>
//     </Switch>
//   );
// };

// export default ApplicantRoutes;
