import React, { useState } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";
// import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import { Link, useLocation, useHistory, NavLink } from "react-router-dom";
import { Select, Box, MenuItem } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";

const Tab = () => {
  const history = useHistory();
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const programsTab = [
    { title: "ADE", link: "/programmes/advanced-diploma-in-education-ADE" },
    {
      title: "ADEE",
      link: "/programmes/advanced-diploma-in-environmental-education-ADEE",
    },
    { title: "BED", link: "/programmes/bachelors-degree-in-education-BED" },
    { title: "NCE", link: "/programmes/nigeria-certificate-in-education-NCE" },
    {
      title: "PGDE",
      link: "/programmes/post-graduate-diploma-in-education-PGDE",
    },
    {
      title: "PTTP",
      link: "/programmes/pivotal-teacher-training-programme-PTTP",
    },
  ];

  // const [open, setOpen] = useState("StateOffices");
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  console.log(value);
  return (
    <div>
      {isPhone ? (
        <div className="container content">
          <Box
            // sx={{ Width: 100 }}
            className="for-mobile-tab"
          >
            <Select
              className="mobile-select"
              value={value}
              onChange={handleChange}
              displayEmpty
              disableUnderline
              inputProps={{
                "aria-label": "Without label",
              }}
              style={{
                padding: "0.5rem",
                width: "100%",
                textAlign: "center",
                color: "#fff",
                background: "#46c35f",
                borderRadius: "4px",
              }}
            >
              <MenuItem
                onClick={() => {
                  history.push("/programs");
                }}
                value=""
              >
                General
              </MenuItem>
              {programsTab.map(({ link, title }, key) => (
                <MenuItem
                  onClick={() => {
                    history.push(link);
                  }}
                  value=""
                  key={key}
                >
                  {title}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </div>
      ) : (
        <Grid>
          <List
            style={{
              display: "flex",
              // justifyContent: "space-evenly",
              backgroundColor: "#F6F6F6",
              alignItems: "center",
              height: "48px",
              width: "1171px",
              margin: "0 auto",
            }}
          >
            <NavLink
              to="/programs"
              style={{ padding: "0.5rem 1rem" }}
              className="tabLink"
              activeClassName="activetabList"
            >
              <AppsIcon />
            </NavLink>
            {programsTab.map(({ link, title }, key) => (
              <NavLink
                to={link}
                key={key}
                className="tabLink"
                activeClassName="activetabList"
              >
                <ListItem className="tabList" style={{ textAlign: "center" }}>
                  <h4>{title}</h4>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Grid>
      )}
    </div>
  );
};

export default Tab;
