import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import styles from "../../styles/webStyles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Sidebar = ({ applicationFee, yrs, semester }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  const history = useHistory();

  return (
    <div data-aos="fade-up">
      <Grid container style={{ padding: "1rem 0" }}>
        <Card
          variant="outlined"
          style={{
            width: "100%",
            margin: "0 auto 0.5rem auto",
            boxShadow: "2px 5px 6px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Typography
            gutterBottom
            style={{
              textAlign: "center",
              background: "#F6F6F6",
              padding: "1rem 2rem",
            }}
          >
            Application fee
          </Typography>

          <h3 style={{ textAlign: "center", padding: "1rem 0" }}>
            {applicationFee}
          </h3>
        </Card>

        <Card
          variant="outlined"
          style={{
            width: "100%",
            margin: "0.5rem auto 0 auto",
            boxShadow: "2px 5px 6px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Typography
            gutterBottom
            style={{
              textAlign: "center",
              background: "#F6F6F6",
              padding: "1rem 2rem",
            }}
          >
            Course Duration:
          </Typography>

          <Typography
            style={{
              padding: "0 2rem",
              display: "flex ",
              justifyContent: "space-between",
            }}
          >
            <p>Semesters:</p> <p>{semester}</p>
          </Typography>
          <Typography
            style={{
              marginTop: "-1rem",
              padding: "0 2rem",
              display: "flex ",
              justifyContent: "space-between",
            }}
          >
            <p>Years:</p> <p>{yrs}</p>
          </Typography>
        </Card>
        <Typography
          style={{
            width: "100%",
            margin: "1rem auto",
            fontSize: "14px",
          }}
        >
          * Calendar years depending on the entry qualifications of the
          candidates.{" "}
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            margin: "1rem auto",
          }}
        >
          {/* <button className="greenBtn" onClick={() => history.push("/applicant/new")}>
          Apply for Admission
        </button> */}
          <a className="greenBtn" href="/applicant/new" target="_blank">
            <button className="greenBtn">Apply for Admission</button>
          </a>
        </div>
      </Grid>
    </div>
  );
};

export default Sidebar;
