import React from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import InquirerIllustration from "../../assets/images/InquirerInfoIllustration.jpg";

const RequirementInformation = () => {
  const history = useHistory();

  return (
    <div>
      <Grid>
        <h1>Request Information</h1>
        <p>Please fill the form with the appropraite information</p>
        <Grid container spacing={10}>
          <Grid item md={9}>
            <div className="external-services-card">
              <Grid container>
                <Grid item md={6}>
                  <p>What are you requesting for?</p>
                  <TextField
                    id="outlined-basic"
                    label="Enter Fullname"
                    variant="outlined"
                  />
                 
                </Grid>
                <Grid item md={6}>
                  <p>Amount</p>
                  <TextField
                    id="outlined-basic"
                    label="Enter email address"
                    variant="outlined"
                  />
                 
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width:"85%",
                  height: "8rem",
                  alignItems: "center",
                  marginTop: "5rem"
                }}
              >
                <button
                 style={{marginRight:"1rem"}}
                  className="external-services-button"
                  onClick={() =>
                    history.push("/external-services/external-layout")
                  }
                >
                  Previous
                </button>
                <button
                style={{marginLeft:"1rem"}}
                  className="external-services-button"
                  
                >
                  Next
                </button>
              </div>
            </div>
          </Grid>
          <Grid item md={3}>
            <img alt='' src={InquirerIllustration} />
          </Grid>
        </Grid>
      </Grid>
      
    </div>
  );
};

export default RequirementInformation;
