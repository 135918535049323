import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
import mainStyles from "../styles/mainStyles";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import PageImage from "../assets/images/404-page-image.svg";
import Page404Image from "../assets/images/404.svg";
import PageContainer from "../components/PageContainer";

const Error404 = (props) => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  return (
    <PageContainer>
      <Container
        style={isPhone ? mainStyles.phoneContainer : mainStyles.container}
      >
        <Helmet>
          <title>404 - Page Not Found | NTI</title>
        </Helmet>

        <Box
          className='slide-box'
          style={isPhone ? {} : { margin: "20px 100px" }}
        >
          <Paper elevation={5}>
            <Grid
              className='log-div'
              style={{
                ...mainStyles.subContainer,
                ...{ padding: "30px 50px" },
              }}
            >
              <Grid
                className='log-grid'
                item
                xs={12}
                sm={6}
                style={{
                  borderRadius: "10px 0 0 10px",
                  padding: "30px 60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt='page' src={PageImage} />
              </Grid>

              <Grid item xs={12} sm={6} style={{ padding: "20px 15px" }}>
                <Typography
                  className='login-main'
                  style={{
                    fontWeight: 500,
                    fontSize: "20px",
                    margin: "5%",
                    marginBottom: "0",
                    textAlign: "center",
                  }}
                >
                  It seems you are lost
                </Typography>

                <img
                  alt='404'
                  src={Page404Image}
                  style={{ margin: "-2rem auto", width: "100%" }}
                />

                <Typography
                  className='log-sub'
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#9F9F9F",
                    textAlign: "center",
                  }}
                >
                  The page you’re looking for was not found <br />
                  Good thing i know the right way
                </Typography>
                <Grid item xs={12} style={mainStyles.inputGrid}>
                  <Box mb={6}>
                    <Button
                      onClick={() => (window.location.href = "/")}
                      fullWidth
                      style={{ height: "50px" }}
                      variant='contained'
                      color='primary'
                    >
                      GO BACK HOME
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </PageContainer>
  );
};
export default Error404;
