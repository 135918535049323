import React, { useState, useEffect } from "react";
import styles from "../styles/webStyles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { ExpandLess } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PageTitle from "../components/Website/PageTitle";
import PageContainer from "../components/PageContainer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Faq from "../components/Website/FAQ";
import { Link } from "react-router-dom";
import CallIcon from "@material-ui/icons/Call";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Twitter from "../assets/images/twitter.svg";
import Facebook from "../assets/images/facebook.svg";
import Message from "../assets/images/Message.svg";
import GridImage from "../assets/images/support-img.png";
import AOS from "aos";
import "aos/dist/aos.css";

const HelpAndSupport = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const [expandedAccordions, setExpandedAccordions] = React.useState([]);
  const [visibility, setVisibility] = useState(false);

  const handleChange = (key) => {
    if (expandedAccordions.indexOf(key) > -1) {
      setExpandedAccordions(expandedAccordions.filter((exp) => exp !== key));
    } else {
      setExpandedAccordions([...expandedAccordions, key]);
    }
  };

  const sectionOne = [
    {
      heading: "How to access the NTI Portal?",
      content: (
        <ul>
          <li>Go to the browser and Type: </li>
          <li>
            {" "}
            <a href="https://my.nti.edu.ng" target={"_blank"}>
              my.nti.edu.ng
            </a>
          </li>
        </ul>
      ),
    },
    {
      heading: "How to access the NTI website",
      content: (
        <ul>
          <li>Go to the browser using your phone or system type:</li>
          <li>
            {" "}
            <a href="https://nti.edu.ng" target={"_blank"}>
              nti.edu.ng
            </a>{" "}
          </li>
        </ul>
      ),
    },
    {
      heading: "How to apply for NTI programs",
      content: (
        <ul>
          <li>Go to the browser and type:</li>
          <li>
            <a href="https://my.nti.edu.ng" target={"_blank"}>
              my.nti.edu.ng
            </a>
          </li>
          <li>
            click on the BLUE Icon,( NEW STUDENT APPLY ) to start your
            application{" "}
          </li>
        </ul>
      ),
    },
    {
      heading: "How to log in as a returning student",
      content: (
        <ul>
          <li>Type on your browser: my.nti.edu.ng</li>
          <li>Click on the GREEN icon (current student register)</li>
          <li>Type in your email address and password </li>
          <li>Click on login </li>
        </ul>
      ),
    },
  ];

  const socials = [
    { link: "https://wa.me/09095450282", icon: Message },
    { link: "https://twitter.com/ntiedung", icon: Twitter },
    { link: "https://www.facebook.com/ntiedung/", icon: Facebook },
  ];

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>Help & Support | NTI</title>
      </Helmet>
      <PageTitle title="Help & Support" />

      <div className="" style={{ background: "#fff" }}>
        {/* added container here */}
        <div data-aos="fade-up" className="container content">
          <div>
            {sectionOne.map((items, index) => {
              return (
                <Accordion
                  className="accordion-section"
                  style={{ margin: "0.5rem 0" }}
                  key={index}
                  defaultExpanded={false}
                  onChange={() => handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedAccordions.indexOf(index) > -1 ? (
                        <RemoveIcon className="icon-color" />
                      ) : (
                        <AddIcon className="icon-color" />
                      )
                    }
                  >
                    <h4>{items.heading}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{items.content}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
        <Faq />
        <div data-aos="fade-up" className="container content">
          <div className="accordion-grid">
            <div item xs={12} md={6}>
              <h2
                style={{
                  ...styles.subHeader,
                  fontSize: isPhone ? "30px" : "24px",
                }}
              >
                Need more answers?
              </h2>
              <p style={{ margin: " 10px 0px" }}>
                You can find them right here
              </p>
              <div style={{ margin: " 10px 0px" }}>
                <p className="contact-list">
                  <CallIcon className="contactIcon" />
                  <a
                    style={{ color: "black" }}
                    href="tel:09064579779"
                    target="_blank"
                  >
                    090 6457 9779
                  </a>
                </p>
                <p className="contact-list">
                  <WhatsApp className="contactIcon" />
                  <a
                    style={{ color: "black" }}
                    href="https://wa.me/09095450282"
                    target="_blank"
                  >
                    090 9545 0282
                  </a>
                </p>
                <p className="contact-list">
                  <AlternateEmailIcon className="contactIcon" />
                  <a
                    style={{ color: "black" }}
                    href="mailto:support@nti.edu.ng"
                    target="_blank"
                  >
                    {" "}
                    support@nti.edu.ng
                  </a>
                </p>
              </div>

              <div style={{ margin: "2rem 0px 2rem 0px" }}>
                {socials.map(({ link, icon }, key) => {
                  return (
                    <a
                      className="contact-link"
                      href={link}
                      key={key}
                      target="_blank"
                    >
                      <img src={icon} alt="img" />
                    </a>
                  );
                })}
              </div>
            </div>
            <div item xs={12} md={6}>
              <img
                src={GridImage}
                style={{
                  ...styles.supportImages,
                  width: isPhone ? "100%" : "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};

export default HelpAndSupport;
