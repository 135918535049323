import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  Checkbox,
  Paper,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import mainStyles from "../../styles/mainStyles";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { PRIMARY_COLOR } from "../../styles/colors";
import { VisibilityOff } from "@material-ui/icons";
import { Visibility } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  studentLogin,
  logoutStudent,
  getStudentData,
} from "../../redux/actions/studentAction";
import PageContainer from "../../components/PageContainer";
import AOS from "aos";
import "aos/dist/aos.css";
// import Loader from "../../components/Loader";

const Login = ({ studentLogin, logoutStudent, student, getStudentData }) => {
  const history = useHistory();
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    checked: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [logging, setLogging] = useState(false);

  const handleFormChange = ({ name, value }) => {
    setFormError("");
    setLoginForm({ ...loginForm, [name]: value });
  };

  const handleLogin = async () => {
    setFormError("");

    if (
      !loginForm?.email.includes("@") ||
      !loginForm?.email.includes("my.nti.edu.ng")
    ) {
      setFormError(
        "Email must be a valid email, missing '@' or ' my.nti.edu.ng ' "
      );
      return;
    }
    setLogging(true);
    try {
      const user = await studentLogin(loginForm);
      console.log(user);
      await getStudentData();
      history.push("/student/dashboard");
    } catch (err) {
      setFormError(err.data.message);
    } finally {
      setLogging(false);
    }
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <PageContainer>
      {/* {logging && <Loader />} */}
      <Container
        style={isPhone ? mainStyles.phoneContainer : mainStyles.container}
      >
        <Helmet>
          <title>Student Login | NTI PORTAL</title>
        </Helmet>
        <Box
          data-aos="fade-up"
          className="slide-box"
          style={isPhone ? {} : { margin: "30px 100px" }}
        >
          <Paper elevation={5}>
            <Grid
              className="log-div"
              style={{ ...mainStyles.subContainer, ...{ padding: 0 } }}
            >
              <Grid
                className="log-grid"
                item
                xs={12}
                sm={6}
                style={{ backgroundColor: PRIMARY_COLOR, padding: "30px 60px" }}
              >
                <Typography
                  className="login-main"
                  style={{
                    fontWeight: 300,
                    fontSize: "45px",
                    color: "#FFF",
                    marginBottom: "25%",
                    lineHeight: "60px",
                    paddingRight: "6%",
                  }}
                >
                  Welcome Back to the NTI Student Portal
                </Typography>

                <Typography
                  className="log-sub"
                  style={{
                    fontWeight: 300,
                    fontSize: "20px",
                    lineHeight: "40px",
                    color: "#FFF",
                  }}
                >
                  By clicking sign in, you agree to National Teachers Institute,
                  Security Protocols, Please see Terms &amp; Conditions.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} style={{ padding: "20px 15px" }}>
                <Typography
                  style={{ fontWeight: 500, fontSize: "40px", margin: "5%" }}
                >
                  Sign In
                </Typography>
                <Grid item style={mainStyles.inputGrid}>
                  <TextField
                    label="Email Address"
                    style={mainStyles.input}
                    variant="outlined"
                    name="email"
                    onChange={(e) => handleFormChange(e.target)}
                    value={loginForm.email}
                  />
                </Grid>
                <Grid item xs={12} style={mainStyles.inputGrid}>
                  <TextField
                    label="Password"
                    style={mainStyles.input}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={loginForm.password}
                    onChange={(e) => handleFormChange(e.target)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={mainStyles.inputGrid}>
                  <FormControl variant="outlined" style={mainStyles.input}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={loginForm.checked}
                          onChange={() =>
                            setLoginForm({
                              ...loginForm,
                              checked: !loginForm.checked,
                            })
                          }
                          name="checked"
                          color="primary"
                        />
                      }
                      label="Remember Me"
                    />
                  </FormControl>
                  <FormHelperText
                    error
                    style={{ textAlign: "center", marginBottom: "1rem" }}
                  >
                    {formError}
                  </FormHelperText>
                  <Box mb={6}>
                    <Button
                      disabled={logging}
                      onClick={() => handleLogin()}
                      fullWidth
                      style={{ height: "50px" }}
                      variant="contained"
                      color="primary"
                    >
                      {logging ? (
                        <CircularProgress color={"#FFF"} size="15px" />
                      ) : (
                        "Login"
                      )}
                    </Button>
                  </Box>
                </Grid>

                <Typography
                  style={{
                    fontWeight: 300,
                    fontSize: "16px",
                    marginLeft: "15px",
                  }}
                >
                  I forgot my{" "}
                  <Link
                    to="/student/reset-password"
                    style={{ color: "#46c35f" }}
                  >
                    User ID{" "}
                  </Link>
                  or{" "}
                  <Link
                    to="/student/reset-password"
                    style={{ color: "#46c35f" }}
                  >
                    Password
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </PageContainer>
  );
};

const mapStateToProps = ({ student }) => ({ student });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { studentLogin, logoutStudent, getStudentData },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
