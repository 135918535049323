import {
  SET_SERVICES,
  SET_PROGRAMMES,
  SET_STATES,
  //   SET_CENTERS,
  //   SET_EVENTS,
  //   SET_GRADES,
  //   SET_SPECIALIZATIONS,
  //   SET_ZONES,
  //   SET_SUBJECTS,
  //   SET_FEE_TYPES,
  //   SET_SESSION,
} from "./constants";
import servicesApi from "../api/services";

export const getServices = () => {
  return async (dispatch) => {
    try {
      // // Disturbing testing hence commented it out
      // const events = await (await servicesApi.fetchEvents()).data.events;
      const session = await (await servicesApi.getSession()).data.result;
      const programmes = await (await servicesApi.getProgrammes()).data;
      // console.log(programmes);
      const states = await (await servicesApi.getStates()).data;
      const zones = await (await servicesApi.getZones()).data;
      const feeTypes = await (await servicesApi.getFeeTypes()).data;
      // const subjects = await (await servicesApi.getSubjects()).data;
      const subjects = await (await servicesApi.getSubjects()).data;
      // console.log(subjects);
      // const grades = await (await servicesApi.getAllGrades()).data.grades;
      const grades = await (await servicesApi.getGrades()).data;
      // console.log(grades);
      const centers = await (
        await servicesApi.getAllCenters()
      ).data.studyCenter;
      const specializations = await (
        await servicesApi.getSpecializations()
      ).data;
      const resultType = await (await servicesApi.getResultType()).data;
      // console.log(resultType);
      const res = await Promise.all([
        session,
        programmes,
        states,
        zones,
        feeTypes,
        subjects,
        grades,
        centers,
        // events,
        specializations,
      ]).then((data) => {
        // console.log(data);
      });
      // console.log(res);
      const payload = {
        session: res[0],
        programmes: res[1],
        states: res[2],
        zones: res[3],
        feeTypes: res[4],
        subjects: res[5],
        grades: res[6],
        centers: res[7],
        events: res[8],
        specializations: res[9],
        resultType: res[10],
      };

      await dispatch({
        type: SET_SERVICES,
        payload,
      });
      // console.log(payload);
      return Promise.resolve(payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getProgrammesData = () => {
  return async (dispatch) => {
    try {
      const response = await servicesApi.getProgrammes();
      // console.log(response.data);
      let programmesData = response.data;
      //   const { applicant, user } = response.data.data;
      //   const applicantData = JSON.parse(
      //     localStorage.getItem("nti_applicant") || "{}",
      //   );

      //   console.log(applicantData);

      //   const newApplicant = { ...applicantData, ...{ applicant, user } };
      //   console.log(newApplicant);

      //   localStorage.setItem("nti_applicant", JSON.stringify(newApplicant));
      await dispatch({
        type: SET_PROGRAMMES,
        payload: programmesData,
      });
      return Promise.resolve(programmesData);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getStatesData = () => {
  return async (dispatch) => {
    try {
      const response = await servicesApi.getStates();
      // console.log(response.data);
      let statesData = response.data;

      await dispatch({
        type: SET_STATES,
        payload: statesData,
      });
      return Promise.resolve(statesData);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
