import React from "react";
import "./App.css";
import Routes from "./routes/routes";
import { Provider } from "react-redux";
import initStore from "./redux/store";
import Theme from "./styles/theme";

export const store = initStore({});

const App = () => {
  return (
    <Provider store={store}>
      <Theme>
        <Routes />
      </Theme>
    </Provider>
  );
};

export default App;
