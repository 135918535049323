import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import { CourseCard } from "../CardComponent";
import Pic from "../../../assets/images/MiniPic.png";
import Pic2 from "../../../assets/images/lady-smiling.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import AOS from "aos";
import "aos/dist/aos.css";
import serviceApi from "../../../redux/api/services";
import moment from "moment";
import Parser from "html-react-parser";

SwiperCore.use([Pagination, Autoplay]);

const CourseSection = () => {
  const [events, setEvents] = useState([]);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const history = useHistory();

  const truncate = (str, n, useWordBoundary) => {
    if (str.length <= n) {
      return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "&hellip;"
    );
  };

  const getCourseEvents = async () => {
    setFetchingEvents(true);
    try {
      const res = await serviceApi.getCourseEvents();
      setEvents(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingEvents(false);
    }
  };

  useEffect(() => {
    getCourseEvents();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <div data-aos="fade-up">
      <Swiper
        // centeredSlides={true}
        // slidesPerView={4}
        spaceBetween={50}
        breakpoints={{
          200: { slidesPerView: 1 },
          500: { slidesPerView: 1 },
          700: { slidesPerView: 2 },
          900: { slidesPerView: 2 },
          1200: { slidesPerView: 2 },
          2500: { slidesPerView: 2 },
        }}
        speed={3000}
        loop
        pagination={{
          el: ".custom-pag1",
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '">' + "</span>";
          },
        }}
        className={`swiper-wrapper slideWrapper`}
        autoplay={{
          delay: 300,
          disableOnInteraction: false,
        }}
      >
        {React.Children.toArray(
          events?.slice(0, 5)?.map((event) => (
            <SwiperSlide>
              <div>
                <div className="course-card-div">
                  <img
                    alt=""
                    src={`https://www.nti.edu.ng/static/media/Logo.b5d023f99a9bae47a2886b993915b4d9.svg`}
                    width="40%"
                    height="150px"
                  />

                  <div className="course-card">
                    <p>{moment(event?.created_at).format("MMMM DD, YYYY")}</p>
                    <h3>{Parser(event?.title)}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: truncate(event?.paragraph, 100, true),
                      }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
      <div className="custom-pag1"></div>
    </div>
  );
};

export default CourseSection;
