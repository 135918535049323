import applicantReducer from "./ApplicantReducer";
import serviceReducer from "./serviceReducer";
import AdminReducer from "./AdminReducer";
import StudentReducer from "./StudentReducer";

const rootReducer = {
  applicant: applicantReducer,
  services: serviceReducer,
  admin: AdminReducer,
  student: StudentReducer,
};

export default rootReducer;
