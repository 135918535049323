import React from "react";
import styles from "../../styles/webStyles";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/Website/PageTitle";
import HomeIllustration from "../../assets/images/ExternalHomeIllustration.jpg";

const ExternalHome = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();

  return (
    <div>
      <Navbar />
      <PageTitle title="External Services" />
      <main className="container" style={{ marginTop: "5rem" }}>
        <Grid container className="external-services-home">
          <Grid item md={6}>
            <img alt='' src={HomeIllustration} />
          </Grid>
          <Grid item md={6}>
            <h2>Welcome</h2>
            <p>
              If you want to request for transcripts and verification of
              ex-students of NTI, you are in the right place.
            </p>
            <p>
              {" "}
              Note that you have to be either a Corporate business, a tertiary
              institution (University or College) or an ex-student to use this
              portal. Please, if you do not belong to any of the three
              categories mentioned, you might be in the wrong place.
            </p>
            <p>Click on the button below to proceed.</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "10rem",
              }}
            >
              <button
                className="greenButton"
                style={{
                  ...styles.greenButton,
                  margin: isPhone ? "10px 20px 10px 20px" : "50px 15px 20px 0",
                  width: isPhone ? "100%" : "fit-content",
                }}
                onClick={() => history.push("/external-services/external-layout")}
              >
                Proceed
              </button>
            </div>
          </Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default ExternalHome;
