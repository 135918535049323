import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Logo from "../../assets/images/nti-logo.svg";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../styles/colors";
import { useMediaQuery } from "react-responsive";
import { ExitToApp, Menu, Done } from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutApplicant } from "../../redux/actions/applicantAction";
import Profile from "../../assets/images/profile.svg";

const Nav = ({ applicant, logoutApplicant }) => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [open, setOpen] = useState(false);
  const [applicantData, setApplicantData] = useState(applicant.applicantData);
  const [fullName, setFullName] = useState("");
  const [application_no, setApplicationNo] = useState("");
  const [image, setImage] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [applicationState, setApplicationState] = useState(
    applicantData?.applicant?.application_state
  );
  const currentURL = location.pathname;

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const goto = (value) => {
    if (!applicantData?.applicant?.completed) {
      history.push("/applicant/personal-information", { step: value });
    }
    toggleDrawer();
  };

  var links = [
    {
      title:
        currentURL === "/applicant/login"
          ? "New Application"
          : "Continue Application",
      link:
        currentURL === "/applicant/login"
          ? "/applicant/new"
          : "/applicant/login",
    },
    { title: "Home", link: "/" },
    { title: "How to Apply", link: "/how-to-apply" },
    { title: "Help & Support", link: "/help-and-support" },
  ];

  useEffect(() => {
    setApplicantData(applicant.applicantData);
    if (applicant.applicantData && applicant.applicantData.applicant) {
      const { surname, firstname, othername, profile_picture, application_no } =
        applicant.applicantData.applicant;
      setFullName(`${surname} ${firstname} ${othername}`);
      setImage(profile_picture);
      setApplicationNo(application_no);
    }
  }, [applicant.applicantData]);

  const handleLogout = async () => {
    try {
      await logoutApplicant();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AppBar position="sticky" color="primary">
      <Toolbar style={{ padding: "1rem 0rem" }} className="container">
        <Grid item xs={3} style={{ paddingLeft: "1rem" }}>
          <Box>
            <Link to="/">
              <img src={Logo} height={40} width={40} alt="Logo" />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {isPhone ? (
            <>
              <Button onClick={() => toggleDrawer()}>
                <Menu style={{ color: "#FFF", fontSize: 40 }} />
              </Button>
            </>
          ) : (
            <>
              {applicantData ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => handleLogout()}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexDirection: "column",
                        marginRight: "1rem",
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: "capitalize",
                          display: "block",
                        }}
                      >
                        Welcome, {fullName}
                      </Typography>
                      <Typography variant="body2">
                        {application_no && application_no}
                      </Typography>
                    </div>
                    <img
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                      height={40}
                      width={40}
                      src={image || Profile}
                      alt="profile"
                    />
                    <ExitToApp
                      onClick={() => handleLogout()}
                      style={{
                        fontSize: 30,
                        marginLeft: "1rem",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </>
              ) : (
                links.map(({ link, title }, key) => {
                  return (
                    <Link to={link} key={key}>
                      <Button
                        variant={key === 0 ? "contained" : "text"}
                        style={key === 0 ? styles.mainBtn : styles.btn}
                      >
                        {title}
                      </Button>
                    </Link>
                  );
                })
              )}
            </>
          )}
        </Grid>

        <Drawer anchor="right" open={open} onClose={() => toggleDrawer()}>
          <div role="presentation">
            <AppBar position="static" color="primary">
              <Toolbar style={{ padding: ".5rem 1rem" }}>
                {applicant.applicantData ? (
                  <div>
                    <Typography>Welcome, {fullName}</Typography>
                    <div
                      onClick={() => handleLogout()}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Logout</Typography>
                      <ExitToApp style={{ fontSize: "16px" }} />
                    </div>
                  </div>
                ) : (
                  "Menu"
                )}
              </Toolbar>
            </AppBar>
            <List style={{ minWidth: "250px", padding: "10px 15px" }}>
              {!applicant.applicantData &&
                links.map(({ title, link }, key) => (
                  <Link to={link} key={key} style={{ color: PRIMARY_COLOR }}>
                    <ListItem>
                      <ListItemText primary={title} />
                    </ListItem>
                    <Divider />
                  </Link>
                ))}

              {applicant.applicantData &&
                applicant?.applicantData?.applicant?.application_state >= 3 && (
                  <div>
                    <ListItem>
                      <ListItemText
                        onClick={() => goto(3)}
                        primary={"Personal Information"}
                        primaryTypographyProps={{
                          color: applicationState > 3 ? "primary" : "initial",
                          fontSize: "14px",
                        }}
                      />
                      {applicationState > 4 && <Done color="primary" />}
                    </ListItem>
                    <Divider />
                    <Accordion style={{ boxShadow: "none" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          style={{
                            color:
                              applicationState >= 6 ? PRIMARY_COLOR : "initial",
                          }}
                        >
                          Certificates Obtained
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ margin: 0, padding: 0 }}>
                        <div style={{ width: "100%" }}>
                          <ListItem>
                            <ListItemText
                              onClick={() => goto(4)}
                              primary={"O-Level"}
                              primaryTypographyProps={{
                                color:
                                  applicationState > 4 ? "primary" : "initial",
                                fontSize: "14px",
                              }}
                            />
                            {applicationState >= 5 && <Done color="primary" />}
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              onClick={() => goto(5)}
                              primary={"Tertiary"}
                              primaryTypographyProps={{
                                color:
                                  applicationState > 5 ? "primary" : "initial",
                                fontSize: "14px",
                              }}
                            />
                            {applicationState >= 6 && <Done color="primary" />}
                          </ListItem>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        onClick={() => goto(6)}
                        primary={"Work Experience"}
                        primaryTypographyProps={{
                          color: applicationState > 6 ? "primary" : "initial",
                          fontSize: "14px",
                        }}
                      />
                      {applicationState >= 7 && <Done color="primary" />}
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        onClick={() => goto(7)}
                        primary={"Documents Uploads"}
                        primaryTypographyProps={{
                          color: applicationState > 7 ? "primary" : "initial",
                          fontSize: "14px",
                        }}
                      />
                      {applicationState >= 8 && <Done color="primary" />}
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        onClick={() => goto(8)}
                        primary={"Review & Submit"}
                        primaryTypographyProps={{
                          color: applicationState > 8 ? "primary" : "initial",
                          fontSize: "14px",
                        }}
                      />
                      {/* { (applicationState < 5 ? applicationState - 3 > key : applicationState -4 > key) && <Done color="primary" /> } */}
                    </ListItem>
                    <Divider />
                  </div>
                )}
            </List>
          </div>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

const styles = {
  btn: {
    color: "#FFF",
    textTransform: "capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    margin: "0 .5rem",
    fontWeight: "400",
  },
  mainBtn: {
    color: PRIMARY_COLOR,
    backgroundColor: WHITE_COLOR,
    textTransform: "capitalize",
    boxShadow: "none",
    margin: "0 .5rem",
    fontWeight: "400",
  },
};

const mapStateToProps = ({ applicant }) => ({ applicant });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logoutApplicant }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
