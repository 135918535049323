import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const ApplicantFooter = () => {
  return (
    <footer id="applicant">
      <Box style={{ textAlign: "center" }}>
        <Typography style={{ fontSize: "14px" }}>
          &copy; {new Date().getFullYear()} National Teachers Institute. All
          rights reserved. Powered by{" "}
          <a target="_blank" rel="noreferrer" href="https://omniswift.com/">
            Omnswift
          </a>{" "}
          | <Link to="/">Terms of Use</Link> |{" "}
          <Link to="/">Privacy Policy</Link>
        </Typography>
      </Box>
    </footer>
  );
};

export default ApplicantFooter;
