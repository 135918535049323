import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import servicesApi from "../../../redux/api/services";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Parser from "html-react-parser";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";

const News = () => {
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const [newsMediaEvents, setNewsMediaEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  // Use this to get events
  useEffect(() => {
    setLoading(true);
    const dashboardEvents = async () => {
      try {
        const dataResponse = await servicesApi.getEvents();
        const newsEvents = await servicesApi.getNewsEvents();
        const mediaEvents = await servicesApi.getMediaEvents();
        const data = dataResponse.data;
        setEvents(data);
        setNewsMediaEvents(newsEvents?.data.concat(mediaEvents?.data));
      } catch (error) {
        console.log("err", error);
      } finally {
        setLoading(false);
      }
    };
    dashboardEvents();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <div className="news-section">
      <main data-aos="fade-up" className="container content ">
        <h5 style={{ fontSize: "20px", fontWeight: "700px" }}>News & events</h5>

        {loading ? (
          <div className="for-news">
            <CircularProgress />
          </div>
        ) : (
          <>
            {newsMediaEvents.length === 0 || "" || undefined ? (
              <div className="for-news">
                <h4>No news available!</h4>
              </div>
            ) : (
              <div className="news-events-grid">
                {newsMediaEvents.slice(0, 4).map((event) => {
                  return (
                    <div
                      className="news-card"
                      style={{ marginBottom: "20px", cursor: "pointer" }}
                      key={event.id}
                      onClick={() => {
                        history.push(
                          `/news-media-event/${
                            event?.parent_category === "news"
                              ? "news"
                              : event?.parent_category === "media"
                              ? "media"
                              : ""
                          }/${event?.id}`
                        );
                      }}
                    >
                      <p>{`${moment(event?.created_at).format("LL")}`}</p>
                      <h3>{`${event?.title
                        ?.toUpperCase()
                        ?.slice(0, 50)}...`}</h3>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}

        <div className="news-btn">
          <button
            className="carousel-btn"
            onClick={() => history.push("/news-media-event/news")}
          >
            More News
          </button>
        </div>
      </main>
    </div>
  );
};

export default News;
