import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import MiniCard from "../../Website/MiniCard";
import AOS from "aos";
import "aos/dist/aos.css";

const AdeGeneralInfo = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <div
        style={{ padding: "1rem 0" }}
        className={"general-info-content"}
        dangerouslySetInnerHTML={{
          __html: content?.general_info_body || "",
        }}
      />
      {/* <Grid style={{ padding: "1rem 0" }}>
        <h3>Objectives of the Programme:</h3>
        <p>The objective of the Advanced Diploma in Education programme is;</p>
        <ol>
          <li>
            To help NCE holders upgrade their knowledge into some specialized
            Field in Education such as; School Supervision and Inspection, Early
            Childhood Education, Guidance & Counselling etc.
          </li>
        </ol>
      </Grid> */}
    </div>
  );
};

const AdeeGeneralInfo = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <div
        tyle={{ padding: "1rem 0" }}
        className={"general-info-content"}
        dangerouslySetInnerHTML={{
          __html: content?.general_info_body || "",
        }}
      />
      {/* <Grid style={{ padding: "1rem 0" }}>
        <h3>Objectives of the Programme:</h3>
        <p>
          The programme is hosted under the Green Teacher Nigeria (GTN) in
          collaboration with the Common wealth of Learning (COL).
          <br />
          The NTI-COL Green Teacher programme is a unique capacity-building
          Distance Learning programme aimed at helping the student to:
        </p>
        <ol>
          <li style={{ marginBottom: "1rem" }}>
            Enhance their appreciation of: <br />
            (i). The environment and generating deep interest and concern for
            environmental issues; <br />
            (ii). The relationship and connection of political, socio-economic
            and cultural activities as they affect environment <br />
            (iii). Environmental Education (EE) – theory, approaches and methods{" "}
            <br />
          </li>
          <li style={{ marginBottom: "1rem" }}>
            Strengthening the students skills
          </li>
          <li style={{ marginBottom: "1rem" }}>
            Disseminating among young learners, the knowledge and skills on the
            environment and how to dispose waste, conserve and protect it;
          </li>
          <li>
            Fostering appropriate values and attitudes among young learners for
            the protection and improving the environment by individuals,
            different social groups and communities in the context of
            sustainable development,
          </li>
        </ol>
      </Grid> */}
    </div>
  );
};

const BedGeneralInfo = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  const dot = (
    <>
      <div
        style={{
          display: "inline-block",
          color: "#00C853",
          marginRight: "6px",
          transform: "scale(0.8)",
        }}
      >
        •
      </div>
    </>
  );

  return (
    <div data-aos="fade-up">
      <Grid style={{ padding: "1rem 0" }}>
        <div
          style={{ padding: "1rem 0" }}
          className={"general-info-content"}
          dangerouslySetInnerHTML={{
            __html: content?.general_info_body || "",
          }}
        />
        {/* <h3>Objectives of the Programme:</h3>
        <p></p>
        <ol>
          <li style={{ marginBottom: "1rem" }}>
            To train and upgrade all qualified serving Grade II and NCE teachers
            to Degree level.;
          </li>
          <li>
            To improve the basic background of those who may wish to pursue
            their studies at higher levels, and to help produce the required
            number of quality Degree holding teachers for the successful
            implementation of the Universal Basic Education Programme and the
            needed teachers at the senior secondary school level.
          </li>
        </ol>
        <h3>Candidates are to select from among the following:</h3>
        <Grid container>
          <Grid item xs={12} md={4}>
            <p>{dot}B.A. Ed (Primary)</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p>{dot}B.A. Ed (English)</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p>{dot}B.Sc. Ed (Integrated Science)</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p style={{ marginTop: "-0.2rem" }}>
              {dot}B.A. Ed (Social Studies)
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p style={{ marginTop: "-0.2rem" }}>{dot}B.Sc. Ed (Mathematics)</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p style={{ marginTop: "-0.2rem" }}>
              {dot}B.Sc. Ed(Physical and Health Education)
            </p>
          </Grid>
        </Grid> */}
      </Grid>
      <h3>Specialisations:</h3>
      <Grid container spacing={5} style={{ marginBottom: "10rem" }}>
        <Grid item xs={12} md={4}>
          <MiniCard text={"Bachelors Degree In Arts Education English (ENG)"} />
        </Grid>

        <Grid item xs={12} md={4}>
          <MiniCard
            text={
              "Bachelors Degree In Science Education Physical And Health Education (PHE)"
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <MiniCard
            text={
              "Bachelors Degree In Science Education Integerated Science (ITS)"
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <MiniCard text={"Bachelors Degree In Arts Education Primary (PES)"} />
        </Grid>

        <Grid item xs={12} md={4}>
          <MiniCard
            text={"Bachelors Degree In Arts Education Social Studies (SOS)"}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <MiniCard
            text={"Bachelors Degree In Science Education Mathematics (MATH)"}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <MiniCard
            text={"Bachelors Degree In Science Education Health Education (HE)"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const NceGeneralInfo = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid style={{ padding: "1rem 0" }}>
        <div
          style={{ padding: "1rem 0" }}
          className={"general-info-content"}
          dangerouslySetInnerHTML={{
            __html: content?.general_info_body || "",
          }}
        />
        {/* <h3>Objectives of the Programme:</h3>

        <ol>
          <li style={{ marginBottom: "1.5rem" }}>
            To acquire adequate knowledge of the subject matter
          </li>
          <li style={{ marginBottom: "3rem" }}>
            To improve the basic background of those who may wish to pursue
            their studies at higher levels, and to help produce the required
            number of quality Degree holding teachers for the successful
            implementation of the Universal Basic Education Programme and the
            needed teachers at the senior secondary school level.
          </li>
        </ol> */}

        <h3>Specializations:</h3>
        <Grid container spacing={5} style={{ marginBottom: "10rem" }}>
          <Grid item xs={12} md={4}>
            <MiniCard text={content?.first_specialization} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={content?.second_specialization} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={content?.third_specialization} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={content?.fourth_specialization} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={content?.fifth_specialization} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={content?.sixth_specialization} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const PgdeGeneralInfo = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid style={{ padding: "1rem 0" }}>
        <div
          style={{ padding: "1rem 0" }}
          className={"general-info-content"}
          dangerouslySetInnerHTML={{
            __html: content?.general_info_body || "",
          }}
        />
        {/* <h3>Objectives of the Programme:</h3>
        <ol>
          <li style={{ marginBottom: "1rem" }}>
            To enable students have an adequate understanding of the
            philosophical, psychological and sociological foundations of
            Education and their relevance to teaching and learning in schools;
          </li>
          <li style={{ marginBottom: "1rem" }}>
            To enable students acquire the requisite general and
            subject-specific pedagogical skills for teaching in primary and
            secondary schools;
          </li>
          <li style={{ marginBottom: "3rem" }}>
            To provide opportunities for the development of practical skills in
            the school setting through a process of supervised internship /
            monitoring.
          </li>
        </ol>
        <h3>Target Group:</h3>
        <p>
          The programme is targeted at graduates of other disciplines who do not
          possess a professional teaching qualification but are currently
          teaching or intend to teach in future.
        </p> */}
      </Grid>
    </div>
  );
};

const PttpGeneralInfo = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid>
        <div
          style={{ padding: "1rem 0" }}
          className={"general-info-content"}
          dangerouslySetInnerHTML={{
            __html: content?.general_info_body || "",
          }}
        />
        {/* <h3>Objectives of the Programme:</h3>
        <p>
          The objective of the Pivotal Teachers Training Programme (PTTP) is
          that, at the end of the programme, the participants would have;
        </p>
        <ol>
          <li style={{ marginBottom: "1rem" }}>
            Acquired adequate knowledge of the subject matter
          </li>
          <li style={{ marginBottom: "1rem" }}>
            Acquired pedagogical proficiency for effective teaching
          </li>
          <li style={{ marginBottom: "1rem" }}>
            Imbibed relevant values and attitudes that would make them develop
            positive attitudes towards the teaching profession
          </li>
          <li style={{ marginBottom: "1rem" }}>
            Acquired basic background for further continuous upgrading and
            updating programmes in the teaching profession
          </li>
        </ol>
        <h3>Courses Offered:</h3>
        <p>
          The Courses offered in the Pivotal Teachers Training Programme are
          divided into two category -: Core and Optional.
        </p> */}

        <h3>Core Courses:</h3>
        <Grid container spacing={5} style={{ marginBottom: "1rem" }}>
          {content?.coreCourse?.map((course, key) => (
            <Grid key={key} item xs={12} md={4}>
              <MiniCard text={course?.name} />
            </Grid>
          ))}

          {/* <Grid item xs={12} md={4}>
            <MiniCard text={"English"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"Mathematics"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"Integrated Science"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"Social Science"} />
          </Grid> */}
        </Grid>

        <h3>Optional Courses:</h3>
        <Grid container spacing={5} style={{ marginBottom: "1rem" }}>
          {content?.optionalCourse?.map((course, key) => (
            <Grid key={key} item xs={12} md={4}>
              <MiniCard text={course?.name} />
            </Grid>
          ))}
          {/* <Grid item xs={12} md={4}>
            <MiniCard text={"Physical and Health Education"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"One Nigerian Language (Hausa, Igbo, or Yoruba)"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"Christian Religious Studies (CRS)"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"Islamic Religious Studies (IRS)"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"Home Economics"} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MiniCard text={"Agricultural Science"} />
          </Grid> */}
        </Grid>

        <p>
          <span>
            <strong>Note:</strong>
          </span>{" "}
          Every student is expected to register for all the core subjects and at
          least two (2) others selected from the optional subjects. In addition,
          every student is expected to undergo 8 weeks supervised teaching
          practice exercise.
        </p>

        <h3>Other Information:</h3>
        <p>
          Pivotal Teacher Training Programme (PTTP) is not the same as Teachers’
          Grade II. Pivotal Teachers’ Certificate is higher than Teachers’ Grade
          Two Certificate. Holders of PTC will be admitted into NTI NCE Year Two
          provided they have entry requirements for the NCE DLS.
        </p>
      </Grid>
    </div>
  );
};

export {
  AdeGeneralInfo,
  AdeeGeneralInfo,
  BedGeneralInfo,
  NceGeneralInfo,
  PgdeGeneralInfo,
  PttpGeneralInfo,
};
