import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../styles/styles";
import { Grid } from "@material-ui/core";
import Logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import Twitter from "../assets/images/twitter.svg";
import Facebook from "../assets/images/facebook.svg";
import YouTube from "../assets/images/youtube.svg";
import LinkedIn from "../assets/images/linkedIn.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  const url = "http://139.59.188.88/wp-json/newsletter/v1/subscribe";
  const [data, setData] = useState({
    email: "",
  });

  function submit(e) {
    e.preventDefault();
    axios
      .post(url, {
        email: data.email,
      })
      .then((res) => {
        console.log(res.data);
      });
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    console.log(newdata);
  }

  var links = [
    // { title: "About", link: "https://www.nti.edu.ng/about/" },
    { title: "Prospective Students", link: "/prospective" },
    { title: "Current Students", link: "student/login" },
    { title: "Programmes", link: "/programs" },
    // { title: "Locations", link: "/" },
    { title: "News and Events", link: "/news-media-event/news" },
    // { title: "How to apply", link: "https://my.nti.edu.ng/application-guide" },
    { title: "Contact Us", link: "/contact-us" },
  ];

  var socials = [
    { link: "https://twitter.com/ntiedung", icon: Twitter },
    { link: "https://www.facebook.com/ntiedung/", icon: Facebook },
    { link: "/", icon: YouTube },
    { link: "/", icon: LinkedIn },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <div className="footer">
      <div
        className="content container"
        data-aos="fade-up"
        // style={{ width: "1171px", margin: "auto" }}
      >
        <Grid container>
          <Grid item xs={12} md={12} lg={1}>
            <Link to="/">
              <img src={Logo} alt="logo" height="48" width="48" />
            </Link>
          </Grid>
          <Grid
            item
            md={9}
            xs={12}
            className="footer-menu"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            {links.map(({ link, title }, key) => {
              return (
                <a
                  className="nav-link"
                  href={link}
                  key={key}
                  rel="noreferrer"
                  style={{ color: "#fff", fontWeight: "normal" }}
                >
                  {title}
                </a>
              );
            })}
          </Grid>
          <Grid item md={12} xs={12} lg={2}>
            <p className="footer-follow-text" style={{ marginBottom: ".5rem" }}>
              Follow us on:
            </p>
            <div className="footer-menu icons">
              {socials.map(({ link, icon }, key) => {
                return (
                  <a
                    className="nav-link"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    key={key}
                  >
                    <img src={icon} alt="img" />
                  </a>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} xs={12} style={{ margin: "45px 0" }}>
            <h3 style={{ ...styles.pageTitle, color: "#fff" }}>
              Keep in Touch With Us
            </h3>
            <p
              style={{ ...styles.text, color: "#fff" }}
              className="subscribe-txt"
            >
              Subscribe to our NewsLetter to always get important information
              regarding NTI
            </p>
            <form onSubmit={(e) => submit(e)}>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Enter email address"
                value={data.email}
                onChange={(e) => handle(e)}
                className="subscribe-input"
              />
              <button
                className="subscribe-btn"
                style={{
                  ...styles.button,
                  padding: "5px 50px",
                  marginTop: 30,
                  backgroundColor: "#00C853",
                }}
              >
                Subscribe
              </button>
            </form>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p>
              &copy; {new Date().getFullYear()} National Teachers Institute. All
              rights reserved.
            </p>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            lg={6}
            className="footer-menu bottom-footer"
          >
            <p>
              Powered by{" "}
              <a href="https://omniswift.com/" rel="noreferrer" target="_blank">
                Omniswift
              </a>
            </p>
            <p>
              <Link to="/terms-and-conditions">Terms of Use</Link>
              {" | "}
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
