import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const MiniCard = ({ text }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  const cardStyle = {
    borderLeft: "3px solid #00C853",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.12)",
    width: "100%",
    padding: "0.5rem 1rem",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div data-aos="zoom-in" style={cardStyle}>
      <p>{text}</p>
    </div>
  );
};

export default MiniCard;
