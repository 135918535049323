import React from "react";
import { useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import Bg1 from "../../../assets/images/bgs1.png";
import Bg2 from "../../../assets/images/bgs2.png";
import Bg3 from "../../../assets/images/bgs3.png";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const HomeCarousel = ({ status, semester }) => {
  const history = useHistory();

  const options = {
    margin: 30,
    loop: true,
    responsiveClass: true,
    controlsClass: true,
    items: 1,
    dots: true,
    autoplay: true,
    fluidSpeed: 15000,
    responsive: {
      0: {
        items: 1,
      },
      812: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <div
      className="header-floating "
      // style={{ height: "70vh" }}
    >
      <OwlCarousel
        className="owl-theme"
        items={1}
        loop
        margin={20}
        autoplay={true}
        fluidSpeed={15000}
        autoplaySpeed={2000}
        navSpeed={2000}
        dotsSpeed={2000}
        autoplayTimeout={8000}
        navText={[
          `<span class="arrow prev"><</span>`,
          `<span class="arrow next">></span>`,
        ]}
        nav={true}
      >
        <div className="carousel-div">
          <img alt="" src={Bg1} className="carousel-im" />
          <div className="carousel-text ">
            <h1 className="text-h1">Application is {status}</h1>
            <p
              className="text-p"
              // style={{ width: "45%" }}
            >
              Aspiring Student application is {status}. Students registeration
              for {semester?.name === 1 ? "first semester" : "second semester"}{" "}
              {semester?.session?.year_name} is open
            </p>
          </div>
        </div>

        <div className="carousel-div">
          <img alt="" src={Bg2} className="carousel-im" />
          <div className="carousel-text">
            <h1 className="text-h1">
              Join the Green Teacher's <br /> Programme
            </h1>
            <p
              className="text-p"
              // style={{ width: "45%" }}
            >
              The Advanced Diploma in Environmental Education programme at NTI
              is currently {status} for Application
            </p>
          </div>
        </div>

        <div className="carousel-div">
          <img alt="" src={Bg3} className="carousel-im" />
          <div className="carousel-text">
            <h1 className="text-h1">
              Apply to NTI using the Mobile Companion App
            </h1>
            <p
              className="text-p"
              // style={{ width: "45%" }}
            >
              Now available on the Android Appstore
            </p>
          </div>
        </div>
      </OwlCarousel>

      <div className="btn-absolute-div ">
        <button
          className="carousel-btn student-blue"
          onClick={() => history.push("/applicant/new")}
        >
          Applicant Registration
        </button>
        <button
          className="carousel-btn current-stud-reg"
          onClick={() => history.push("/student/login")}
        >
          Student Login
        </button>

        <button className="carousel-btn lms-link-btn remove-padding">
          <a
            href="https://www.lms.nti.edu.ng"
            rel="noreferrer"
            target="_blank"
            className="lms-link-fill"
          >
            LMS Portal
          </a>
        </button>
      </div>
    </div>
  );
};

export default HomeCarousel;
