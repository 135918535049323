import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  Checkbox,
  Paper,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import mainStyles from "../../styles/mainStyles";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { PRIMARY_COLOR } from "../../styles/colors";
import { VisibilityOff } from "@material-ui/icons";
import { Visibility } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  applicantLogin,
  logoutApplicant,
  getApplicantData,
} from "../../redux/actions/applicantAction";
import PageContainer from "../../components/PageContainer";

const Login = ({
  applicantLogin,
  logoutApplicant,
  applicant,
  getApplicantData,
}) => {
  const history = useHistory();
  const isPhone = useMediaQuery({ query: "(max-width: 986px)" });
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    checked: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [logging, setLogging] = useState(false);

  const handleFormChange = ({ name, value }) => {
    setFormError("");
    setLoginForm({ ...loginForm, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setFormError("");
    if (!loginForm?.email.includes("@") || !loginForm?.email.includes(".com")) {
      setFormError("Email must be a valid email, missing '@' or ' .com ' ");

      return;
    }

    const emailHost = loginForm?.email?.slice(loginForm?.email.indexOf("@"));
    if (
      emailHost?.includes("nti") ||
      emailHost?.includes("mynti") ||
      emailHost?.includes("my.nti")
    ) {
      setFormError("Email cannot be an NTI email");

      return;
    }

    setLogging(true);
    try {
      const user = await applicantLogin(loginForm);
      await getApplicantData();
      if (user.applicant.completed) {
        history.push("/applicant/dashboard");
      } else {
        history.push("/applicant/personal-information", {
          application_state: user.applicant.application_state,
        });
      }
    } catch (err) {
      setFormError(err.data.message || err.data.message);
    } finally {
      setLogging(false);
    }
  };

  return (
    <PageContainer>
      <Container
        style={isPhone ? mainStyles.phoneContainer : mainStyles.container}
      >
        <Helmet>
          <title>Applicant Login | NTI</title>
        </Helmet>
        <form onSubmit={(e) => handleLogin(e)}>
          <Box
            className="slide-box"
            style={isPhone ? {} : { margin: "30px 100px" }}
          >
            <Paper elevation={5}>
              <Grid
                container
                className="log-div"
                style={{ ...mainStyles.subContainer, ...{ padding: 0 } }}
              >
                <Grid
                  className="log-grid"
                  item
                  xs={12}
                  md={6}
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    padding: "30px 60px",
                  }}
                >
                  <Typography
                    className="login-main"
                    style={{
                      fontWeight: 300,
                      fontSize: "45px",
                      color: "#FFF",
                      marginBottom: "25%",
                      lineHeight: "60px",
                      paddingRight: "6%",
                    }}
                  >
                    Welcome Back to the NTI Application Portal
                  </Typography>

                  <Typography
                    className="log-sub"
                    style={{
                      fontWeight: 300,
                      fontSize: "20px",
                      lineHeight: "40px",
                      color: "#FFF",
                    }}
                  >
                    By clicking sign in, you agree to National Teachers
                    Institute, Security Protocols, Please see Terms &amp;
                    Conditions.
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} style={{ padding: "20px 15px" }}>
                  <Typography
                    style={{ fontWeight: 500, fontSize: "40px", margin: "5%" }}
                  >
                    Sign In
                  </Typography>
                  <Grid item style={mainStyles.inputGrid}>
                    <TextField
                      label="Email Address"
                      style={mainStyles.input}
                      variant="outlined"
                      name="email"
                      onChange={(e) => handleFormChange(e.target)}
                      value={loginForm.email}
                      placeholder="Email Address"
                    />
                  </Grid>
                  <Grid item xs={12} style={mainStyles.inputGrid}>
                    <TextField
                      label="Password"
                      style={mainStyles.input}
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={loginForm.password}
                      placeholder="Password"
                      onChange={(e) => handleFormChange(e.target)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {!showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={mainStyles.inputGrid}>
                    <FormControl variant="outlined" style={mainStyles.input}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={loginForm.checked}
                            onChange={() =>
                              setLoginForm({
                                ...loginForm,
                                checked: !loginForm.checked,
                              })
                            }
                            name="checked"
                            color="primary"
                          />
                        }
                        label="Remember Me"
                      />
                    </FormControl>
                    <FormHelperText
                      error
                      style={{ textAlign: "center", marginBottom: "1rem" }}
                    >
                      {formError}
                    </FormHelperText>
                    <Box mb={6}>
                      <Button
                        disabled={logging}
                        type="submit"
                        fullWidth
                        style={{ height: "50px" }}
                        variant="contained"
                        color="primary"
                      >
                        {logging ? (
                          <CircularProgress color="inherit" size="15px" />
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </Box>
                  </Grid>

                  <Typography
                    style={{
                      fontWeight: 300,
                      fontSize: "16px",
                      marginLeft: "15px",
                    }}
                  >
                    I forgot my{" "}
                    <Link to="/applicant/reset-password">User ID</Link> or{" "}
                    <Link to="/applicant/reset-password">Password</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </form>
      </Container>
    </PageContainer>
  );
};

const mapStateToProps = ({ applicant }) => ({ applicant });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { applicantLogin, logoutApplicant, getApplicantData },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
