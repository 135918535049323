import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import mainStyles from "../../styles/mainStyles";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getServices } from "../../redux/actions/serviceAction";
import {
  logoutApplicant,
  getApplicantData,
  getApplicantFeeRRR,
  verifiedRRR,
} from "../../redux/actions/applicantAction";
import DashboardComponent from "../../components/Dashboard/DashboardComponent";
import ViewIcon from "../../assets/images/view-icon.svg";
import CheckIcon from "../../assets/images/payment-check.svg";
import NotPaidStroke from "../../assets/images/ex-round.svg";
import { bindActionCreators } from "redux";
import { useHistory, useParams } from "react-router-dom";
import applicantApi from "../../redux/api/applicant";
// import serviceApi from "../../redux/api/services";
import { sha512 } from "../../util/generalUtils";
import { generatePDFFile } from "../../util/pdfBuilder";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from "axios";
import CongratsImg from "../../assets/images/congrats.svg";
import AlertComponent from "../../components/Applicant/AlertComponent";
import DashboardModal from "../../components/Dashboard/DashboardModal";

import { RED_COLOR } from "../../styles/colors";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PayGenFees = ({ applicant, services, getApplicantData }, props) => {
  const history = useHistory();
  const params = useParams();
  const [alertConfig, setAlertConfig] = useState({
    image: CongratsImg,
    headerText: "Payment Verified",
    btn2Text: "Print Receipt & Proceed",
  });

  const [openModal, setOpenModal] = useState(false);
  const [checkError, setCheckError] = useState("");
  // const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [section, setSection] = useState("pay"); // value can be 'pay' || 'verify'
  const [applicantData, setApplicantData] = useState(
    applicant?.applicantData?.applicant
  );
  // const [applicationState, setApplicationState] = useState(
  //   applicantData?.applicant?.application_state,
  // );
  const [remitaData, setRemitaData] = useState({});
  const [verifiedRrrRes, setVerifiedRrrRes] = useState("");
  const [verifyRRRStatus, setVerifyRRRStatus] = useState("");
  const [genFeeId, setGenFeeId] = useState("");

  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [payForm, setPayForm] = useState({
    fullname: applicantData
      ? `${applicantData.surname} ${applicantData.firstname} ${applicantData.othername}`
      : "",
    email: applicantData ? applicantData?.email : "",
    phone: applicantData ? applicantData?.phone : "",
    programme: applicantData ? applicantData?.programme?.name : "",
    specialization: applicantData ? applicantData?.first_choice?.name : "",
  });

  // setting up environment variables
  const merchantId = `${process.env.REACT_APP_MERCHANT_ID}`;
  const apiKey = `${process.env.REACT_APP_API_KEY}`;
  const [hash, setHash] = useState("");

  // FOR THE REMITA PAYMENT
  const getHash = useCallback(async () => {
    const hashString = merchantId + remitaData.rrr + apiKey;
    const hsh = await sha512(hashString);
    setHash(hsh);
  }, [remitaData.rrr]);

  console.log(applicantData);

  // Use this to get the remita , order-id and more
  useEffect(() => {
    const generalFeeRRR = async () => {
      try {
        const dataResponse = await applicantApi.payGeneralFees(genFeeId);
        const data = dataResponse.data.data.transaction;

        setRemitaData(data);
      } catch (error) {
        console.log("err", error);
      } finally {
        console.log("Done rrr");
      }
    };
    generalFeeRRR();
  }, [genFeeId]);
  console.log(remitaData);

  const generatePDFInvoice = async (status = false) => {
    setPrinting(true);
    try {
      const paymentStatus = applicantData.application_state > 2 ? true : status;
      const paymentInfo = {
        ...payForm,
        total: payForm.amount,
        feeType: remitaData ? remitaData?.fee_type?.name : "",
        paymentStatus,
        amount: remitaData ? remitaData.amount : "",
        orderId: remitaData ? remitaData.orderid : "",
        rrr: remitaData ? remitaData.rrr : "", //remita-retrieval-reference code
        session: remitaData ? remitaData.semester?.session.name : "",
      };
      const remitaInfo = { ...remitaData };
      console.log(remitaInfo);
      await generatePDFFile(
        "invoice",
        applicantData,
        paymentInfo,
        remitaInfo
      ).then(async (data) => {
        const pdfDocGenerator = pdfMake.createPdf(data);
        pdfDocGenerator.print();
      });
    } finally {
      setPrinting(false);
    }
  };

  // Verify RRR and getApplicantData updated information
  const verifyRRRHandler = async () => {
    let rrrData = remitaData.rrr;
    setProcessing(true);
    try {
      const response = await applicantApi.verifyUnpaidPost({ rrr: rrrData });
      console.log(response.data.data);
      setProcessing(false);
      setVerifiedRrrRes(response.data.data);
      if (verifiedRrrRes === "Transaction Successful") {
        await getApplicantData();
      }
    } catch (err) {
      // setError(err?.data.data);
      console.log("rrr error", err);
    } finally {
      setProcessing(false);
    }
  };

  const printReceipt = async () => {
    setProcessing(true);
    try {
      await generatePDFInvoice(true);
      await getApplicantData();
    } finally {
      setProcessing(false);
      setOpenModal(false);
    }
  };

  const moveToDashboardHandler = async () => {
    setLoading(true);
    try {
      await getApplicantData();
      history.push("/applicant/dashboard");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // SETTING HASH
  useEffect(() => {
    getHash();
  }, [getHash]);

  // When mounted should load the applicant data
  useEffect(() => {
    const applicantInfo = async () => {
      setProcessing(true);
      let rrrData = remitaData?.rrr;
      try {
        const dataRes = await applicantApi.verifyUnpaidGet(rrrData);

        setVerifyRRRStatus(dataRes.data.data);

        const dataResponse = await applicantApi.getApplicantInformation();
        let loadedApplicant = dataResponse.data.data.applicant;
      } catch (err) {
        console.log(err);
      } finally {
        setProcessing(false);
      }
    };
    applicantInfo();
  }, [remitaData?.rrr]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCheckError("");
  }, [section]);

  //collecting the genFeeId from the url using params
  useEffect(() => {
    setGenFeeId(params.Id);
  }, [params.Id]);

  return (
    <DashboardComponent pageTitle="Dashboard">
      <Box className="slide-box" style={isPhone ? {} : { margin: "20px 35px" }}>
        <Helmet>
          <title>Pay General Fees | NTI</title>
        </Helmet>
        <Box>
          <Typography
            style={
              isPhone
                ? { ...mainStyles.pageTitle, ...{ fontSize: "22px" } }
                : mainStyles.pageTitle
            }
          >
            {section === "pay"
              ? `Pay ${remitaData?.fee_type?.name}`
              : "Verify RRR"}
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: isPhone ? "14px" : "16px" }}>
            {section === "pay"
              ? `Below is the payment details for your ${remitaData?.fee_type?.name}.`
              : "Please verify RRR to continue "}
          </Typography>
        </Box>

        <Grid
          style={
            isPhone
              ? { ...mainStyles.subContainer, ...mainStyles.subPhoneContainer }
              : mainStyles.subContainer
          }
        >
          {section !== "pay" && (
            <div style={{ marginBottom: isPhone ? "50px" : "20px" }}>
              <Typography
                style={
                  isPhone
                    ? { ...mainStyles.pageTitle, ...{ fontSize: "22px" } }
                    : mainStyles.pageTitle
                }
              >
                <span style={{ marginLeft: "25px" }}>RRR Information</span>
              </Typography>
              {checkError && (
                <Box
                  style={{
                    backgroundColor: "brown",
                    borderRadius: "5px",
                    color: "#FFF",
                    margin: "10px 25px",
                    padding: "10px",
                  }}
                >
                  {checkError}
                </Box>
              )}
              <Grid
                item
                xs={12}
                style={{ display: isPhone ? "block" : "flex" }}
              >
                <Grid item xs={12} md={9} style={mainStyles.inputGrid}>
                  <FormControl
                    variant="outlined"
                    style={mainStyles.verifyRRRInput}
                  >
                    <TextField
                      label="Verify RRR Number"
                      style={mainStyles.input}
                      variant="outlined"
                      placeholder="Enter your RRR number"
                      value={remitaData.rrr || ""}
                      helperText={`${
                        verifiedRrrRes
                          ? verifiedRrrRes.data
                          : "Only RRR generated on this portal can be verified"
                      }`}
                      name="rrr-number"
                      readOnly={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                  <FormHelperText
                    error
                    style={{ textAlign: "left", marginBottom: "1rem" }}
                  >
                    {`${verifiedRrrRes && verifiedRrrRes}`}
                    {/* {`${error && error}`} */}
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    ...mainStyles.inputGrid,
                    margin: isPhone ? "0 25px" : "25px 25px 0",
                  }}
                >
                  <Button
                    onClick={() => verifyRRRHandler()}
                    style={{
                      ...mainStyles.btn,
                      ...{ width: "100%", marginTop: isPhone ? "1.5rem" : 0 },
                    }}
                    variant="contained"
                    color="primary"
                    // disabled={
                    //   applicantData?.application_state > 3 ||
                    //   applicantData?.completed
                    // }
                  >
                    {processing ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
          <div>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <TextField
                    label="Full name"
                    style={mainStyles.input}
                    variant="outlined"
                    placeholder="Full name"
                    value={payForm.fullname}
                    readOnly={true}
                    name="fullname"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <TextField
                    label="Email Address"
                    style={mainStyles.input}
                    variant="outlined"
                    placeholder="Email Address"
                    value={payForm.email}
                    readOnly={true}
                    name="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <TextField
                    label="Phone number"
                    style={mainStyles.input}
                    variant="outlined"
                    placeholder="Phone number"
                    value={payForm.phone}
                    readOnly={true}
                    name="phone"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <TextField
                    label="Programme"
                    style={mainStyles.input}
                    variant="outlined"
                    placeholder="Loading..."
                    value={applicantData?.programme?.name}
                    readOnly={true}
                    name="programme"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <TextField
                    label="Amount"
                    style={mainStyles.input}
                    variant="outlined"
                    placeholder="Loading..."
                    value={remitaData?.amount}
                    readOnly={true}
                    name="amount"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>&#8358;</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <TextField
                    label="Order ID"
                    style={mainStyles.input}
                    variant="outlined"
                    placeholder="Loading..."
                    value={remitaData?.orderid}
                    readOnly={true}
                    name="orderId"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: isPhone ? "block" : "flex" }}>
              <Grid item xs={12} style={mainStyles.inputGrid}>
                <FormControl variant="outlined" style={mainStyles.input}>
                  <TextField
                    label="RRR"
                    style={mainStyles.input}
                    variant="outlined"
                    placeholder="Loading..."
                    value={remitaData?.rrr}
                    readOnly={true}
                    name="rrr"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  ...mainStyles.inputGrid,
                  justifyContent: "space-between",
                  display: isPhone ? "block" : "flex",
                }}
              >
                {section === "pay" ? (
                  verifyRRRStatus === "" || undefined ? (
                    <Button
                      style={{ ...mainStyles.btn, ...{ width: "100%" } }}
                      variant="contained"
                      color="transparent"
                    >
                      <CircularProgress size={20} color="inherit" />
                    </Button>
                  ) : (
                    <>
                      {verifyRRRStatus === "Transaction Pending" && (
                        <form
                          action="https://login.remita.net/remita/ecomm/finalize.reg"
                          name="SubmitRemitaForm"
                          style={{ width: isPhone ? "100%" : "47%" }}
                          method="POST"
                        >
                          <input
                            name="merchantId"
                            value={merchantId}
                            type="hidden"
                          />
                          <input name="hash" value={hash} type="hidden" />
                          <input
                            name="rrr"
                            value={remitaData.rrr}
                            type="hidden"
                          />
                          <input
                            name="responseurl"
                            value={`http://${window.location.host}/applicant/dashboard/pay-general-fees/${genFeeId}`}
                            type="hidden"
                          />
                          <Button
                            type="submit"
                            name="submit_btn"
                            style={{
                              ...mainStyles.btn,
                              ...{ width: "100%" },
                            }}
                            variant="contained"
                            color="primary"
                            // disabled={
                            //   applicantData?.application_state > 2 ||
                            //   applicantData?.completed
                            // }
                          >
                            Pay Via Remita
                          </Button>
                        </form>
                      )}

                      {verifyRRRStatus === "Transaction Pending" && (
                        <Button
                          onClick={async () => await generatePDFInvoice()}
                          style={{
                            ...mainStyles.btn,
                            ...{
                              width: isPhone ? "100%" : "47%",
                              marginTop: isPhone ? "1.5rem" : 0,
                            },
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          {printing ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Save invoice"
                          )}
                        </Button>
                      )}

                      {
                        // Remember to look into this
                        verifyRRRStatus === "Transaction Successful" && (
                          <Button
                            onClick={() => moveToDashboardHandler()}
                            style={{
                              ...mainStyles.btn,
                              ...{ width: "100%" },
                            }}
                            variant="contained"
                            color="primary"
                          >
                            {loading ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              "Proceed"
                            )}
                          </Button>
                        )
                      }
                    </>
                  )
                ) : (
                  <Button
                    onClick={() => setSection("pay")}
                    style={{ ...mainStyles.btn, ...{ width: "100%" } }}
                    variant="contained"
                    color="primary"
                  >
                    Pay now
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>

          {section === "pay" && (
            //replaced the anchor with button Peter Ihimire
            <Typography style={{ margin: "25px 25px 0px" }}>
              If you have made payment via bank
              <button
                style={{
                  cursor: "pointer",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "1.5px solid #46C35F",
                  color: "#46C35F",
                  padding: "0px",
                  background: "none",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "16px",
                  letterSpacing: "0.1px",
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
                onClick={() => setSection("verify")}
              >
                click here
              </button>
              to verify your RRR
            </Typography>
          )}
        </Grid>

        <DashboardModal
          config={alertConfig}
          processing={processing}
          openDialog={openModal}
          handleAlertConfirm={printReceipt}
        />
      </Box>
    </DashboardComponent>
  );
};

const mapStateToProps = ({ services, applicant }) => ({ services, applicant });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getServices,
      logoutApplicant,
      getApplicantData,
      getApplicantFeeRRR,
      verifiedRRR,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PayGenFees);
