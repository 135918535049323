import React, { useState, useEffect } from "react";
import styles from "../styles/webStyles";
import { Grid } from "@material-ui/core";
import { Menu, Search, ExpandLess } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import PageTitle from "../components/Website/PageTitle";
import PageContainer from "../components/PageContainer";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import BusinessIcon from "@material-ui/icons/Business";
import { Link } from "react-router-dom";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import Twitter from "../assets/images/twitter.svg";
import Facebook from "../assets/images/facebook.svg";
import Message from "../assets/images/Message.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);

  const socials = [
    { link: "https://wa.me/09095450282", icon: Message },
    { link: "https://twitter.com/ntiedung", icon: Twitter },
    { link: "https://www.facebook.com/ntiedung/", icon: Facebook },
  ];

  // FOR THE SCROLL TO TOP BUTTON
  useEffect(() => {
    const changeVisibility = () => {
      if (window.scrollY >= 540) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };

    window.addEventListener("scroll", changeVisibility);

    return () => {
      window.removeEventListener("scroll", changeVisibility);
    };
  }, []);
  console.log(visibility);
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <PageContainer>
      <Helmet>
        <title>Contact Us | NTI</title>
      </Helmet>
      <PageTitle title="Contacts" />

      <div className="container content">
        <Grid data-aos="fade-up" container>
          <Grid item xs={12} md={6} style={styles.gridSections}>
            <h2
              style={{
                ...styles.subHeader,
                fontSize: isPhone ? "30px" : "24px",
              }}
            >
              How you can find us
            </h2>
            <div>
              <p className="contact-list">
                <LocationOnIcon className="contactIcon" />
                KM 5 Kaduna – Zaria Express Way, Rigachikun, Kaduna, Nigeria.
              </p>
              <p className="contact-list">
                <BusinessIcon className="contactIcon" />
                Kaduna Nigeria
              </p>
              <p className="contact-list">
                <CallIcon className="contactIcon" />
                <a style={{ color: "black" }} href="tel:09064579779">
                  090 6457 9779
                </a>
              </p>

              <p className="contact-list">
                <AlternateEmailIcon className="contactIcon" />
                <a style={{ color: "black" }} href="mailto:support@nti.edu.ng">
                  {" "}
                  support@nti.edu.ng
                </a>
              </p>
            </div>

            <div style={{ margin: "2rem 30px 2rem 0px" }}>
              {socials.map(({ link, icon }, key) => {
                return (
                  <a
                    className="contact-link"
                    href={link}
                    key={key}
                    target="_blank"
                  >
                    <img src={icon} alt="img" />
                  </a>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={styles.gridSections}>
            <iframe
              src="https://maps.google.com/maps?q=KM%205%20Kaduna%20%E2%80%93%20Zaria%20Express%20Way,%20Rigachikun,%20Kaduna,%20Nigeria.&t=&z=13&ie=UTF8&iwloc=&output=embed"
              style={{
                marginLeft: isPhone ? "0rem" : "7rem",
                border: 0,
                marginBottom: "3rem",
                width: isPhone ? "100%" : "80%",
                height: 350,
              }}
              allowfullscreen=""
            ></iframe>
          </Grid>
        </Grid>
      </div>
      {visibility && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="scroll-top top-btn"
        >
          <ExpandLess />
        </button>
      )}
    </PageContainer>
  );
};

export default ContactUs;
