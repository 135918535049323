import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import StudyCenters from "./StudyCenters";
import StateOffices from "./StateOffices";
import { Select, Box, MenuItem } from "@material-ui/core";

const Header = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [open, setOpen] = useState("StateOffices");
  const [age, setAge] = React.useState("StateOffices");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div style={{ background: "#fff" }}>
      {isPhone ? (
        <div className="container content">
          <Box sx={{ Width: 100 }}>
            <Select
              className="mobile-select"
              value={age}
              onChange={handleChange}
              displayEmpty
              disableUnderline
              inputProps={{
                "aria-label": "Without label",
              }}
              style={{
                padding: "0.5rem",
                // margin: "20px 0px",
                width: "100%",
                textAlign: "center",
                color: "#fff",
                background: "#46c35f",
                borderRadius: "4px",
              }}
            >
              <MenuItem
                onClick={() => setOpen("StateOffices")}
                value="StateOffices"
              >
                State Offices
              </MenuItem>
              <MenuItem
                onClick={() => setOpen("StudyCenters")}
                value="StudyCenters"
              >
                Study Centers
              </MenuItem>
            </Select>
          </Box>
        </div>
      ) : (
        <div className="container conten">
          <main style={{ marginTop: "-1.5rem" }}>
            <nav>
              <ul className="media-details">
                <li
                  onClick={() => setOpen("StateOffices")}
                  style={{
                    background: open === "StateOffices" ? "#00C853" : "#F6F6F6",
                    color: open === "StateOffices" ? "white" : "black",
                  }}
                >
                  State Offices
                </li>
                <li
                  onClick={() => setOpen("StudyCenters")}
                  style={{
                    background: open === "StudyCenters" ? "#00C853" : "#F6F6F6",
                    color: open === "StudyCenters" ? "white" : "black",
                  }}
                >
                  Study Centers
                </li>
              </ul>
            </nav>
          </main>
        </div>
      )}

      <div>
        <div className="container content">
          {open === "StateOffices" && <StateOffices />}
          {open === "StudyCenters" && <StudyCenters />}
        </div>
      </div>
    </div>
  );
};

export default Header;
